"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clear = exports.getChatToken = exports.get = exports.save = void 0;
var cookie_1 = require("./cookie");
var tokenKey = 'ngStorage-accessToken';
var clientTokenKey = 'clientAccessToken';
var expertTokenKey = 'expertAccessToken';
var userKey = 'ngStorage-user';
var AUTH_COOKIE_NAME = 'apauth';
function save(token, user) {
    window.localStorage.setItem(tokenKey, token);
    // @TODO: this is only needed for the old app
    // and may be removed once it's deprecated
    window.localStorage.setItem(userKey, JSON.stringify(user));
    return token;
}
exports.save = save;
function get() {
    // prettier-ignore
    return window.localStorage.getItem(tokenKey)
        || window.localStorage.getItem(clientTokenKey)
        || window.localStorage.getItem(expertTokenKey)
        || (0, cookie_1.getCookie)(AUTH_COOKIE_NAME)
        || null;
}
exports.get = get;
function getChatToken() {
    var token = window.localStorage.getItem(tokenKey);
    if (!token) {
        token = window.localStorage.getItem(clientTokenKey);
    }
    if (!token) {
        token = window.localStorage.getItem(expertTokenKey);
    }
    return token || null;
}
exports.getChatToken = getChatToken;
function clear() {
    (0, cookie_1.clearCookies)();
    window.localStorage.removeItem(tokenKey);
    window.localStorage.removeItem(userKey);
    window.localStorage.removeItem(clientTokenKey);
}
exports.clear = clear;
var credentials = {
    save: save,
    get: get,
    getChatToken: getChatToken,
    clear: clear,
};
exports.default = credentials;
