import React from 'react'
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux'
import { useGetRoomDataQuery } from './redux/reducers/apiSlice'
import { setActiveRoom } from './redux/reducers/chatSlice'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'

import reduxStore from './redux/store'
import pick from 'lodash/pick'

export const ChatRoom = ({
  project,
  user,
  clientOfficeId,
  segment,
  expert,
  epl,
  context,
}: {
  project: any
  user: any
  clientOfficeId: number | undefined
  segment: any | null
  expert: any | null
  epl: any | null
  context: string
}) => {
  return (
    <ReduxProvider store={reduxStore}>
      <ChatRoomContainer
        user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])}
        project={project}
        clientOfficeId={clientOfficeId}
        segment={segment}
        expert={expert}
        epl={epl}
        context={context}
      />
    </ReduxProvider>
  )
}

export const ChatRoomContainer = ({
  project,
  user,
  clientOfficeId,
  segment,
  expert,
  epl,
  context,
}: {
  project: any
  user: any
  clientOfficeId: number | undefined
  segment: any | null
  expert: any | null
  epl: any | null
  context: string
}) => {
  const { data, isSuccess: roomFetched }: any = useGetRoomDataQuery({
    userId: user.id,
    meta: { projectId: project.id, eplId: epl?.id, context },
  })
  const foundRoom = data?.result
  const dispatch = useDispatch()

  const scope = epl ? epl.segment : segment
  let newRoomName = ''
  newRoomName = epl && epl.expert ? (epl.expert.fullName || 'Ex-' + epl.expert.id) + ' - ' : ''
  newRoomName += scope ? scope.name + ' - ' : ''
  newRoomName += project.projectName

  const room = {
    name: newRoomName,
    meta: {
      projectId: project.id,
      clientOfficeId,
      segmentId: segment?.id || null,
      expertId: expert?.id || null,
      eplId: epl?.id || null,
      context,
    },
  }

  const unseen = foundRoom ? foundRoom.messages.length - foundRoom.seens.length : 0

  return (
    <div>
      <div className="chat-icon-wrap">
        <ChatBubbleOutlineIcon
          color="inherit"
          style={{ fontSize: 30, color: '#3259A9' }}
          onClick={() => dispatch(setActiveRoom({ room: foundRoom || room }))}
        />
        {roomFetched && foundRoom && !!unseen && <div className="chat-unseen">{unseen}</div>}
      </div>
    </div>
  )
}
