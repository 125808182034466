import React from 'react'

const Arrow = ({ rotate180, ...props }) => {
  return (
    <div {...props} className={rotate180 ? 'icon--rotate-180' : ''}>
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        enableBackground="new 0 0 16 16"
        xmlSpace="preserve"
      >
        <polygon id="Icon" fill="#7E8DA2" points="8,0 6.59,1.41 12.17,7 0,7 0,9 12.17,9 6.59,14.59 8,16 16,8 " />
      </svg>
    </div>
  )
}

export default Arrow
