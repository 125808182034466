import { extendObservable } from 'mobx'
import { getAtheneumContact } from '@api/client'

class AtheneumContactStore {
  constructor() {
    extendObservable(this, {
      atheneumContact: null,
    })
    this.loadAtheneumContact()
  }

  loadAtheneumContact() {
    getAtheneumContact().then((atheneumContact) => {
      this.atheneumContact = atheneumContact
    })
  }
}

export default AtheneumContactStore
