import './chat.sass'

import React, { useEffect } from 'react'
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux'
import { useGetUserUnseenCountQuery } from './redux/reducers/apiSlice'
import { openEplChat, setSeenStatus } from './redux/reducers/chatSlice'
import reduxStore from './redux/store'
import ForumIcon from '@material-ui/icons/Forum'
import pick from 'lodash/pick'

interface EPLChatRoomProps {
  user: any
  eplId: number
  unseenCount?: number
  room?: {
    name: string
    meta: {
      context: 'expert'
      eplId: number
      expertId: number
      segmentId: number
      projectId: number
    }
  }
}

export const EPLChatRoom = ({ user, eplId, unseenCount, ...rest }: EPLChatRoomProps) => {
  const isBatchFeatureEnabled = typeof unseenCount !== 'undefined'
  return (
    <ReduxProvider store={reduxStore}>
      {!isBatchFeatureEnabled ? (
        <EPLChatRoomContainer user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])} eplId={eplId} {...rest} />
      ) : (
        <EPLChatRoomContainer2
          user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])}
          eplId={eplId}
          {...rest}
          unseenCount={unseenCount}
        />
      )}
    </ReduxProvider>
  )
}

const EPLChatRoomContainer = ({ user, eplId, room }: EPLChatRoomProps) => {
  const { data, isSuccess }: any = useGetUserUnseenCountQuery({
    userId: user.id,
    meta: { eplId },
  })

  const dispatch = useDispatch()
  return (
    <div>
      {isSuccess && (
        <div className="epl-chat-wrapper">
          <ForumIcon
            color="inherit"
            style={{ fontSize: 22, color: '#3A86BF' }}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(openEplChat({ eplId, room }))
            }}
          />
          {!!data.unseenCount && <div className="chat-unseen">{data.unseenCount}</div>}
        </div>
      )}
    </div>
  )
}

const EPLChatRoomContainer2 = ({ eplId, room, unseenCount }: EPLChatRoomProps) => {
  const seenStatus = useSelector((state: any) => state.chat.seenStatus)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSeenStatus({ eplId, status: false }))
  }, [unseenCount])

  return (
    <div>
      {
        <div className="epl-chat-wrapper">
          <ForumIcon
            color="inherit"
            style={{ fontSize: 22, color: '#3A86BF' }}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(openEplChat({ eplId, room }))
            }}
          />
          {!seenStatus[eplId] && unseenCount > 0 && <div className="chat-unseen">{unseenCount}</div>}
        </div>
      }
    </div>
  )
}
