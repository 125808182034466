import React from 'react'
import PropTypes from 'prop-types'

const NoContent = ({ item }) => {
  return (
    <div className="no-content">
      <div className="no-content__title">There are currently no {item}</div>
      <div className="no-content__text">Please contact your Atheneum contact if you have any questions</div>
    </div>
  )
}

NoContent.propTypes = {
  item: PropTypes.string,
}

export default NoContent
