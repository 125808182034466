import { extendObservable } from 'mobx'
import { getPlatformProjects, getCapiProjects, deleteCapiProject } from '@api/client'

class ActiveProjectsStore {
  constructor(growlStore) {
    extendObservable(this, {
      growlStore,
      page: 1,
      perPage: 6,
      allProjects: [],
      platformProjects: [],
      projects: [],
      currentProject: {},
      numOfActiveProjects: null,
      requestedExperts: null,
      items: [],
      initialized: false,
      sortBy: 'Date',
      statusFilter: 'All',
      project: null,
      confirmModalOpen: false,
    })
  }

  loadActiveProjects = async (client) => {
    try {
      const capiProjects = await getCapiProjects(client.account.parentAccount.parentAccountName)
      const platformProjects = await getPlatformProjects()

      const combinedProjects = capiProjects ? [...platformProjects, ...capiProjects] : platformProjects
      this.platformProjects.replace(platformProjects)
      this.allProjects.replace(combinedProjects)
      this.projects.replace(combinedProjects)
      const activeProjects = platformProjects.filter((project) => project.projectStatus !== 'Closed')
      this.numOfActiveProjects = activeProjects.length
      const items = combinedProjects.slice(0, this.perPage)
      this.items.replace(items)
      let requested = 0
      activeProjects.map((project) => {
        requested = requested + project.profilesRequired
        return requested
      })
      this.requestedExperts = requested
      this.page = 1
      this.filterProjects(this.statusFilter)
      this.initialized = true
    } catch (err) {
      console.error({ err })
    }
  }

  sortProjects = (sortBy) => {
    let projects = []

    if (sortBy === 'Interviewed') {
      projects = this.projects.sort((a, b) => b.profilesInterviewed - a.profilesInterviewed)
    } else if (sortBy === 'Contact') {
      projects = this.projects.sort((a, b) => {
        const contactA = a.clientContact?.toUpperCase() || 'a'
        const contactB = b.clientContact?.toUpperCase() || 'a'

        if (contactA > contactB) return 1
        return contactA < contactB ? -1 : 0
      })
    } else {
      projects = this.projects.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    }

    this.projects.replace(projects)
    const items = projects.slice(0, this.perPage)
    this.items.replace(items)
    this.page = 1
    this.sortBy = sortBy
  }

  filterProjects = (statusFilter) => {
    const allProjects = this.allProjects
    let projects = []

    if (statusFilter === 'Ongoing') {
      projects = allProjects.filter((project) =>
        project.id ? project.status === 'Open' : project.projectStatus === 'Open',
      )
    } else if (statusFilter === 'Completed') {
      projects = allProjects.filter((project) =>
        project.id ? project.status === 'Closed' : project.projectStatus === 'Closed',
      )
    } else if (statusFilter === 'Pending') {
      projects = allProjects.filter((project) =>
        project.id ? project.status === 'pending' : project.projectStatus === 'Pending',
      )
    } else if (statusFilter === 'Draft') {
      projects = allProjects.filter((project) => project.status && project.status === 'draft')
    } else {
      projects = allProjects
    }

    this.projects.replace(projects)
    this.sortProjects(this.sortBy)
    this.statusFilter = statusFilter
    this.page = 1
  }

  changePage = (page) => {
    const from = (page - 1) * this.perPage
    const to = page * this.perPage
    this.page = page
    this.items.replace(this.projects.slice(from, to))
  }

  openAreYouSure = (project) => {
    this.project = project
    this.confirmModalOpen = true
  }

  handleDelete = async () => {
    try {
      this.confirmModalOpen = false
      await deleteCapiProject(this.project.id)

      const projects = this.projects.filter((project) => project.id !== this.project.id)
      const allProjects = this.allProjects.filter((project) => project.id !== this.project.id)

      this.projects.replace(projects)
      this.allProjects.replace(allProjects)

      const items = projects.slice(0, this.perPage)
      this.items.replace(items)

      this.project = null
      this.growlStore.showSuccess('Project deleted.')
    } catch (err) {
      console.error({ err })
      this.growlStore.showError(err.message)
    }
  }

  handleCancel = () => {
    this.project = null
    this.confirmModalOpen = false
  }
}

export default ActiveProjectsStore
