import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Spinner = ({ center, spinnerHeight }) => {
  const classes = classNames({
    spinner: true,
    'spinner--center': center,
  })
  return (
    // eslint-disable-next-line
    <div className="spinner__container" style={{ minHeight: spinnerHeight }}>
      <div className={classes}>
        <div className="spinner__rectangle rect1" />
        <div className="spinner__rectangle rect2" />
        <div className="spinner__rectangle rect3" />
        <div className="spinner__rectangle rect4" />
        <div className="spinner__rectangle rect5" />
      </div>
    </div>
  )
}

Spinner.propTypes = {
  center: PropTypes.bool,
  spinnerHeight: PropTypes.string,
}

// Used when in Modal context
Spinner.defaultProps = {
  spinnerHeight: '625px',
  showCloseIcon: false,
  shouldCloseOnOverlayClick: false,
  hideButtons: true,
  class: 'modal--spinner',
}

export default Spinner
