"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteTagFromProject = exports.findTags = exports.deleteTag = exports.createTag = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function createTag(tagData) {
    return api_1.default.put('/api/tag', tagData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createTag = createTag;
function deleteTag(tagId) {
    return api_1.default.delete("/api/tag/".concat(tagId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteTag = deleteTag;
function findTags(query) {
    return api_1.default.get("/api/tag?q=".concat(query)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.findTags = findTags;
function deleteTagFromProject(tagId) {
    return api_1.default.delete("/api/tag/".concat(tagId, "/project")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteTagFromProject = deleteTagFromProject;
var api = {
    createTag: createTag,
    deleteTag: deleteTag,
    findTags: findTags,
    deleteTagFromProject: deleteTagFromProject,
};
exports.default = api;
