import { extendObservable } from 'mobx'
import { getSingleContractConsultations } from '@api/client'

class ConsultationListingStore {
  constructor() {
    extendObservable(this, {
      contractId: null,
      consultations: [],
      page: 1,
      perPage: 5,
      totalNumOfConsultations: null,
      loading: false,
    })
  }

  loadConsultations(contractId) {
    this.loading = true
    this.contractId = contractId
    getSingleContractConsultations(contractId, this.page, this.perPage).then((consultations) => {
      this.consultations.replace(consultations.rows)
      this.totalNumOfConsultations = consultations.count
      this.loading = false
    })
  }

  changePage = (page) => {
    this.page = page
    this.loadConsultations(this.contractId)
  }
}

export default ConsultationListingStore
