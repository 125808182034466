"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.highlightReplace = void 0;
function highlightReplace(val, hiReg) {
    if (!val)
        return val;
    var styling = "style=\"background-color:#FFD700 !important; z-index:10;\"";
    var replaceString = function (str) { return "<span ".concat(styling, ">").concat(str, "</span>"); };
    return val.replace(hiReg, replaceString);
}
exports.highlightReplace = highlightReplace;
