import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import find from 'lodash/find'

import Spinner from '@components/Spinner'
import InfoWrapperRow from '@components/InfoWrapper'
import { formatDate } from '@lib/helperFunctions'
import { NoContent } from '@components/NoContent'
import Pagination from '@components/Pagination'

class ConsultationListing extends Component {
  static propTypes = {
    consultationListingStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.loadSingleContractConsultations()
  }

  loadSingleContractConsultations() {
    const { consultationListingStore, match } = this.props
    const contractId = match.params.id
    consultationListingStore.loadConsultations(contractId)
  }

  render() {
    const { consultationListingStore } = this.props
    const { staticData } = this.props.staticDataStore
    const { loading, consultations, page, perPage, totalNumOfConsultations } = consultationListingStore
    const { currencies } = staticData.names
    return consultations.length === 0 && !loading ? (
      <NoContent item="consultations listed under this contract" />
    ) : (
      <div>
        {loading ? (
          <Spinner spinnerHeight="1000px" />
        ) : (
          consultations.map((consultation) => {
            const clients = consultation.project.clients
            return (
              <div key={consultation.id} className="sections-one__section">
                <div className="sections-one__link-container">
                  <b>
                    {consultation.project.caseCode && `${consultation.project.caseCode} - `}
                    {consultation.project.projectName}
                  </b>
                  <div className="sub-header__account">{consultation.project.office.officeName}</div>
                </div>
                <InfoWrapperRow
                  noSpace
                  label="Contacts"
                  text={clients.map((client, i) => (i + 1 < clients.length ? `${client.fullName}, ` : client.fullName))}
                />
                <InfoWrapperRow noSpace label="Delivery date" text={formatDate(consultation.deliveryDate)} />
                <InfoWrapperRow noSpace label="Delivered service" text={consultation.deliverable.name} />
                <InfoWrapperRow
                  noSpace
                  label="Fee"
                  text={`${renderValueOrCredits(
                    consultation,
                    consultation.contract,
                    staticData,
                  )} / ${renderFormattedFeeAmount(consultation.feeItems, currencies)}`}
                />
              </div>
            )
          })
        )}
        <Pagination
          page={page}
          perPage={perPage}
          total={totalNumOfConsultations}
          onChange={(p) => consultationListingStore.changePage(p)}
        />
      </div>
    )
  }
}

export default withRouter(inject('consultationListingStore', 'staticDataStore')(observer(ConsultationListing)))

function renderValueOrCredits(fee, contract, staticData) {
  const { currencies } = staticData.names
  const { CONTRACT_TYPES } = staticData.CONST
  const feeItem = find(fee.feeItems, (item) => item.feeTypeId)

  if (contract.contractTypeId === CONTRACT_TYPES.PAYG) {
    return `${feeItem.value} ${currencies[feeItem.currencyId]}`
  }

  return feeItem.credits ? `${feeItem.credits} credits` : '-'
}

function renderFormattedFeeAmount(feeItems, currencies) {
  if (!feeItems || !feeItems.length) return '-'

  const feeItem = find(feeItems, (item) => item.feeTypeId)
  if (!feeItem) return '-'

  const amount = feeItem.value
  const currencyId = feeItem.currencyId
  if (typeof amount !== 'number' || !currencyId) return '-'

  return `${amount} ${currencies[currencyId]}`
}
