import React from 'react'

const Logo = () => {
  return (
    <svg className="logo" viewBox="0 0 293 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -123.000000)" fill="#414042">
          <g id="Group" transform="translate(1.000000, 123.000000)">
            <path
              d="M27.8442,48.2332 L27.8442,45.4952 L32.4082,45.4952 L28.3652,34.9312 L12.5202,34.9312 L8.2802,45.4952 L13.4982,45.4952 L13.4982,48.2332 L0.0002,48.2332 L0.0002,45.4952 L4.0432,45.4952 L20.8662,3.8262 L24.8432,3.8262 L41.0152,45.4952 L43.7532,45.4952 L43.7532,48.2332 L27.8442,48.2332 Z M20.6702,14.6502 L14.0852,30.9522 L26.8662,30.9522 L20.6702,14.6502 Z"
              id="Fill-9"
            />
            <path
              d="M55.8101,49.0814 C51.7671,49.0814 49.0291,46.4074 49.0291,42.4944 L49.0291,22.6724 L44.7261,22.6724 L44.7261,20.5854 L49.0291,19.4774 L49.0291,10.1524 L56.4611,10.1524 L56.4611,19.3474 L63.1791,19.3474 L63.1791,22.6724 L56.4611,22.6724 L56.4611,43.0184 C56.4611,43.8634 57.3101,44.6474 58.2881,44.6474 C59.5261,44.6474 60.2441,44.3874 62.2671,43.1484 L63.6361,45.4954 C60.3101,48.1664 58.3541,49.0814 55.8101,49.0814"
              id="Fill-10"
            />
            <path
              d="M84.0425,48.2332 L84.0425,45.4952 L87.8915,45.4952 L87.8915,27.3002 C87.8915,23.3902 86.9775,22.1512 84.3035,22.1512 C81.7625,22.1512 80.7175,22.8022 76.4135,26.8452 L76.4135,45.4952 L80.2595,45.4952 L80.2595,48.2332 L64.9385,48.2332 L64.9385,45.4952 L68.7845,45.4952 L68.7845,4.8042 L64.1545,4.8042 L64.1545,2.7172 L73.5455,0.1732 L76.4135,0.1732 L76.4135,23.3902 C80.6535,19.0862 82.9345,17.9112 87.0435,17.9112 C92.5195,17.9112 95.5205,20.7792 95.5205,26.0612 L95.5205,45.4952 L99.3675,45.4952 L99.3675,48.2332 L84.0425,48.2332 Z"
              id="Fill-11"
            />
            <path
              d="M116.5786,49.0814 C107.8386,49.0814 102.0356,42.8844 102.0356,33.6264 C102.0356,24.1714 107.9686,17.9114 116.9696,17.9114 C124.5316,17.9114 129.5526,22.6054 129.5526,29.7804 L129.5526,32.2574 L110.0576,32.2574 C110.0576,40.3434 113.7076,45.3654 119.5766,45.3654 C121.9906,45.3654 123.2926,44.8414 128.3136,41.8434 L129.8136,44.7114 C123.4226,48.2994 121.1426,49.0814 116.5786,49.0814 L116.5786,49.0814 Z M121.4666,27.3664 C121.4666,23.4534 119.5766,20.7164 116.9026,20.7164 C113.4466,20.7164 110.7086,23.9744 110.1216,28.9324 L121.4666,28.9324 L121.4666,27.3664 Z"
              id="Fill-12"
            />
            <path
              d="M151.9795,48.2332 L151.9795,45.4952 L155.8285,45.4952 L155.8285,27.3002 C155.8285,23.3902 154.9805,22.1512 152.3065,22.1512 C149.7625,22.1512 148.6545,22.8022 144.4175,26.8452 L144.4175,45.4952 L148.1965,45.4952 L148.1965,48.2332 L132.8755,48.2332 L132.8755,45.4952 L136.7215,45.4952 L136.7215,22.6722 L132.1575,22.6722 L132.1575,20.5852 L141.5455,18.0412 L144.4175,18.0412 L144.4175,23.3902 C148.6545,19.0862 150.8715,17.9112 154.9805,17.9112 C160.5225,17.9112 163.4575,20.7792 163.4575,26.0612 L163.4575,45.4952 L167.3035,45.4952 L167.3035,48.2332 L151.9795,48.2332 Z"
              id="Fill-13"
            />
            <path
              d="M184.5151,49.0814 C175.7751,49.0814 169.9721,42.8844 169.9721,33.6264 C169.9721,24.1714 175.9051,17.9114 184.9061,17.9114 C192.4681,17.9114 197.4901,22.6054 197.4901,29.7804 L197.4901,32.2574 L177.9941,32.2574 C177.9941,40.3434 181.6441,45.3654 187.5141,45.3654 C189.9271,45.3654 191.2291,44.8414 196.2511,41.8434 L197.7501,44.7114 C191.3601,48.2994 189.0791,49.0814 184.5151,49.0814 L184.5151,49.0814 Z M189.4031,27.3664 C189.4031,23.4534 187.5141,20.7164 184.8391,20.7164 C181.3841,20.7164 178.6461,23.9744 178.0581,28.9324 L189.4031,28.9324 L189.4031,27.3664 Z"
              id="Fill-14"
            />
            <path
              d="M225.7856,48.7543 L222.9816,48.7543 L222.9816,43.2783 C218.6146,47.8423 216.3936,49.0813 212.4176,49.0813 C206.8086,49.0813 203.8736,46.2133 203.8736,40.6683 L203.8736,22.6723 L199.2466,22.6723 L199.2466,20.5853 L208.6356,18.0413 L211.5056,18.0413 L211.5056,39.3653 C211.5056,43.0813 212.6786,44.7113 215.3526,44.7113 C217.8966,44.7113 219.5256,43.6693 222.9816,39.9533 L222.9816,22.6723 L219.6556,22.6723 L219.6556,20.3253 L228.2636,18.0413 L230.6106,18.0413 L230.6106,44.2563 L235.1776,44.2563 L235.1776,46.4073 L225.7856,48.7543 Z"
              id="Fill-15"
            />
            <path
              d="M276.7744,48.2332 L276.7744,45.4952 L280.5544,45.4952 L280.5544,27.3002 C280.5544,23.6502 279.5764,22.2152 277.0984,22.2152 C274.4914,22.2152 272.6654,23.3902 268.8824,27.4972 L268.8824,45.4952 L272.4044,45.4952 L272.4044,48.2332 L257.6674,48.2332 L257.6674,45.4952 L261.1894,45.4952 L261.1894,27.3662 C261.1894,23.7812 260.0814,22.2152 257.4704,22.2152 C254.7994,22.2152 252.8424,23.3902 249.5174,27.0392 L249.5174,45.4952 L253.2974,45.4952 L253.2974,48.2332 L237.9754,48.2332 L237.9754,45.4952 L241.8224,45.4952 L241.8224,22.6722 L237.2574,22.6722 L237.2574,20.5852 L246.6464,18.0412 L249.5174,18.0412 L249.5174,23.1932 C253.5604,19.1502 256.1014,17.9112 259.9514,17.9112 C264.9054,17.9112 267.4494,19.8012 268.5584,24.1712 C272.3374,19.5412 275.2094,17.9112 279.5764,17.9112 C284.7274,17.9112 288.2504,21.1732 288.2504,25.9972 L288.2504,45.4952 L292.0324,45.4952 L292.0324,48.2332 L276.7744,48.2332 Z"
              id="Fill-16"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
