"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get = exports.update = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/settings';
function update(hierarchy, level, id, settings) {
    return api_1.default.put("".concat(base, "/").concat(hierarchy, "/").concat(level, "/").concat(id), settings).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
function get(hierarchy, level, id, settings) {
    return api_1.default.get("".concat(base, "/").concat(hierarchy, "/").concat(level, "/").concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.get = get;
var api = {
    update: update,
    get: get,
};
exports.default = api;
