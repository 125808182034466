"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBookmark = exports.createBookmark = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/bookmark';
function createBookmark(data) {
    return api_1.default.post(base, data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createBookmark = createBookmark;
function deleteBookmark(projectId) {
    return api_1.default.delete("".concat(base, "?projectId=").concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteBookmark = deleteBookmark;
var api = {
    createBookmark: createBookmark,
    deleteBookmark: deleteBookmark,
};
exports.default = api;
