import './chat.sass'

import React from 'react'
import { Provider as ReduxProvider, useSelector, useDispatch } from 'react-redux'
import { useGetUserUnseenCountQuery } from './redux/reducers/apiSlice'
import { toggleChatListOpen } from './redux/reducers/chatSlice'
import reduxStore from './redux/store'
import CloseIcon from '@material-ui/icons/Close'
import ChatIcon from '@material-ui/icons/Chat'
import { AtheneumIcon } from './AtheneumIcon'
import pick from 'lodash/pick'

import { ChatList } from '.'

export const ChatRobot = ({
  projectId = null,
  user,
  clientOfficeId = null,
  expertId = null,
  context,
}: {
  projectId: number | null
  user: any
  clientOfficeId: number | null
  expertId: number | null
  context: string
}) => {
  return (
    <ReduxProvider store={reduxStore}>
      <ChatRobotContainer
        user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])}
        projectId={projectId}
        clientOfficeId={clientOfficeId}
        expertId={expertId}
        context={context}
      />
    </ReduxProvider>
  )
}
const ChatRobotContainer = ({
  projectId,
  user,
  clientOfficeId,
  expertId,
  context,
}: {
  projectId: number | null
  user: any
  clientOfficeId: number | null
  expertId: number | null
  context: string
}) => {
  const chatListOpen = useSelector((state: any) => state.chat.chatListOpen)
  const dispatch = useDispatch()

  const { data, isSuccess }: any = useGetUserUnseenCountQuery({
    userId: user.id,
    meta: { projectId, clientOfficeId, expertId },
  })

  return (
    <div>
      {!chatListOpen && (
        <div className="robot-wrapper" onClick={() => dispatch(toggleChatListOpen(true))}>
          <AtheneumIcon />
          {isSuccess && !!data.unseenCount && <div className="chat-unseen">{data.unseenCount}</div>}
        </div>
      )}
      {chatListOpen && (
        <div className="chat-wrapper fixed list">
          <div className="chat-header">
            <div className="left">
              <ChatIcon style={{ fontSize: 32, color: '#fff' }} />
              <span>Messages</span>
            </div>
            <CloseIcon style={{ color: '#fff' }} onClick={() => dispatch(toggleChatListOpen(false))} />
          </div>
          <ChatList
            user={user}
            projectId={projectId}
            clientOfficeId={clientOfficeId}
            expertId={expertId}
            context={context}
          />
        </div>
      )}
    </div>
  )
}
