import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Button = (props) => {
  const {
    disabled,
    onClick,
    primary,
    secondary,
    white,
    orange,
    redWhite,
    greenWhite,
    orangeWhite,
    blueWhite,
    red,
    block,
    small,
    submit,
    className,
    thick,
    children,
    ...restProps
  } = props
  const type = submit ? 'submit' : 'button'
  const classes = classNames({
    [className]: className,
    button: true,
    'button--primary': primary,
    'button--secondary': secondary || !primary,
    'button--white': white,
    'button--orange': orange,
    'button--red': red,
    'button--red-white': redWhite,
    'button--green-white': greenWhite,
    'button--orange-white': orangeWhite,
    'button--blue-white': blueWhite,
    'button--full': block,
    'button--small': small,
    'button--thick': thick,
  })

  return (
    <button type={type} disabled={disabled} className={classes} onClick={onClick} {...restProps}>
      {children}
    </button>
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  block: PropTypes.bool,
  submit: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  white: PropTypes.bool,
  orange: PropTypes.bool,
  red: PropTypes.bool,
  redWhite: PropTypes.bool,
  greenWhite: PropTypes.bool,
  orangeWhite: PropTypes.bool,
  blueWhite: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  thick: PropTypes.bool,
}

export default Button
