"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOfficeTimezone = exports.getTimezone = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getTimezone(_a) {
    var latitude = _a.latitude, longitude = _a.longitude;
    var url = "/api/timezone/location?latitude=".concat(latitude, "&longitude=").concat(longitude);
    return api_1.default.get(url).then(api_1.extractData);
}
exports.getTimezone = getTimezone;
function getOfficeTimezone(officeId) {
    return api_1.default.get("/api/office/".concat(officeId, "/timezone")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getOfficeTimezone = getOfficeTimezone;
var api = {
    getOfficeTimezone: getOfficeTimezone,
    getTimezone: getTimezone,
};
exports.default = api;
