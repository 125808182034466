"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmailTemplate = exports.getProjectStatistics = exports.getByEpl = exports.getOne = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var baseUrl = '/api/email';
function getOne(id) {
    return api_1.default.get("".concat(baseUrl, "/").concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getOne = getOne;
function getByEpl(eplId) {
    return api_1.default.get("".concat(baseUrl, "/epl/").concat(eplId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getByEpl = getByEpl;
function getProjectStatistics(projectId) {
    return api_1.default.get("".concat(baseUrl, "/").concat(projectId, "/statistics")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectStatistics = getProjectStatistics;
function getEmailTemplate(email) {
    return api_1.default.get("".concat(baseUrl, "/email-template/").concat(email)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getEmailTemplate = getEmailTemplate;
var api = {
    getOne: getOne,
    getByEpl: getByEpl,
    getProjectStatistics: getProjectStatistics,
};
exports.default = api;
