import { extendObservable, observable } from 'mobx'

import { login, quickLogin, retrievePassword, resetPassword } from '@api/user'
import clientApi from '@api/api'
import tokenStorage from '@lib/tokenStorage'

class UserStore {
  constructor(socket, notificationStore) {
    extendObservable(this, {
      loading: false,
      user: observable.map(),
      error: null,
      isQuickLoginInProgress: !!tokenStorage.get(),
      socket: null,
      notificationStore,
    })

    this.socket = socket
    this.socket.on('connect', () => {
      if (this.user) {
        this.socket.emit('socketAuth', tokenStorage.get())
      }
    })

    if (this.isQuickLoginInProgress) {
      this.getUser()
    }
  }

  async getUser(externalToken = null) {
    if (externalToken) {
      tokenStorage.save(externalToken)
    }
    const token = tokenStorage.get()

    if (token) {
      try {
        const { user } = await quickLogin(token)
        this.user.merge(user)
        this.connectSocket()
      } catch (err) {
        this.handleInvalidToken(err)
      }
    }
    this.isQuickLoginInProgress = false
  }

  loginClient(data) {
    this.loading = true

    return login(data)
      .then(({ token, user }) => {
        tokenStorage.save(token)
        this.user.merge(user)
        this.connectSocket()
        this.loading = false
      })
      .catch((err) => {
        this.error = err.message
        this.loading = false
        throw err
      })
  }

  handleInvalidToken(error) {
    if (error && error.statusCode === 401 && error.message === 'Invalid token format') {
      tokenStorage.clear()
    }
  }

  forgotPassword(email) {
    return retrievePassword(email).catch((err) => {
      this.error = err.message
      throw err
    })
  }

  resetClientPassword(data) {
    return resetPassword(data).catch((err) => {
      this.error = err.message
      throw err
    })
  }

  logout() {
    this.user.clear()
    this.error = null
    tokenStorage.clear()
    delete clientApi.defaults.headers.Authorization
    this.socket.disconnect()
  }

  connectSocket() {
    this.socket.emit('socketAuth', tokenStorage.get())
    this.notificationStore.notificationListener(this.socket)
  }
}

export default UserStore
