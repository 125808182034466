"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGeneralAdjustment = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function createGeneralAdjustment(data) {
    return api_1.default.post("/api/accounting-adjustment", data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createGeneralAdjustment = createGeneralAdjustment;
var api = {
    createGeneralAdjustment: createGeneralAdjustment,
};
exports.default = api;
