import React from 'react'

import { Link } from 'react-router-dom'
import SidePanel from './SidePanel'
import Logo from './SidePanel/logo.js'

const ForgotPasswordSuccess = () => {
  return (
    <div className="auth">
      <SidePanel />
      <div className="form-login">
        <Logo className="auth-form-logo" />
        <h1 className="form-login__title">Forgot your password?</h1>
        <div className="form__label--black">
          Please check your email and follow the instructions to retrieve your password.
        </div>
        <Link className="form-login__link" to="/auth/login">
          Back to login
        </Link>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess
