export default {
  formId: 'registerProjectForm',
  projectDetailsFields: [
    {
      name: 'industries',
      label: 'Industry / sub-industry',
      component: 'tags-input',
      tooltip: 'Enter industries',
      disabled: true,
    },
    {
      name: 'seniority',
      label: 'Experience level',
      component: 'tags-input',
      tooltip: 'Enter experts experience level',
    },
    {
      name: 'languages',
      label: 'Expert language',
      component: 'tags-input',
      tooltip: 'Choose expert language',
      helperText: 'You can choose multiple languages',
    },
    {
      name: 'geography',
      label: 'Expert location',
      component: 'tags-input',
      tooltip: 'Choose the location of the expert you want to interview',
      helperText: 'You can choose multiple locations',
    },
    {
      name: 'roles',
      label: 'Profession',
      component: 'tags-input',
      tooltip: 'Enter experts profession',
    },
    {
      name: 'companies',
      label: 'Companies to add',
      component: 'tags-input',
      tooltip: 'Enter companies to include',
      helperText: 'You can enter multiple companies',
    },
    {
      name: 'competitors',
      label: 'Companies to exclude',
      component: 'tags-input',
      tooltip: 'Enter companies to exclude',
      helperText: 'You can enter multiple companies',
    },
    {
      name: 'requestedNumberOfCalls',
      label: 'Expert count',
      component: 'input',
      type: 'number',
      tooltip: 'Enter number of wanted experts',
    },
  ],
  formField: {
    projectName: {
      name: 'projectName',
      label: 'Give a name to your project*',
      requiredErrorMsg: 'Project name is required',
    },
    projectTypeId: {
      name: 'projectTypeId',
      label: 'Choose a project type*',
      requiredErrorMsg: 'Project Type is required',
    },
    projectDescription: {
      name: 'projectDescription',
      label: 'Project brief*',
      requiredErrorMsg: 'Project Brief is required',
    },
    applyBeforeDate: {
      name: 'applyBeforeDate',
      label: 'Deadline',
      requiredErrorMsg: 'Deadline is required',
    },
    industryId: {
      name: 'industryId',
      label: 'Industry*',
      requiredErrorMsg: 'Industry is required',
    },
    subIndustryId: {
      name: 'subIndustryId',
      label: 'Sub-industry*',
      requiredErrorMsg: 'Sub-industry is required',
    },
    projectDirectorName: {
      name: 'projectDirectorName',
      label: 'Project director*',
      requiredErrorMsg: 'Project Director is required',
    },
    projectDirectorEmail: {
      name: 'projectDirectorEmail',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
    },
    projectLeadName: {
      name: 'projectLeadName',
      label: 'Project Lead*',
      requiredErrorMsg: 'Project Lead is required',
    },
    projectLeadEmail: {
      name: 'projectLeadEmail',
      label: 'Email*',
      requiredErrorMsg: 'Email is required',
    },
    timezone: {
      name: 'timezone',
      label: 'Timezone*',
      requiredErrorMsg: 'Timezone is required',
    },
    industries: {
      name: 'industries',
      label: 'Industry / sub-industry',
      requiredErrorMsg: 'Industry / sub-industry is required',
    },
    industryName: {
      name: 'industryName',
      label: 'Industry',
      requiredErrorMsg: 'You entered a new subindustry, enter industry name so we can create it.',
    },
  },
}
