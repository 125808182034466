import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Spinner = ({ smallMargin, center, inline }) => {
  const classes = classNames({
    spinner: true,
    'spinner--small-margin': smallMargin,
    'spinner--center': center,
    'spinner--inline': inline,
  })
  // const classes = smallMargin ? 'spinner spinner--small-margin' : 'spinner';

  return (
    <div className={classes}>
      <div className="spinner__rectangle rect1" />
      <div className="spinner__rectangle rect2" />
      <div className="spinner__rectangle rect3" />
      <div className="spinner__rectangle rect4" />
      <div className="spinner__rectangle rect5" />
    </div>
  )
}

Spinner.propTypes = {
  smallMargin: PropTypes.bool,
  center: PropTypes.bool,
  inline: PropTypes.bool,
}

// Used when in Modal context
Spinner.defaultProps = {
  showCloseIcon: false,
  shouldCloseOnOverlayClick: false,
  hideButtons: true,
  class: 'modal--spinner',
}

export default Spinner
