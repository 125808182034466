import { extendObservable } from 'mobx'
import clientApi from '@api/api'
import moment from 'moment'
import _ from 'lodash'
import omit from 'lodash/omit'

class ProjectStore {
  constructor(staticDataStore) {
    extendObservable(this, {
      project: {},
      segments: [],
      activeSegementIndex: 0,
      staticDataStore,
    })
  }

  init = (user, project = {}) => {
    if (project.id) {
      const capiProject = omit(project, ['segments'])
      this.project = capiProject
      this.segments = project.segments
      this.activeSegmentIndex = 0
    } else {
      const scopeName = 'Initial scope'
      const client = user.get('client')
      const clientName = client.account.parentAccount.parentAccountName
      const timezoneId = client.office.address.timezoneId
      const timezone = timezoneId ? this.staticDataStore.staticData.names.timezones[timezoneId] : ''

      this.project = {
        projectName: '',
        projectTypeId: 1,
        projectDescription: '',
        industryName: '',
        industryId: null,
        subIndustryId: null,
        applyBeforeDate: moment().format('YYYY-MM-DD'),
        projectDirectorName: '',
        projectDirectorEmail: '',
        projectDirectorPhone: '',
        projectLeadName: '',
        projectLeadEmail: '',
        projectLeadPhone: '',
        chargeCode: null,
        timezone: timezone,
        status: 'open',
        platformClientId: client.account.parentAccountId,
        clientName,
        type: 'project',
      }
      this.segments = []
      this.createSegment(scopeName)
    }
  }

  updateSegment = (field, value) => {
    this.segments[this.activeSegmentIndex][field] = value
  }

  updateSegmentScreeningQuestions = (index, value) => {
    this.segments[this.activeSegmentIndex].screeningQuestions =
      this.segments[this.activeSegmentIndex].screeningQuestions || []
    this.segments[this.activeSegmentIndex].screeningQuestions[index] = value
  }

  deleteQuestion = (questionIndex) => {
    this.segments[this.activeSegmentIndex].screeningQuestions.splice(questionIndex, 1)
  }

  updateProject = (field, value) => {
    this.project[field] = value
  }

  createSegment = (name) => {
    const scopeName = name ? name : 'Scope ' + this.segments.length

    const segment = {
      segmentName: scopeName,
      industries: [],
      companies: [],
      competitors: [],
      geography: [],
      roles: [],
      screeningQuestions: [],
      seniority: [],
      languages: [],
      requestedNumberOfCalls: null,
    }

    this.segments = [...this.segments, segment]
    this.activeSegmentIndex = this.segments.length - 1
    return segment
  }

  deleteSegment = (index) => {
    if (this.segments.length === 1) return
    const filteredSegments = this.segments.filter((w, inx) => index !== inx)
    this.segments = filteredSegments
  }

  setActiveSegment = (index = 0) => {
    this.activeSegmentIndex = index
  }

  cancelAddSegment = () => {
    this.segments = this.segments.slice(0, -1)
    this.setActiveSegment()
  }

  cancelSegmentChanges = (initialSegment) => {
    this.segments[this.activeSegmentIndex] = initialSegment
    this.setActiveSegment()
  }

  cancelProjectChanges = (initialProject) => {
    this.project = initialProject
  }

  createProject = async (status) => {
    let industryId = this.project.industryId
    let subIndustryId = this.project.subIndustryId
    const segments = this.segments.map((segment) => {
      segment.screeningQuestions = segment.screeningQuestions.filter((s) => s)
      return segment
    })

    try {
      const projectStatus = await clientApi.post(`${process.env.REACT_APP_CAPI_SERVICE_URL}/platform-client-projects`, {
        ...this.project,
        industryId,
        subIndustryId,
        segments,
        status,
      })

      return projectStatus
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

export default ProjectStore
