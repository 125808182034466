import React from 'react'

const NotFound = () => {
  return (
    <div className="no-content">
      <div className="no-content__title">Oops! That page cannot be found.</div>
      <div className="no-content__text">
        It looks like nothing was found at this location. Maybe try one of the links under tabs or press back to go to
        the previous page
      </div>
    </div>
  )
}

export default NotFound
