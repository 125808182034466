import { extendObservable } from 'mobx'

class GrowlStore {
  constructor() {
    extendObservable(this, {
      list: [],
      defaultOffset: 6000,
    })
  }

  showError = (error = 'Unkown error occured', title = 'Error!') => {
    let message
    if (error.message) {
      message = error.message
    } else if (error.error) {
      message = error.error
    } else {
      message = error
    }

    this.addNotification({
      title: title,
      type: 'error',
      message: message,
      autoClose: true,
    })
  }

  showSuccess = (message, title = 'Success!') => {
    this.addNotification({
      title: title,
      type: 'success',
      message: message,
      autoClose: true,
    })
  }

  addNotification({ message, title, timeout, children, type, autoClose, linkTo, callback }) {
    const newNotificationId = Math.floor(Math.random() * 200000)
    const newNotification = {
      id: newNotificationId,
      title: title,
      message: message,
      children: children,
      type: type,
      autoClose: autoClose,
      linkTo: linkTo,
      callback: callback,
      timeout: timeout,
    }

    this.list.unshift(newNotification)
    if (autoClose) {
      setTimeout(() => {
        this.removeNotification(newNotificationId)
      }, timeout || this.defaultOffset)
    }
  }

  removeNotification(id) {
    this.list = this.list.filter((notification) => {
      return notification.id !== id
    })
  }
}

export default GrowlStore
