const tokenKey = 'clientAccessToken'

export function save(token) {
  localStorage.setItem(tokenKey, token)
  return token
}

export function get() {
  const token = localStorage.getItem(tokenKey)
  return token || null
}

export function clear() {
  localStorage.removeItem(tokenKey)
}

const credentials = {
  save,
  get,
  clear,
}

export default credentials
