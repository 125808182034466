"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStaticDataToOptions = void 0;
function convertStaticDataToOptions(staticDataItem) {
    return staticDataItem.map(function (item) {
        if (typeof item === 'object') {
            return {
                label: item.name || item.nameForInternal,
                value: item.id,
            };
        }
        return {
            label: item,
            value: item,
        };
    });
}
exports.convertStaticDataToOptions = convertStaticDataToOptions;
