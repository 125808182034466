import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import SidePanel from './SidePanel'
import Logo from './SidePanel/logo.js'

const ForgotPasswordSuccess = ({ setPassword }) => {
  return (
    <div className="auth">
      <SidePanel />
      <div className="form-login">
        <Logo className="auth-form-logo" />
        <h1 className="form-login__title">{setPassword ? 'Set password' : 'Reset password'}</h1>
        <div className="form__label--black">Your new password has been saved, you can log in now.</div>
        <Link className="form-login__link" to="/auth/login">
          Back to login
        </Link>
      </div>
    </div>
  )
}

ForgotPasswordSuccess.propTypes = {
  setPassword: PropTypes.bool,
}

export default ForgotPasswordSuccess
