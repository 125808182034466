import React from 'react'
import Avatar from '@components/Avatar'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import ContactIcon from '../Icons/ContactIcon'

const AtheneumContactLayout = ({ contact }) => {
  const links = contact.address.phones.map((phone) => (
    <li key={phone.phoneNum} className="atheneum-contacts__item">
      <a href={`tel:${phone.phoneNum}`} className="atheneum-contacts__link">
        {phone.phoneNum}
      </a>
    </li>
  ))

  links.push(
    <li key={contact.email} className="atheneum-contacts__item">
      <a href={`mailto:${contact.email}`} className="atheneum-contacts__link">
        {contact.email}
      </a>
    </li>,
  )

  const classes = 'atheneum-contacts__person'

  return (
    <li className={classes}>
      <Avatar src={contact.picture && contact.picture.path} alt={contact.fullName} />

      <div className="atheneum-contacts__info">
        <div className="atheneum-contacts__name">{contact.fullName}</div>
        <ul className="atheneum-contacts__items">{links}</ul>
      </div>
    </li>
  )
}

AtheneumContactLayout.propTypes = {
  contact: PropTypes.object,
}

const AtheneumContact = observer(AtheneumContactLayout)

const AtheneumContactsTitle = () => (
  <h3 className="atheneum-contacts__title">
    <ContactIcon />
    Your Atheneum contact
  </h3>
)

const AtheneumContacts = ({ contact, staticData }) => (
  <div>
    <AtheneumContactsTitle />
    <ul className="atheneum-contacts__people">
      <AtheneumContact contact={contact} staticData={staticData} />
    </ul>
  </div>
)

AtheneumContacts.propTypes = {
  contact: PropTypes.object,
  staticData: PropTypes.object,
}

AtheneumContacts.defaultProps = {
  shouldCloseOnOverlayClick: true,
  showCloseIcon: true,
  hideCancelButton: true,
  okText: 'Close',
}

export default inject('staticData')(observer(AtheneumContacts))
