"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeExpertInformLabel = exports.updateExpertInformLabel = exports.getLabels = exports.createLabels = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function createLabels(data) {
    return api_1.default.put('/api/expert/inform-label', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createLabels = createLabels;
function getLabels(userId) {
    if (userId === void 0) { userId = ''; }
    return api_1.default
        .get("/api/expert/inform-label".concat(userId ? '/' + userId : ''))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getLabels = getLabels;
function updateExpertInformLabel(data) {
    return api_1.default.patch('/api/expert/inform-label/expert', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateExpertInformLabel = updateExpertInformLabel;
function removeExpertInformLabel(data) {
    var expertId = data.expertId, labelId = data.labelId, labelType = data.labelType;
    return api_1.default
        .delete("/api/expert/inform-label/expert/".concat(expertId, "/").concat(labelId, "/").concat(labelType))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.removeExpertInformLabel = removeExpertInformLabel;
var api = {
    createLabels: createLabels,
    getLabels: getLabels,
    updateExpertInformLabel: updateExpertInformLabel,
    removeExpertInformLabel: removeExpertInformLabel,
};
exports.default = api;
