import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Formik, Form } from 'formik'
import pick from 'lodash/pick'

import { updateContactInformation } from '@api/client'
import { PhoneFields, EmailFields, AddressFields } from '@components/Form'
import getValidationSchema from './ContactInformationFormValidationSchema'
import Button from '@components/Button'

class ContactInformationForm extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    modalStore: PropTypes.object,
    growlStore: PropTypes.object,
  }

  submitForm = (data, actions) => {
    const { userStore, modalStore, growlStore } = this.props
    const userId = userStore.user.get('id')
    updateContactInformation(userId, data)
      .then(() => {
        modalStore.close()
        userStore.getUser()
        growlStore.showSuccess('Contact information successfully updated')
      })
      .catch((err) => {
        actions.setSubmitting(false)
        modalStore.close()
        growlStore.showError(err)
      })
  }

  render() {
    const { user } = this.props.userStore
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={initializeForm({ user })}
          validationSchema={getValidationSchema({ user })}
          onSubmit={this.submitForm}
          render={(form) => (
            <div className="form__contact-information">
              <h3 className="form__contact-information__title">Edit contact information</h3>
              <hr className="separator--thick" />
              <Form onSubmit={form.handleSubmit}>
                <PhoneFields
                  form={form}
                  name="address.phones"
                  label="Phones"
                  phoneNotRepeating={form.errors.phoneNotRepeating}
                />
                <hr />
                <EmailFields
                  form={form}
                  primaryEmail="email"
                  additionalEmails="address.emails"
                  emailNotRepeating={form.errors.emailNotRepeating}
                  label="Emails"
                />
                <hr />
                <AddressFields form={form} name="address" cityLabel="City / Postal code" postalCode />
                <hr />
                <div className="form__section">
                  <Button primary submit onClick={this.onSubmit} disabled={form.isSubmitting}>
                    Save
                  </Button>
                  <Button secondary onClick={this.props.modalStore.close}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          )}
        />
      </div>
    )
  }
}

ContactInformationForm.defaultProps = {
  hideButtons: true,
  showCloseIcon: true,
  shouldCloseOnOverlayClick: false,
}

export default withRouter(inject('userStore', 'modalStore', 'growlStore')(observer(ContactInformationForm)))

function initializeForm({ user }) {
  const initialValues = pick(toJS(user), ['address.phones', 'email', 'address.emails', 'address'])

  return initialValues
}
