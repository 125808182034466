import React, { Component } from 'react'

class TempResponsiveDashboard extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.add('temp-responsive')
    document.getElementsByTagName('html')[0].classList.add('temp-responsive')
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('temp-responsive')
    document.getElementsByTagName('html')[0].classList.remove('temp-responsive')
  }
}

export default TempResponsiveDashboard
