import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'

class IconDown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <Icon {...this.props}>
        <svg className={this.props.className} width="12px" height="8px" viewBox="0 0 12 8">
          <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
            <g transform="translate(-353.000000, -540.000000)" id="chevron_down" className="icon__fill" fill="#7E8DA2">
              <g transform="translate(359.000000, 544.000000) rotate(-270.000000) translate(-359.000000, -544.000000) translate(355.000000, 538.000000)">
                <polygon id="Shape" points="2 0 0.6 1.4 5.2 6 0.6 10.6 2 12 8 6" />
              </g>
            </g>
          </g>
        </svg>
      </Icon>
    )
  }
}

export default IconDown
