import { observer, propTypes as mobxPropTypes } from 'mobx-react'
import { Results, Helpers } from './Base'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const DEBOUNCE_DELAY_IN_MS = 100

class TypeAhead extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    filterItemsOnly: PropTypes.bool,
    itemToString: PropTypes.func,
    loadOptions: PropTypes.func, // set if component is async, do not use with options prop
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onInputValueChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, mobxPropTypes.observableArray]),
    placeholder: PropTypes.string,
    renderItem: PropTypes.func,
    value: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      items: props.options || [],
      loading: false,
    }
  }

  setupData = debounce((inputValue) => {
    const { options, loadOptions, itemToString, filterItemsOnly } = this.props

    if (options) {
      const orderedItems = Helpers.reorderItems({
        items: options,
        itemToString,
        inputValue,
        filterOnly: filterItemsOnly,
      })

      this.setState({ items: orderedItems })
      return
    }

    this.setState({ loading: true })

    return loadOptions(inputValue)
      .then((items) => {
        this.setState({
          items,
          loading: false,
        })
      })
      .catch((error) => {
        console.error(error) // ignore errors here
        this.setState({
          items: [],
          loading: false,
        })
      })
  }, DEBOUNCE_DELAY_IN_MS)

  onStateChange = (changes, stateAndHelpers) => {
    // make sure to clear the input if the model changed directly
    if (changes.hasOwnProperty('inputValue') && changes.inputValue === null) {
      stateAndHelpers.clearSelection()
    }
  }

  createInputHandler = ({ clearSelection }) => {
    return (event) => {
      const inputValue = event.target.value

      if (!inputValue) {
        this.setState({
          items: [],
        })
        clearSelection()
        return
      }

      this.setupData(inputValue)
    }
  }

  createKeyDownHandler = ({ closeMenu }) => {
    return (event) => {
      const inputValue = event.target.value

      if (event.keyCode === 13) {
        this.props.onChange(inputValue)
        closeMenu()
      }
    }
  }

  render() {
    const { onChange, onInputValueChange, itemToString, value, placeholder, renderItem, disabled, onBlur } = this.props

    return (
      <Downshift
        onChange={onChange}
        onInputValueChange={onInputValueChange}
        itemToString={itemToString}
        inputValue={value}
        defaultHighlightedIndex={null}
        render={({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          closeMenu,
          clearSelection,
          getButtonProps,
        }) => {
          const autocompleteContainerClasses = classNames({
            autocomplete__container: true,
            'autocomplete__container--disabled': disabled,
          })

          const autocompleteInputClasses = classNames({
            autocomplete__input: true,
            'autocomplete__input--disabled': disabled,
          })

          return (
            <div className={autocompleteContainerClasses}>
              <input
                {...getInputProps({
                  disabled,
                  onBlur,
                  placeholder,
                  className: autocompleteInputClasses,
                  value: inputValue !== null ? inputValue : '',
                  onChange: this.createInputHandler({ clearSelection }),
                  onKeyDown: this.createKeyDownHandler({ closeMenu }),
                })}
              />

              <Results
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                inputValue={inputValue}
                isOpen={isOpen}
                items={this.state.items}
                itemToString={itemToString}
                loading={this.state.loading}
                renderItem={renderItem}
                selectedItem={selectedItem}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default observer(TypeAhead)
