import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import Logo from './logo.js'
import LogoutIcon from '../Icons/Logout'

class Header extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    activeProjectsStore: PropTypes.object,
    activeContractsStore: PropTypes.object,
  }

  logoutClient = () => {
    const { userStore, activeProjectsStore, activeContractsStore } = this.props
    userStore.logout()
    activeProjectsStore.initialized = false
    activeContractsStore.initialized = false
  }

  render() {
    return (
      <div className="header">
        <div className="container">
          <Logo />
          <div className="header__logout">
            <LogoutIcon clickable onClick={this.logoutClient} />
          </div>
        </div>
      </div>
    )
  }
}

export default inject('userStore', 'activeProjectsStore', 'activeContractsStore')(observer(Header))
