"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteOffice = exports.getOffice = exports.getFeedbacks = exports.getProjectCount = exports.update = exports.create = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/office';
function create(officeFormData) {
    var officeData = officeFormData;
    return api_1.default.post(base, officeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(oldOfficeData, officeFormData) {
    var officeData = officeFormData;
    return api_1.default.put("".concat(base, "/").concat(oldOfficeData.id), officeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
function getProjectCount(officeId) {
    return api_1.default.get("".concat(base, "/").concat(officeId, "/projects-count")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectCount = getProjectCount;
function getFeedbacks(officeId) {
    return api_1.default.get("".concat(base, "/feedbacks/").concat(officeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeedbacks = getFeedbacks;
function getOffice(officeId) {
    return api_1.default.get("".concat(base, "/").concat(officeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getOffice = getOffice;
function deleteOffice(officeId) {
    return api_1.default.delete("".concat(base, "/").concat(officeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteOffice = deleteOffice;
var api = {
    create: create,
    update: update,
    getProjectCount: getProjectCount,
    getFeedbacks: getFeedbacks,
    getOffice: getOffice,
    deleteOffice: deleteOffice,
};
exports.default = api;
