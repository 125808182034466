import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import Spinner from '@components/Spinner'
import { formatDate } from '@lib/helperFunctions'
import Pagination from '@components/Pagination'
import { NoContent } from '@components/NoContent'

const ProjectListing = ({ singleContractStore, subIndustries }) => {
  const { loadingMore, projects, totalNumOfProjects, perPage, page } = singleContractStore
  return projects.length === 0 && !loadingMore ? (
    <NoContent item="projects listed under this contract" />
  ) : (
    <div>
      {loadingMore ? (
        <Spinner />
      ) : (
        projects.map((project) => (
          <div key={project.id} className="sections-one__section">
            <div className="sections-one__link-container">
              <b>
                {project.caseCode && `${project.caseCode} - `}
                {project.projectName}
              </b>
              <div className="sub-header__account">{subIndustries[project.subIndustryId]}</div>
            </div>
            <div className="info-wrapper__row">
              {formatDate(project.startDate)} - {formatDate(project.endDate)}
            </div>
          </div>
        ))
      )}
      <Pagination
        total={totalNumOfProjects}
        perPage={perPage}
        page={page}
        onChange={(p) => singleContractStore.changePage(p)}
      />
    </div>
  )
}

ProjectListing.propTypes = {
  singleContractStore: PropTypes.object,
  subIndustries: PropTypes.object,
}

export default inject('singleContractStore')(observer(ProjectListing))
