import clientApi from '@api/api'
import { extendObservable } from 'mobx'
import snakeCase from 'lodash/snakeCase'

class StaticData {
  constructor() {
    extendObservable(this, {
      initialized: false,
      loading: false,
      error: null,
    })
    this.staticData = null
  }

  decorateWithConstAndNames = (data) => {
    const resources = [
      'accountTypes',
      'conditions',
      'contractCategories',
      'contractConditions',
      'contractCoverages',
      'contractStatuses',
      'contractPriorities',
      'contractTypes',
      'contractTypeDetails',
      'countries',
      'currencies',
      'employeePositions',
      'eplStatuses',
      'industries',
      'invoicingCoverages',
      'invoiceStatuses',
      'languageProficiencies',
      'languages',
      'permissions',
      'phoneTypes',
      'projectStatuses',
      'projectTypes',
      'projectRequiredResources',
      'subIndustries',
      'timezones',
      'eplOutputTypes',
      'expertFocusRegions',
      'expertStatuses',
      'eplPreliminaryTags',
      'deliverableTypes',
      'feeTypes',
      'paymentServiceTypes',
      'paymentTransferMethods',
      'costTypes',
      'paymentStatuses',
      'expertPaymentDetailStatuses',
      'eplStatusComments',
      'vatClasses',
      'pricings',
      'replyStatuses',
      'invoiceTypes',
      'taxTypes',
      'invoicePaymentStatuses',
      'userTypes',
      'complianceTypes',
    ]

    let CONST = {}
    data.names = {}

    resources.forEach((resource) => {
      data.names[resource] = {}

      let constCat = snakeCase(resource).toUpperCase()
      CONST[constCat] = {}

      data[resource].forEach((item) => {
        if (item.name) {
          data.names[resource][item.id] = item.name
          CONST[constCat][snakeCase(item.name).toUpperCase()] = item.id
        }
      })
    })

    data.CONST = CONST

    return data
  }

  init() {
    if (this.initialized) return
    this.loading = true
    this.initialized = true
    return clientApi
      .get('/api/static-data')
      .then((data) => {
        data = this.decorateWithConstAndNames(data)
        this.staticData = data
        this.loading = false
      })
      .catch((err) => {
        this.error = err
      })
  }
}

export default new StaticData()
