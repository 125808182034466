import React from 'react'
import propTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Field, FieldArray } from 'formik'
import get from 'lodash/get'

import RepeatableAction from './RepeatableAction'
import Dropdown from './Dropdown'
import Input from './Input'

const PhoneFields = ({ form, name, label, phoneNotRepeating, inputProps, ...props }) => {
  let phones = get(form.values, name)
  if (phones.length === 0) {
    phones = [{ phoneTypeId: null, phoneNum: '' }]
  }
  const { staticData } = props.staticDataStore

  return (
    <div className="form__input-group">
      <label className="form__label">{label}</label>
      <div className="form__input-wrapper">
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <div className="form__repeatable-input-wrapper">
              {phones.map((phone, index) => (
                <div className="form__repeatable-input form__repeatable-input--multiple" key={index}>
                  <Field
                    name={`${name}[${index}].phoneTypeId`}
                    placeholder="Type"
                    itemToString={(item) => item && item.name}
                    options={staticData.phoneTypes}
                    component={Dropdown}
                  />
                  <Field name={`${name}[${index}].phoneNum`} component={Input} {...inputProps} />
                  <RepeatableAction
                    fieldValues={phones}
                    arrayHelpers={arrayHelpers}
                    index={index}
                    initialObject={{ phoneTypeId: null, phoneNum: '' }}
                  />
                </div>
              ))}
            </div>
          )}
        />
        {phoneNotRepeating ? <div className="form__input-error">{phoneNotRepeating}</div> : null}
      </div>
    </div>
  )
}

PhoneFields.propTypes = {
  form: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]),
  phoneNotRepeating: propTypes.string,
  inputProps: propTypes.object,
  staticDataStore: propTypes.object,
}

export default inject('staticDataStore')(observer(PhoneFields))
