import _ from 'lodash'
import React, { Fragment } from 'react'

type FeatureAccessManagerProps = {
  entity: object
  type: string
  path: string
  allowedOptions: Array<string | number>
  children: React.ReactNode
}

const FeatureAccessManager = ({ entity, type, path, allowedOptions = [], children }: FeatureAccessManagerProps) => {
  const resolvedType = entity[type]
  if (!resolvedType) throw Error(`Only above entities are permitted ${Object.keys(entity).toString()}`)

  const elementOptions = [_.get(resolvedType, path)].flat()
  const existsInAllowedOptions = _.intersection(elementOptions, allowedOptions)

  if (!existsInAllowedOptions.length) return null
  return <Fragment>{children}</Fragment>
}

export default FeatureAccessManager
