"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAll = exports.deleteOne = exports.update = exports.create = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/epd-rejection-reason';
function create(formData) {
    return api_1.default.post(base, formData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(id, formData) {
    return api_1.default.put("".concat(base, "/").concat(id), formData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
function deleteOne(id) {
    return api_1.default.delete("".concat(base, "/").concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteOne = deleteOne;
function getAll() {
    return api_1.default.get("".concat(base)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAll = getAll;
var api = {
    create: create,
    update: update,
    deleteOne: deleteOne,
    getAll: getAll,
};
exports.default = api;
