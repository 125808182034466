"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressById = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getAddressById(addressId) {
    return api_1.default.get("/api/address/".concat(addressId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAddressById = getAddressById;
var api = {
    getAddressById: getAddressById,
};
exports.default = api;
