"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePicture = exports.getQuickLoginToken = exports.getPasswordStrength = exports.resetPassword = exports.forgotPassword = exports.quickLogin = exports.login = exports.enableClientContact = exports.disableClientContact = exports.enablePlatformAccess = exports.preventPlatformAccess = exports.sendPlatformInvitationEmail = exports.checkEmailBulk = exports.checkEmail = exports.getUserAddressByEmail = exports.getAddress = exports.getLanguages = exports.getCurrentUser = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getCurrentUser() {
    return api_1.default.get('/api/user/current').then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getCurrentUser = getCurrentUser;
function getLanguages(userId) {
    return api_1.default.get("/api/user/".concat(userId, "/language")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getLanguages = getLanguages;
function getAddress(addressId) {
    return api_1.default.get("/api/address/".concat(addressId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAddress = getAddress;
function getUserAddressByEmail(email) {
    return api_1.default.get("/api/user/address/".concat(email)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getUserAddressByEmail = getUserAddressByEmail;
function checkEmail(_a) {
    var email = _a.email, id = _a.id;
    return api_1.default
        .post('/api/email-check', {
        email: email,
        id: id,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.checkEmail = checkEmail;
function checkEmailBulk(_a) {
    var emails = _a.emails;
    return api_1.default
        .post('/api/email-check-bulk', {
        emails: emails,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.checkEmailBulk = checkEmailBulk;
function sendPlatformInvitationEmail(userId) {
    return api_1.default.get("/api/user/".concat(userId, "/send-platform-invitation-email")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendPlatformInvitationEmail = sendPlatformInvitationEmail;
function preventPlatformAccess(id) {
    return api_1.default.put("/api/user/".concat(id, "/prevent-platform-access")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.preventPlatformAccess = preventPlatformAccess;
function enablePlatformAccess(id) {
    return api_1.default.put("/api/user/".concat(id, "/enable-platform-access")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.enablePlatformAccess = enablePlatformAccess;
function disableClientContact(_a) {
    var contactId = _a.contactId, commentPrefix = _a.commentPrefix, comment = _a.comment;
    return api_1.default
        .put("/api/user/".concat(contactId, "/disable-client-contact"), {
        commentPrefix: commentPrefix,
        comment: comment,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.disableClientContact = disableClientContact;
function enableClientContact(_a) {
    var contactId = _a.contactId, commentPrefix = _a.commentPrefix, comment = _a.comment;
    return api_1.default
        .put("/api/user/".concat(contactId, "/enable-client-contact"), {
        commentPrefix: commentPrefix,
        comment: comment,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.enableClientContact = enableClientContact;
function login(data) {
    return api_1.default.post('/api/login', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.login = login;
function quickLogin(token) {
    return api_1.default.post('/api/quick-login', { token: token }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.quickLogin = quickLogin;
function forgotPassword(data) {
    return api_1.default.post('/api/forgot-password', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.forgotPassword = forgotPassword;
function resetPassword(data) {
    return api_1.default.post('/api/reset-password', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.resetPassword = resetPassword;
function getPasswordStrength(password) {
    return api_1.default.post('/api/password-strength', { password: password }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getPasswordStrength = getPasswordStrength;
function getQuickLoginToken(userId) {
    return api_1.default
        .get('/api/quick-login-token/' + userId)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getQuickLoginToken = getQuickLoginToken;
function updatePicture(userId, pictureId) {
    return api_1.default.post("/api/user/".concat(userId, "/update-picture"), { pictureId: pictureId });
}
exports.updatePicture = updatePicture;
var api = {
    getCurrentUser: getCurrentUser,
    getLanguages: getLanguages,
    getAddress: getAddress,
    getUserAddressByEmail: getUserAddressByEmail,
    preventPlatformAccess: preventPlatformAccess,
    enablePlatformAccess: enablePlatformAccess,
    sendPlatformInvitationEmail: sendPlatformInvitationEmail,
    login: login,
    quickLogin: quickLogin,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    getPasswordStrength: getPasswordStrength,
    checkEmail: checkEmail,
    updatePicture: updatePicture,
};
exports.default = api;
