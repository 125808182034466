import React, { Component } from 'react'
import propTypes from 'prop-types'
import { observer } from 'mobx-react'

import Button from '@components/Button'
import Action from '@components/Action'
import PlusIcon from '@components/Icons/Plus'
import DeleteIcon from '@components/Icons/Delete'

class RepeatableAction extends Component {
  static propTypes = {
    arrayHelpers: propTypes.object.isRequired,
    index: propTypes.number.isRequired,
    initialObject: propTypes.object.isRequired,
    disabled: propTypes.bool,
    allowAdd: propTypes.bool,
    allowDelete: propTypes.bool,
    fieldValues: propTypes.array,
  }

  static defaultProps = {
    disabled: false,
    fieldValues: [],
  }

  onAddAction = () => {
    this.props.arrayHelpers.push(this.props.initialObject)
  }

  onDeleteAction = () => {
    this.props.arrayHelpers.remove(this.props.index)
  }

  renderAddAction = () => {
    return (
      <Button small className="form__repeatable-action--add" onClick={this.onAddAction} secondary>
        <PlusIcon blue />
      </Button>
    )
  }

  renderDeleteAction = () => {
    return (
      <Action className="form__repeatable-action--delete" right onClick={this.onDeleteAction}>
        <DeleteIcon blue />
      </Action>
    )
  }

  render() {
    const { disabled, allowAdd, allowDelete, fieldValues } = this.props

    if (disabled) {
      return null
    }

    const renderAdd = allowAdd || fieldValues.length - 1 === this.props.index
    const renderDelete = allowDelete || fieldValues.length > 1

    return (
      <div>
        {renderAdd ? this.renderAddAction() : null}
        {renderDelete && !disabled ? this.renderDeleteAction() : null}
      </div>
    )
  }
}

export default observer(RepeatableAction)
