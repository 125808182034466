"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFormattedNumber = void 0;
/**
 * Checks if a value is a string containing a formatted number.
 *
 * @param {string} value
 * @returns {Boolean}
 */
function isFormattedNumber(value) {
    var checkIfFormattedNumberRegExp = /^(\-)?([0-9]*.[0-9]+)|([0-9]+.[0-9]*)$/;
    return checkIfFormattedNumberRegExp.test(value);
}
exports.isFormattedNumber = isFormattedNumber;
