import React, { Component } from 'react'
import Downshift from 'downshift'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Results } from './Base'
import ArrowDown from '../Icons/ArrowDown'

class Dropdown extends Component {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    itemToString: PropTypes.func,
    selectedItem: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }

  render() {
    const { options, itemToString, selectedItem, onChange, onBlur, placeholder, disabled } = this.props
    const placeholderText = placeholder || 'Select an item ...'
    const inputText = selectedItem ? renderItem(itemToString, selectedItem) : placeholderText
    return (
      <Downshift
        selectedItem={selectedItem}
        onChange={onChange}
        itemToString={itemToString}
        render={({ isOpen, getButtonProps, getItemProps, highlightedIndex, selectedItem, inputValue }) => {
          const arrowIconClassName = classNames({
            'autocomplete__arrow-icon': true,
            'icon-animate': true,
            'icon-rotate': isOpen,
          })
          const classes = classNames({
            autocomplete__input: true,
            'autocomplete__input--dropdown': true,
            'autocomplete__input--disabled': disabled,
            'autocomplete__input--placeholder': inputText === placeholderText,
          })
          return (
            <div className="autocomplete__container">
              <button className={classes} {...getButtonProps()} disabled={disabled} onBlur={onBlur}>
                {selectedItem ? renderItem(itemToString, selectedItem) : placeholderText}
              </button>

              <ArrowDown className={arrowIconClassName} {...getButtonProps()} />

              <Results
                showResultsOnEmptyInput
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                inputValue={inputValue}
                isOpen={isOpen}
                items={options}
                itemToString={itemToString}
                selectedItem={selectedItem}
                showAllItemsOnEmpty
              />
            </div>
          )
        }}
      />
    )
  }
}

export default Dropdown

function renderItem(itemToString, item) {
  if (itemToString) return itemToString(item)
  return item
}
