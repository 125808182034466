import { extractData, errorHandler } from './api'
import clientApi from './api'

export function getPlaceSuggestions(placeString, types) {
  const encodedPlaceString = encodeURIComponent(placeString)
  return clientApi.get(`/api/googleplaces/autocomplete-places?input=${encodedPlaceString}&types=${types}`)
}

export function geocodeByPlaceId(placeId) {
  return clientApi.get(`/api/googleplaces/geocode-by-placeid?placeId=${placeId}`)
}

export function getTimezone({ latitude, longitude }) {
  const url = `/api/timezone/location?latitude=${latitude}&longitude=${longitude}`
  return clientApi.get(url)
}

export function getCompanySuggestions(companyString) {
  const encodedCompanyString = encodeURIComponent(companyString)
  return clientApi.get(`/api/company/typeahead?q=${encodedCompanyString}`).then(extractData).catch(errorHandler)
}

export function getPositionSuggestions(positionString, limit) {
  const encodedPositionString = encodeURIComponent(positionString)
  let baseUrl = `/api/typeahead/positions?q=${encodedPositionString}`
  if (limit) {
    baseUrl = baseUrl + `&limit=${limit}`
  }
  return clientApi.get(baseUrl).then(extractData).catch(errorHandler)
}
