import React from 'react'
import PropTypes from 'prop-types'

const NoProfilePicIcon = ({ className }) => {
  return (
    // <div className="svg-icon interview-icon">
    <svg className={className} width="75px" height="75px" viewBox="0 0 57 57">
      <defs>
        <polygon
          id="path-1"
          points="-1.96966873e-14 47.8445226 -1.96966873e-14 56.8267477 57 56.8267477 57 0 -1.96966873e-14 0 -1.96966873e-14 47.8445226 56.9999309 47.8445226 56.9999309 1.72202266e-05 -6.90909091e-05 1.72202266e-05 -6.90909091e-05 47.8445226"
        />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-12" transform="translate(0.000000, 0.000000)">
          <ellipse id="Oval-4" fill="#CBD2E2" cx="28.5" cy="29.2796353" rx="27.804878" ry="27.7203647" />
          <g id="Group-11">
            <g id="Page-1">
              <g id="Group-3">
                <g id="Clip-2" />
                <path
                  d="M20.8740218,40.5958404 C22.7866309,36.8278826 18.5971309,32.1243499 17.5985945,29.6467037 C16.6848673,27.3803497 16.7258036,25.4575392 17.0849036,23.2760809 C17.1413855,21.5881543 18.2407945,18.2816986 19.3966855,16.2967231 C20.3418491,13.4788052 23.7352491,13.0486439 26.4615764,12.1667961 C27.1353855,11.9489602 27.8238764,11.8833512 28.4999309,11.9191693 L28.4999309,11.9191693 C29.1759855,11.8833512 29.8648218,11.9489602 30.5384582,12.1667961 C33.2646127,13.0486439 36.6581855,13.4788052 37.6033491,16.2967231 C38.75924,18.2816986 39.8586491,21.5881543 39.9151309,23.2760809 C40.2740582,25.4575392 40.3151673,27.3803497 39.40144,29.6467037 C38.4027309,32.1243499 34.2135764,36.8278826 36.1260127,40.5958404 C38.3707764,45.0179946 44.5917218,45.7958322 49.2912855,47.8445226 C54.07134,42.7628337 56.9999309,35.9295034 56.9999309,28.4133911 C56.9999309,12.7211152 44.2400491,1.72202266e-05 28.4999309,1.72202266e-05 C12.7598127,1.72202266e-05 -6.90909091e-05,12.7211152 -6.90909091e-05,28.4133911 C-6.90909091e-05,35.9295034 2.92852182,42.7628337 7.70874909,47.8445226 C12.40814,45.7958322 18.6292582,45.0179946 20.8740218,40.5958404"
                  id="Fill-1"
                  fill="#CBD2E2"
                  mask="url(#mask-2)"
                />
              </g>
              <path
                d="M28.5,56.8267477 C36.7009182,56.8267477 44.0917455,53.3720259 49.2911818,47.8445053 C44.5917909,45.795815 38.3706727,45.0179773 36.1259091,40.5958232 C34.2134727,36.8278654 38.4028,32.1243327 39.4013364,29.6468587 C40.3150636,27.3803325 40.2741273,25.457522 39.9152,23.2760637 C39.8587182,21.5881371 38.7593091,18.2818536 37.6032455,16.2967058 C36.6580818,13.478788 33.2646818,13.0486267 30.5385273,12.1667789 C29.8647182,11.948943 29.1760545,11.883334 28.5,11.919152 L28.5,11.919152 C27.8239455,11.883334 27.1354545,11.948943 26.4614727,12.1667789 C23.7353182,13.0486267 20.3419182,13.478788 19.3967545,16.2967058 C18.2406909,18.2818536 17.1412818,21.5881371 17.0848,23.2760637 C16.7258727,25.457522 16.6849364,27.3803325 17.5986636,29.6468587 C18.5972,32.1243327 22.7867,36.8278654 20.8740909,40.5958232 C18.6293273,45.0179773 12.4082091,45.795815 7.70881818,47.8445053 C12.9082545,53.3720259 20.2990818,56.8267477 28.5,56.8267477"
                id="Fill-4"
                fill="#7B8DA3"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

NoProfilePicIcon.propTypes = {
  className: PropTypes.string,
}

export default NoProfilePicIcon
