import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Spinner from '@components/Spinner'
import Button from '@components/Button'
import Input from '@components/Form/Components/Input'
import { showDialog, isCancellationError } from '@components/Dialog'
import { formatDate, renderExpertRate } from '@lib/helperFunctions'
import { Error } from '@components/NoContent'
import { ChatRoom } from '@atheneum/messaging'

import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

class ComplianceAuditSingle extends Component {
  static propTypes = {
    complianceAuditStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    epl: PropTypes.object,
  }

  constructor(props) {
    super(props)

    const engagementDisabledTypes = [
      this.props.staticDataStore.staticData.CONST.COMPLIANCE_TYPES.CID,
      this.props.staticDataStore.staticData.CONST.COMPLIANCE_TYPES.EY,
    ]
    this.state = {
      error: null,
      engagementRequired:
        this.props.epl.project.complianceRequired &&
        !engagementDisabledTypes.includes(this.props.epl.project.complianceTypeId),
    }
  }

  initializeForm(epl) {
    const initialValues = {
      engagementManager: epl.project.engagementManager || null,
      engagementCode: epl.project.engagementCode || null,
    }
    return initialValues
  }

  submitForm =
    (epl, eplStatusId, fullName = '') =>
    (form) => {
      if (form.isValid) {
        this.setState({ error: null })
        this.handleEplUpdate(epl, { eplStatusId, ...form.values }, fullName)
      } else {
        const accountId = this.props.epl.project.parentAccountId
        const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'
        this.setState({ error: `Engagement ${em} and Engagement Code are required to approve this profile` })
      }
    }

  getValidationSchema() {
    const accountId = this.props.epl.project.parentAccountId
    const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'
    const engagementRequired = (s, msg) =>
      this.state.engagementRequired ? s.required(msg) : s.notRequired().default('')
    return yup.object().shape({
      engagementManager: engagementRequired(yup.string().nullable(), `Engagement ${em} is required`),
      engagementCode: engagementRequired(yup.string().nullable(), 'Engagement Code is required'),
      clientComments: yup.string(),
    })
  }

  handleEplUpdate = (
    epl,
    { eplStatusId, engagementManager = null, engagementCode = null, clientComments = null },
    fullName = '',
  ) => {
    const { EPL_STATUSES } = this.props.staticDataStore.staticData.CONST
    showDialog({
      title: `Are you sure you want to ${eplStatusId === EPL_STATUSES.REJECTED ? 'reject' : 'approve'} this profile?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    })
      .then(async () => {
        const { complianceAuditStore, growlStore } = this.props
        await complianceAuditStore.updateEplStatus(
          epl,
          {
            eplStatusId,
            engagementManager,
            engagementCode,
            clientComments,
          },
          fullName,
        )
        growlStore.showSuccess()
        await complianceAuditStore.loadAuditEpls()
      })
      .catch((err) => {
        if (isCancellationError(err)) return
        this.props.growlStore.showError(err)
      })
  }

  renderExpertName = (epl) => {
    if (!epl.expert.fullName) return
    return <div>{epl.expert.fullName}</div>
  }

  renderEplExperience = (experience) => {
    return (
      <div>
        <div className="info-wrapper__row">
          <b>{experience.position.name}</b>
        </div>
        <div className="info-wrapper__row">
          <b>
            {experience.company.name} ({formatDate(experience.startDate)} -{' '}
            {experience.current ? 'Now' : formatDate(experience.endDate)})
          </b>
        </div>
        <div className="info-wrapper__row">{experience.location}</div>
      </div>
    )
  }

  render() {
    const { staticDataStore, userStore, epl } = this.props

    const { loading, error } = this.props.complianceAuditStore

    const engagementManager = epl.project.engagementManager
    const engagementCode = epl.project.engagementCode
    const requestor = epl.project.clientToProjects[0].client.fullName

    if (loading) return <Spinner />
    if (error) return <Error error={error} />

    const { currencies, pricings } = staticDataStore.staticData.names
    const { SUBMITTED, REJECTED } = staticDataStore.staticData.CONST.EPL_STATUSES
    const fullName = userStore.user.get('fullName')

    const accountId = this.props.epl.project.parentAccountId
    const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'
    return (
      <div key={epl.id} className="sections-one__section">
        {epl.project && epl.project.clients && epl.project.clients.length > 0 && (
          <ChatRoom
            project={epl.project}
            clientOfficeId={epl.project.clients[0]?.client.officeId}
            user={userStore.user}
            context="client"
            epl={epl}
          />
        )}
        <div className="sections-one__link-container">
          <b>{epl.project.projectName}</b> ({epl.segment.name})
        </div>
        {this.renderExpertName(epl)}
        {this.renderEplExperience(epl.relevantExperience.experience)}
        {renderExpertRate({ epl, pricings, currencies })}
        <Link className="sections-one__link epl__link" to={`/audit/${epl.id}`}>
          View full profile
        </Link>
        <div className="epl_engagement_form">
          {requestor && <div className="requestor-section">Requestor: {requestor}</div>}

          <Formik
            isInitialValid={(engagementManager && engagementCode) || !this.state.engagementRequired}
            enableReinitialize
            initialValues={this.initializeForm(epl)}
            validationSchema={this.getValidationSchema()}
            render={(form) => (
              <Form>
                <Field name={'engagementManager'} label={`Engagement ${em}`} component={Input} />
                <Field name={'engagementCode'} label="Engagement Code" component={Input} />
                <Field name={'clientComments'} label="Comments" component={Input} />
                {this.state.error && <div className="error-message">{this.state.error}</div>}
                <div className="epl__actions">
                  <div className="epl__action">
                    <Button
                      className="button--green"
                      onClick={(event) => this.submitForm(epl, SUBMITTED, fullName)(form)}
                    >
                      Approve profile
                    </Button>
                  </div>
                  <div className="epl__action">
                    <Button className="button--red" onClick={(event) => this.submitForm(epl, REJECTED, fullName)(form)}>
                      Reject profile
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(
  inject(
    'complianceAuditStore',
    'atheneumContactStore',
    'staticDataStore',
    'modalStore',
    'userStore',
    'growlStore',
  )(observer(ComplianceAuditSingle)),
)
