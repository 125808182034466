import React from 'react'

const ContactIcon = () => {
  return (
    <div className="svg-icon contact-icon">
      <svg className="" width="24px" height="24px" viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none">
          <g transform="translate(-20.000000, -268.000000)">
            <g transform="translate(20.000000, 246.000000)">
              <g transform="translate(0.000000, 22.000000)">
                <g fill="#7E8DA2">
                  <path
                    d="M12,22.5 C6.201,22.5 1.5,17.7975 1.5,12 C1.5,6.2025 6.201,1.5 12,1.5 C17.799,1.5 22.5,6.2025 22.5,12 C22.5,17.7975 17.799,22.5 12,22.5 L12,22.5 Z M12,0 C5.37225,0 0,5.37 0,12 C0,18.63 5.37225,24 12,24 C18.62775,24 24,18.63 24,12 C24,5.37 18.62775,0 12,0 Z"
                    transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                  />
                </g>
                <circle stroke="#7E8DA2" strokeWidth="1.5" cx="12" cy="9.75" r="3.75" />
                <path
                  d="M19.177132,20.677132 C19.177132,16.7133115 15.9638206,13.5 12,13.5 C8.03617943,13.5 4.82286796,16.7133115 4.82286796,20.677132"
                  stroke="#7E8DA2"
                  strokeWidth="1.5"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default ContactIcon
