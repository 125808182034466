import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import Spinner from '@components/Spinner'

class QuickLogin extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  componentDidMount() {
    const { search } = this.props.location
    const { token, location } = queryString.parse(search)
    this.quickLogin(token, location)
  }

  quickLogin = async (token, location) => {
    if (token) {
      await this.props.userStore.getUser(token)
      this.props.history.push(location)
    }
  }

  render() {
    if (this.props.userStore.isQuickLoginInProgress) {
      return (
        <div>
          <Spinner />
        </div>
      )
    } else {
      return null
    }
  }
}

export default withRouter(inject('userStore')(observer(QuickLogin)))
