"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update = exports.create = exports.getAll = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/compliance';
function getAll() {
    return api_1.default.get(base).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAll = getAll;
function create(formData) {
    return api_1.default.post(base, formData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(complianceId, formData) {
    return api_1.default.put("".concat(base, "/").concat(complianceId), formData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
var api = {
    getAll: getAll,
    create: create,
    update: update,
};
exports.default = api;
