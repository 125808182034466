import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { Field } from 'formik'
import classNames from 'classnames'
import get from 'lodash/get'

import { getPlaceSuggestions, geocodeByPlaceId, getTimezone } from '@api/autocomplete'
import Input from './Input'
import Autocomplete from './Autocomplete'
import Typeahead from './Typeahaed'

class AddressField extends Component {
  static propTypes = {
    staticData: PropTypes.object,
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    addressLabel: PropTypes.string,
    cityLabel: PropTypes.string,
    stateLabel: PropTypes.string,
    postalCode: PropTypes.bool,
    countryLabel: PropTypes.string,
    staticDataStore: PropTypes.object,
  }

  static defaultProps = {
    addressLabel: 'Address',
    cityLabel: 'City',
    stateLabel: 'State / province',
    countryLabel: 'Country',
    editAddress: false,
    editCity: true,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      addressSuggestions: [],
      citySuggestions: [],
    })
    this.addressAutocompleteOptions = {
      types: ['address'],
    }
    this.cityAutocompleteOptions = {
      types: ['(cities)'],
    }
  }

  extractPlaceInfo(googlePlace) {
    const { staticData } = this.props.staticDataStore

    const address = googlePlace.address_components
      .filter((c) => {
        return c.types.includes('route') || c.types.includes('street_number')
      })
      .map((c) => c.long_name)
      .reverse()
      .join(' ')
    const countryComponent = googlePlace.address_components.find((c) => c.types.includes('country'))
    const countryIsoCode = countryComponent && countryComponent.short_name
    let city = googlePlace.address_components.find((c) => c.types.includes('locality'))
    if (!city) {
      city = googlePlace.address_components.find(
        (c) =>
          c.types.includes('postal_town') ||
          c.types.includes('administrative_area_level_3') ||
          c.types.includes('sublocality_level_1'),
      )
    }
    const state = googlePlace.address_components.find((c) => c.types.includes('administrative_area_level_1'))
    const postalCodeComponent = googlePlace.address_components.find((c) => c.types.includes('postal_code'))
    const country = staticData.countries.find((c) => c.isoCode2 === countryIsoCode)
    const countryId = country && country.id
    const timezoneId = country && country.timezoneId
    const cityName = city && city.long_name
    const stateName = state && state.long_name
    const postalCode = postalCodeComponent && postalCodeComponent.long_name

    const latitude = googlePlace.geometry.location.lat
    const longitude = googlePlace.geometry.location.lng

    return {
      address: address || '',
      city: cityName || '',
      state: stateName || '',
      countryId: countryId || null,
      countryDefaultTimezoneId: timezoneId || null,
      postalCode: postalCode || '',
      location: {
        latitude,
        longitude,
      },
    }
  }

  clearAddressData = () => {
    const { form, name } = this.props

    form.setFieldValue(`${name}.state`, '', false)
    form.setFieldValue(`${name}.countryId`, null, false)
    form.setFieldValue(`${name}.timezoneId`, null, false)
    form.setFieldValue(`${name}.postalCode`, '', false)
    form.setFieldValue(`${name}.city`, '', false)
    form.setFieldTouched(`${name}.address1`, true)
    form.setFieldTouched(`${name}.city`, true)
  }

  geocodePlaceById = (placeId, type) => {
    const { form, name } = this.props
    const { staticData } = this.props.staticDataStore
    geocodeByPlaceId(placeId).then(async (res) => {
      if (res.error) {
        console.error(res.error)
        return ''
      } else {
        if (res.data.length === 0) return

        const place = res.data[0]
        const { address, city, state, postalCode, countryId, countryDefaultTimezoneId, location } =
          this.extractPlaceInfo(place)

        const timezoneInfo = await getTimezone(location)
        const timezoneName = timezoneInfo.timeZoneId
        const timezone = staticData.timezones.find((t) => t.name.indexOf(timezoneName) !== -1)
        const locationTimezoneId = timezone && timezone.id

        const timezoneId = locationTimezoneId || countryDefaultTimezoneId

        form.setFieldValue(`${name}.city`, city, false)
        form.setFieldValue(`${name}.state`, state, false)
        form.setFieldValue(`${name}.postalCode`, postalCode, false)
        form.setFieldValue(`${name}.countryId`, countryId, false)
        form.setFieldValue(`${name}.timezoneId`, timezoneId, false)

        form.setFieldTouched(`${name}.city`, true)
        if (type === 'address') {
          form.setFieldValue(`${name}.address1`, address, false)
          form.setFieldTouched(`${name}.address1`, true)
          this.addressSuggestions.replace([])
        } else {
          this.citySuggestions.replace([])
        }
      }
    })
  }

  onSelect = (selectedCity) => {
    const { form, name } = this.props
    const suggestion = this.citySuggestions.find((item) => item.description === selectedCity)

    if (!suggestion) {
      form.setFieldValue(`${name}.city`, '', false)
      this.clearAddressData()
      return
    }

    const placeId = suggestion.place_id
    this.geocodePlaceById(placeId, '(cities)')
  }

  onAddressChange = (address) => {
    const { form, name } = this.props
    this.clearAddressData()
    form.setFieldValue(`${name}.address1`, address, false)
  }

  handleAutocomplete = (input, type) => {
    if (!input) return Promise.resolve([])
    return getPlaceSuggestions(input, type).then((res) => {
      if (res.error) {
        console.error(res.error)
        return []
      } else {
        if (type === 'address') {
          this.addressSuggestions.replace(res.data)
        } else {
          this.citySuggestions.replace(res.data)
        }
        return res.data.map((place) => place && place.description)
      }
    })
  }

  onAddressChange = (address) => {
    const { form, name } = this.props
    this.clearAddressData()
    form.setFieldValue(`${name}.address1`, address, false)
  }

  onAddressSelect = (selectedAddress) => {
    const { form, name } = this.props
    if (!selectedAddress) {
      form.setFieldValue(`${name}.address1`, '', false)
      this.clearAddressData()
      return
    }

    const suggestion = this.addressSuggestions.find((item) => item.description === selectedAddress)

    if (!suggestion) {
      form.setFieldValue(`${name}.address1`, selectedAddress, false)
      return
    }

    const placeId = suggestion.place_id
    this.geocodePlaceById(placeId, 'address')
  }

  renderCityAddressInput = () => {
    const { name, disabled, addressLabel } = this.props
    return (
      <div className="m-b-lg">
        <Field
          name={`${name}.address1`}
          label={addressLabel}
          onChange={this.onAddressSelect}
          onInputValueChange={this.onAddressChange}
          loadOptions={(input) => this.handleAutocomplete(input, 'address')}
          disabled={disabled}
          component={Typeahead}
        />
      </div>
    )
  }

  renderSecondaryAddressInput = () => {
    const { name, disabled } = this.props
    return <Field name={`${name}.address2`} label="Secondary address" disabled={disabled} component={Input} />
  }

  renderCityInput = () => {
    const { form, name, disabled, cityLabel, postalCode } = this.props
    const addressField = get(form.values, name)
    return (
      <div className="form__input-group">
        <label className="form__label">{cityLabel}</label>
        <div
          className={!postalCode ? 'form__input-wrapper' : 'form__input-wrapper form__input-wrapper--two_one_children'}
        >
          <Field
            name={`${name}.city`}
            onChange={this.onSelect}
            loadOptions={(input) => this.handleAutocomplete(input, '(cities)')}
            disabled={disabled}
            component={Autocomplete}
          />
          {postalCode && this.renderPostalCodeInput(addressField.postalCode)}
        </div>
      </div>
    )
  }

  renderEmptyContent() {
    return <span className="form__placeholder-text">-</span>
  }

  isAddressSelected = () => {
    const { form, name } = this.props
    const addressField = get(form.values, name)
    return addressField.countryId !== null && addressField.timezoneId !== null
  }

  renderPostalCodeInput = () => {
    const { name, disabled } = this.props
    return <Field name={`${name}.postalCode`} component={Input} disabled={disabled} />
  }

  renderStateField = (state) => {
    const { stateLabel } = this.props
    const value = state || this.renderEmptyContent()

    return this.renderUneditableComponent({
      label: stateLabel,
      value,
    })
  }

  renderCountryField = (countryId) => {
    const { countryLabel } = this.props
    const { staticData } = this.props.staticDataStore
    const value = countryId ? staticData.names.countries[countryId] : this.renderEmptyContent()

    return this.renderUneditableComponent({
      label: countryLabel,
      value,
    })
  }

  renderTimezoneField = (timezoneId) => {
    const { staticData } = this.props.staticDataStore
    const value = timezoneId ? staticData.names.timezones[timezoneId] : this.renderEmptyContent()
    return this.renderUneditableComponent({ label: 'Timezone', value })
  }

  renderUneditableComponent = ({ label, value = '-' }) => {
    return (
      <div key={`${label}-${value}`} className="form__input-group">
        <label className="form__label">{label}</label>
        <div className="form__input-wrapper">
          <label className="form__label form__label--black">{value}</label>
          <div className="form__input-error" />
        </div>
      </div>
    )
  }

  render() {
    const { form, name } = this.props
    const addressField = get(form.values, name)
    const wrapperClasses = classNames({
      'form__input-group': true,
      'form__input-group--no-flex': true,
    })

    return (
      <div className={wrapperClasses}>
        {this.renderCityAddressInput()}
        {this.renderSecondaryAddressInput()}
        {this.renderCityInput()}
        {this.renderStateField(addressField.state)}
        {this.renderCountryField(addressField.countryId)}
        {this.renderTimezoneField(addressField.timezoneId)}
      </div>
    )
  }
}

export default inject('staticDataStore')(observer(AddressField))
