import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import range from 'lodash/range'

import { getPasswordStrength } from '@api/user'

class PasswordStrength extends Component {
  static propTypes = {
    password: PropTypes.string,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      strength: null,
    })
  }

  bar = (index, color) => {
    return <td key={index} className={`password-strength-meter__bar password-strength-meter__bar--${color}`} />
  }

  getColor = (strength) => {
    switch (strength) {
      case 0:
        return 'red'
      case 1:
        return 'red'
      case 2:
        return 'yellow'
      case 3:
        return 'yellow'
      case 4:
        return 'green'
      default:
        return 'gray'
    }
  }

  getStrength = (password) => {
    if (!password || password === '') {
      Promise.resolve().then(() => {
        this.strength = null
      })
    } else {
      getPasswordStrength(password).then(({ score }) => {
        this.strength = score
      })
    }
  }

  render() {
    const { password } = this.props
    this.getStrength(password)

    return (
      <table className="password-strength-meter m-t-md">
        <tbody>
          <tr>
            {range(5).map((index) => {
              const color = this.getColor(this.strength)
              if (index <= this.strength) {
                return this.bar(index, color)
              } else {
                return this.bar(index, 'gray')
              }
            })}
          </tr>
        </tbody>
      </table>
    )
  }
}

export default observer(PasswordStrength)
