import React from 'react'
import * as helpers from './Helpers'
import Item from './Item'
import PropTypes from 'prop-types'

const Results = ({
  isOpen,
  items,
  itemToString,
  renderItem,
  getItemProps,
  highlightedIndex,
  selectedItem,
  loading = false,
  creatable = false,
  itemFromCreatable,
  inputValue,
  showResultsOnEmptyInput,
}) => {
  if (!isOpen) return null

  if (!inputValue && !showResultsOnEmptyInput) {
    return <div>Type to search</div>
  }

  if (loading && items.length === 0 && !creatable) {
    return <div>Loading...</div>
  }

  if (items.length === 0 && !creatable && !showResultsOnEmptyInput) {
    return <div>No results...</div>
  }

  const creatableItem =
    creatable &&
    createCreatableItem({
      items,
      itemFromCreatable,
      inputValue,
      itemToString,
      selectedItem,
    })
  const resultItems = creatableItem ? [creatableItem, ...items] : items

  return (
    <div className="autocomplete__results-container">
      {resultItems.map((item, index) => {
        const isActive = highlightedIndex === index
        const isSelected = helpers.isSelected({
          selectedItem,
          item,
          itemToString,
        })

        return (
          <Item
            {...getItemProps({
              item,
              disabled: isSelected || item.disabled,
              key: helpers.getKey(itemToString, item),
              isActive,
              isSelected: isSelected,
            })}
          >
            {helpers.renderItem({ renderItem, itemToString, item })}
          </Item>
        )
      })}
    </div>
  )
}

Results.propTypes = {
  creatable: PropTypes.bool,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  itemFromCreatable: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  itemToString: PropTypes.func,
  loading: PropTypes.bool,
  renderItem: PropTypes.func,
  selectedItem: PropTypes.any,
  showResultsOnEmptyInput: PropTypes.bool,
}

Results.defaultProps = {
  itemFromCreatable: (inputValue) => inputValue,
  highlightedIndex: null,
}

function createCreatableItem({ items, itemFromCreatable = (item) => item, inputValue, itemToString, selectedItem }) {
  const existingItemAlreadySelected = selectedItem && itemToString(selectedItem) === inputValue
  if (existingItemAlreadySelected) return null

  const doesCreatableOptionExistAmongItems = items.map((item) => itemToString(item)).includes(inputValue)
  if (doesCreatableOptionExistAmongItems) return null

  return itemFromCreatable(inputValue)
}

export default Results
