import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { ChatRobot, ChatRoom, Chat } from '@atheneum/messaging'
import InfoWrapperRow from '@components/InfoWrapper'
import Pagination from '@components/Pagination'
import { NoContent } from '@components/NoContent'
import { formatDate } from '@lib/helperFunctions'
import { getExternLink } from '@api/client'
import AtheneumContacts from '@components/AtheneumContacts'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { InputLabel } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'

const getProjectLink = (projectId) => {
  getExternLink(projectId).then((res) => {
    const link = res.externLink.replace('/available', '/details')
    window.open(link)
  })
}

class ActiveProjects extends React.Component {
  static propTypes = {
    userStore: PropTypes.object,
    activeProjectsStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    atheneumContactStore: PropTypes.object,
  }

  constructor(props) {
    super(props)

    const client = this.props.userStore.user.get('client')
    this.props.activeProjectsStore.loadActiveProjects(client)
  }

  render() {
    const {
      projects,
      page,
      perPage,
      items,
      initialized,
      sortBy,
      statusFilter,
      sortProjects,
      filterProjects,
      openAreYouSure,
      project,
      confirmModalOpen,
      handleDelete,
      handleCancel,
    } = this.props.activeProjectsStore

    const { staticData } = this.props.staticDataStore
    const { userStore } = this.props
    const { atheneumContact } = this.props.atheneumContactStore
    const clientOfficeId = userStore.user.get('client').officeId
    const user = userStore.user.toJS()

    const filters = ['All', 'Ongoing', 'Pending', 'Draft', 'Completed']
    const sorting = ['Date', 'Contact', 'Interviewed']
    const noContentText = statusFilter === 'All' ? '' : statusFilter.toLowerCase()
    const isClientAuthEnabled =
      process.env.REACT_APP_FEATURE_SETTINGS === 'true' &&
      items.filter((project) => {
        const projectCascadedSettings = project.cascadedSettings && JSON.parse(project.cascadedSettings)
        return projectCascadedSettings?.clientAuth
      }).length

    return (
      <div>
        <div className="container filter-tabs">
          <div className="sections-one">
            <div className="sort-filter-tabs">
              <FormControl>
                <InputLabel className="label">Sort by</InputLabel>
                <Select value={sortBy} onChange={(e) => sortProjects(e.target.value)}>
                  {sorting.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel className="label">See</InputLabel>
                <Select value={statusFilter} onChange={(e) => filterProjects(e.target.value)}>
                  {filters.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="sections-two"></div>
        </div>
        <div className="container">
          <div className="sections-one">
            {items.length === 0 && initialized ? (
              <NoContent item={noContentText + ' projects'} />
            ) : (
              items.map((project) => {
                const { status, className } = getProjectStatus(project)
                const projectCascadedSettings = project.cascadedSettings && JSON.parse(project.cascadedSettings)
                const clientAuthEnabled = isClientAuthEnabled && projectCascadedSettings?.clientAuth
                return (
                  <div key={project.projectId} className="sections-one__section">
                    {project.id && (
                      <div className="project-actions">
                        <NavLink className={'edit-link'} exact to={'/project/' + project.id}>
                          <div className="sections-one__link-container">
                            <div className="sections-one__link name">
                              {project.projectName}
                              <div className="icons">
                                <div className={`status-indicator ${className}`}>{status}</div>
                                <IconButton aria-label="expand row" size="small">
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div className="sub-header__account">
                              {project.officeName || project.office?.officeName || ''}
                            </div>
                          </div>
                        </NavLink>
                        <DeleteIcon className="delete-icon" onClick={() => openAreYouSure(project)} />
                      </div>
                    )}
                    {project.projectId && (
                      <div className={`sections-one__link-container`}>
                        <div className="icons">
                          <div className={`status-indicator ${className}`}>{status}</div>
                          {!!clientAuthEnabled && !project.capiDetails && (
                            <ChatRoom
                              project={{ id: project.projectId, ...project }}
                              user={user}
                              clientOfficeId={clientOfficeId}
                              context="client"
                            />
                          )}
                        </div>
                        <div className="sections-one__link" onClick={() => getProjectLink(project.projectId)}>
                          {project.projectName}
                        </div>
                        <div className="sub-header__account">
                          {project.officeName || project.office?.officeName || ''}
                        </div>
                      </div>
                    )}
                    <InfoWrapperRow noSpace label="Contacts" text={project.clientContact} />
                    <InfoWrapperRow
                      noSpace
                      label="Start/end date"
                      text={`${formatDate(project.startDate)} / ${formatDate(project.endDate)}`}
                    />
                    <InfoWrapperRow
                      noSpace
                      label="Delivered/Interviewed"
                      text={`${project.profilesSubmitted || 0} / ${project.profilesInterviewed || 0}`}
                    />
                  </div>
                )
              })
            )}
            <Pagination
              total={projects.length}
              perPage={perPage}
              page={page}
              onChange={this.props.activeProjectsStore.changePage}
            />
          </div>
          <div className="sections-two">
            <div className="sections-one__section info-wrapper__row m-b-lg">
              <NavLink exact to="/project">
                <li className="sections-two__link">Create a new project</li>
              </NavLink>
              <div>
                If you have any questions please reach us at{' '}
                <a className="atheneum-contacts__link" href="mailto: request@atheneum-partners.com">
                  request@atheneum-partners.com
                </a>
              </div>
            </div>
            {atheneumContact && atheneumContact.status ? (
              <div>
                <AtheneumContacts contact={atheneumContact} staticData={staticData} />
                <hr />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {confirmModalOpen && (
          <div className="delete-modal">
            <div className="delete-wraper">
              <p>
                Are you sure you want to delete <span className="project-name">{project.projectName}</span>?
              </p>
              <div className="buttons">
                <Button size="large" color="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button size="large" variant="contained" color="primary" onClick={handleDelete}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
        {isClientAuthEnabled && (
          <div>
            <Chat user={user} />
            <ChatRobot user={user} clientOfficeId={clientOfficeId} context="client" />
          </div>
        )}
      </div>
    )
  }
}

const getProjectStatus = (project) => {
  const status = project.id ? project.status : project.projectStatus
  if (status === 'Open') {
    return { status: 'Ongoing', className: 'open' }
  }
  if (status === 'Closed') {
    return { status: 'Completed', className: 'complete' }
  }
  if (status === 'Pending' || status === 'pending') {
    return { status: 'Pending', className: 'pending' }
  }
  return { status: 'Draft', className: 'draft' }
}

ActiveProjects.propTypes = {
  activeProjectsStore: PropTypes.object,
  staticDataStore: PropTypes.object,
  atheneumContactStore: PropTypes.object,
}

export default inject(
  'activeProjectsStore',
  'staticDataStore',
  'atheneumContactStore',
  'userStore',
)(observer(ActiveProjects))
