import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Action = (props) => {
  const { onClick, right, children, disabled } = props
  const classes = classNames({
    action: true,
    'action--right': right,
    'action--disabled': disabled,
  })

  return (
    <div className={classes} onClick={disabled ? null : onClick}>
      {children}
    </div>
  )
}

Action.propTypes = {
  right: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
}

export default Action
