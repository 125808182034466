import React from 'react'

class Warning extends React.Component {
  render() {
    return (
      <svg width="20px" height="20px" viewBox="0 0 20 20">
        <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-623.000000, -355.000000)" id="warning">
            <g transform="translate(621.000000, 353.000000)">
              <polygon id="Bounds" points="0 0 24 0 24 24 0 24" />
              <path
                d="M11,15 L13,15 L13,17 L11,17 L11,15 Z M11,7 L13,7 L13,13 L11,13 L11,7 Z M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 L11.99,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 L12,20 Z"
                id="Icon"
                className="icon__fill"
                fill="#FF4E4E"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Warning
