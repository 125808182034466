import React, { Component } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import './Icon.sass'

class Icon extends Component {
  render() {
    const {
      block,
      img,
      large,
      extraLarge,
      right,
      blue,
      inside,
      noClick,
      rotate180,
      gold,
      rotate90,
      rotate45,
      size125,
      className,
      center,
      disabled,
      gray,
      ...restProps
    } = this.props
    const classes = classNames({
      [className]: className,
      icon: true,
      'icon--block': block,
      'icon--img': img,
      'icon--large': large,
      'icon--extra-large': extraLarge,
      'icon--right': right,
      'icon--blue': blue,
      'icon--gold': gold,
      'icon--inside': inside,
      'icon--centered': center,
      'icon--no-click': noClick,
      'icon--disabled': disabled,
      'icon--rotate-180': rotate180,
      'icon--rotate-90': rotate90,
      'icon--rotate-45': rotate45,
      'icon--size-125': size125,
      'icon--size-125-rotate-45': size125 && rotate45,
    })

    return (
      <div className={classes} {...restProps}>
        {this.props.children}
      </div>
    )
  }
}

Icon.propTypes = {
  active: propTypes.bool,
  block: propTypes.bool,
  img: propTypes.bool,
  right: propTypes.bool,
  blue: propTypes.bool,
  inside: propTypes.bool,
  rotate180: propTypes.bool,
  large: propTypes.bool,
  extraLarge: propTypes.bool,
  gold: propTypes.bool,
  gray: propTypes.bool,
  rotate90: propTypes.bool,
  rotate45: propTypes.bool,
  size125: propTypes.bool,
  className: propTypes.string,
  center: propTypes.bool,
  noClick: propTypes.bool,
  disabled: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  onClick: propTypes.func,
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.string,
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
}

export default Icon
