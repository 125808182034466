import React from 'react'
import { observer } from 'mobx-react'

import Logo from './logo.js'
import TreeIcon from '@components/Icons/TreeIcon'

const SidePanel = () => {
  return (
    <div className="auth-side-panel">
      <Logo />
      <TreeIcon />
    </div>
  )
}

export default observer(SidePanel)
