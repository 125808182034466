import React from 'react'
import Icon from './Icon'

class Info extends React.Component {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" className="icon icon--tooltip icon-info">
          <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
            <g transform="translate(-696.000000, -355.000000)" className="icon__fill" fill="#33343A">
              <g transform="translate(706.000000, 365.000000) rotate(-180.000000) translate(-706.000000, -365.000000) translate(696.000000, 355.000000)">
                <path
                  d="M9,13 L11,13 L11,15 L9,15 L9,13 Z M9,5 L11,5 L11,11 L9,11 L9,5 Z M9.99,0 C4.47,0 0,4.48 0,10 C0,15.52 4.47,20 9.99,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 9.99,0 L9.99,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z"
                  id="Icon"
                />
              </g>
            </g>
          </g>
        </svg>
      </Icon>
    )
  }
}

export default Info
