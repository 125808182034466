import swal from 'sweetalert2'
import PropTypes from 'prop-types'

const showDialog = (props) => {
  const { title, text, type, html, showCancelButton, confirmButtonText, cancelButtonText } = props
  return swal({
    title: title,
    text: text,
    type: type,
    html: html,
    showCancelButton: showCancelButton || false,
    confirmButtonText: confirmButtonText || 'Ok',
    cancelButtonText: cancelButtonText || 'Cancel',
    confirmButtonClass: 'button button--primary',
    cancelButtonClass: 'button button--secondary',
    customClass: 'swal-global',
  })
}

showDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  html: PropTypes.object,
  showCancelButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonClass: PropTypes.string,
  cancelButtonClass: PropTypes.string,
  customClass: PropTypes.string,
}

showDialog.defaultProps = {
  showCancelButton: false,
  confirmButtonText: 'Ok',
  cancelButtonText: 'Cancel',
}

export default showDialog
