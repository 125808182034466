import * as React from 'react'
import { observer } from 'mobx-react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import get from 'lodash/get'

const CheckboxMUI = ({ form, field, label, className, disabled }) => {
  const fieldValue = get(form.values, field.name) || false

  return (
    <div className={className}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            checked={fieldValue}
            onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
          />
        }
      />
    </div>
  )
}

CheckboxMUI.propTypes = {
  form: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default observer(CheckboxMUI)
