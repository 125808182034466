import React from 'react'
import PropTypes from 'prop-types'

const ModalButtons = ({ hideButtons, close, onOk, onCancel, okText, cancelText, hideOkButton, hideCancelButton }) => {
  if (hideButtons) return null

  const onOkCb = onOk || close
  const onCancelCb = onCancel || close

  const okButton = !hideOkButton ? (
    <button className="button button--primary" onClick={onOkCb}>
      {okText || 'OK'}
    </button>
  ) : null

  const cancelButton = !hideCancelButton ? (
    <button className="button button--secondary" onClick={onCancelCb}>
      {cancelText || 'Cancel'}
    </button>
  ) : null

  return (
    <div className="modal__buttons">
      {okButton}
      {cancelButton}
    </div>
  )
}

ModalButtons.propTypes = {
  hideButtons: PropTypes.bool,
  close: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  hideOkButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
}

export default ModalButtons
