"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadFromUrl = void 0;
function downloadFromUrl(_a) {
    var url = _a.url, filename = _a.filename;
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
exports.downloadFromUrl = downloadFromUrl;
