"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalFeeGrossAmount = exports.calculateTotalFeeNetAmount = exports.calculateFeeAmountTaxAmountAndGrossAmount = exports.calculateFeeGrossAmount = exports.getFeeItem = exports.isFee = void 0;
var invoice_1 = require("./invoice");
/**
 * @param {Object} fee can be fee or cost
 * @returns {boolean} returns true if fee, false if cost
 */
function isFee(fee) {
    return !!getFeeItem(fee);
}
exports.isFee = isFee;
/**
 * @param {Object} fee can be fee or cost
 * @returns {Object|null} returns feeItem if fee is passed, otherwise null
 */
function getFeeItem(fee) {
    var feeItem = fee.feeItems.find(function (item) { return item.feeTypeId; });
    return feeItem;
}
exports.getFeeItem = getFeeItem;
/**
 * Calculated gross amount on fee item
 *
 * @param {Object} params
 * @param {Object} params.fee fee object
 * @param {Number} params.vat invoice VAT - tax percentage
 * @param {Object} params.priceInEuros should the prices be in euros or original currency
 * @returns {Number} calculated gross amount
 */
function calculateFeeGrossAmount(_a) {
    var fee = _a.fee, vat = _a.vat, _b = _a.priceInEuros, priceInEuros = _b === void 0 ? false : _b;
    var feeItem = getFeeItem(fee);
    var netAmount = priceInEuros ? feeItem.converted : feeItem.value;
    var taxAmount = (0, invoice_1.calculateTax)(vat, netAmount);
    var grossAmount = netAmount + taxAmount;
    return grossAmount || 0;
}
exports.calculateFeeGrossAmount = calculateFeeGrossAmount;
function calculateFeeAmountTaxAmountAndGrossAmount(_a) {
    var fee = _a.fee, vat = _a.vat, _b = _a.priceInEuros, priceInEuros = _b === void 0 ? false : _b;
    var feeItem = getFeeItem(fee);
    var netAmount = priceInEuros ? feeItem.converted : feeItem.value;
    var taxAmount = (0, invoice_1.calculateTax)(vat, netAmount);
    var grossAmount = netAmount + taxAmount;
    return {
        netAmount: netAmount || 0,
        taxAmount: taxAmount || 0,
        grossAmount: grossAmount || 0,
    };
}
exports.calculateFeeAmountTaxAmountAndGrossAmount = calculateFeeAmountTaxAmountAndGrossAmount;
function calculateTotalFeeNetAmount(_a) {
    var _b = _a.fees, fees = _b === void 0 ? [] : _b, _c = _a.priceInEuros, priceInEuros = _c === void 0 ? false : _c;
    var totalNetAmount = fees
        .map(function (fee) {
        var feeItem = getFeeItem(fee);
        return priceInEuros ? feeItem.converted : feeItem.value;
    })
        .reduce(function (totalAmount, amount) { return totalAmount + amount; }, 0);
    return totalNetAmount || 0;
}
exports.calculateTotalFeeNetAmount = calculateTotalFeeNetAmount;
function calculateTotalFeeGrossAmount(_a) {
    var _b = _a.fees, fees = _b === void 0 ? [] : _b, vat = _a.vat, _c = _a.priceInEuros, priceInEuros = _c === void 0 ? false : _c;
    var totalGrossAmount = fees
        .map(function (fee) { return calculateFeeGrossAmount({ fee: fee, vat: vat, priceInEuros: priceInEuros }); })
        .reduce(function (totalGrossAmount, grossAmount) { return totalGrossAmount + grossAmount; }, 0);
    return totalGrossAmount || 0;
}
exports.calculateTotalFeeGrossAmount = calculateTotalFeeGrossAmount;
