"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = void 0;
function formatNumber(value, digits) {
    if (digits === void 0) { digits = 2; }
    return Number(value).toLocaleString('de-DE', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
}
exports.formatNumber = formatNumber;
