import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { toJS } from 'mobx'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'

import Spinner from '@components/Spinner'
import EssentialsForm from './EssentialsForm'
import ProjectDetailsForm from './ProjectDetailsForm'
import ScopesForm from './ScopesForm'
import ScreeningQuestionsForm from './ScreeningQuestionsForm'
import CreateProjectFormModel from './CreateProjectFormModel'
import validationSchema from './validationSchema'
import { getPositionSuggestions, getCompanySuggestions } from '@api/autocomplete'

const { formId, projectDetailsFields } = CreateProjectFormModel

const steps = ['Essentials', 'Project Details', 'Scopes (Optional)', 'Screening Questions (Optional)']
class CreateProjectForm extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    growlStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    activeProjectsStore: PropTypes.object,
    projectStore: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      isSubmitting: false,
      activeStep: 0,
      checkIndustry: false,
      modalOpen: false,
    })

    const { match } = this.props
    const projectId = match.params.id

    if (projectId) {
      const project = this.props.activeProjectsStore.allProjects.filter((p) => p.id === +projectId)[0]
      this.props.projectStore.init(this.props.userStore.user, project)
    } else {
      this.props.projectStore.init(this.props.userStore.user)
    }
  }

  openCancelModal = () => {
    this.modalOpen = true
  }

  closeCancelModal = () => {
    this.modalOpen = false
  }

  saveProject = async (status, form) => {
    const { projectStore, activeProjectsStore, userStore, growlStore } = this.props
    const { segments, activeSegmentIndex } = projectStore

    if (form && !segments[activeSegmentIndex].industries.length) {
      form.validateForm()
      this.closeCancelModal()
    } else {
      this.isSubmitting = true
      const projectStatus = await projectStore.createProject(status)

      if (projectStatus === 'draft') {
        growlStore.showSuccess('Your project is saved as draft.')
      } else if (projectStatus === 'pending') {
        growlStore.showSuccess('Your project is saved as pending. Our associate will create it for you.')
      } else if (projectStatus === 'open') {
        growlStore.showSuccess('You created a new project.')
      } else {
        growlStore.showError('Something went wrong while saving your project.')
      }

      const client = userStore.user.get('client')
      activeProjectsStore.loadActiveProjects(client)

      this.isSubmitting = false
      this.closeCancelModal()
      if (projectStatus) {
        this.props.history.push('/')
      }
    }
  }

  handleStep = (index) => {
    const { projectStore } = this.props
    const { project, segments, activeSegmentIndex } = projectStore
    if (index > 0 && (!project.projectName || !project.projectDescription)) {
      return
    }
    if (index > 1 && !segments[activeSegmentIndex].industries.length) {
      return
    }
    this.activeStep = index
  }

  handleNext = async (form) => {
    const errors = await form.validateForm()

    if (this.activeStep === 3) {
      this.saveProject('open')
      return
    }

    if (this.activeStep > 1) {
      this.activeStep++
    }

    if (this.activeStep === 1) {
      if (Object.keys(errors).length === 0) {
        this.activeStep++
      }
      this.checkIndustry = true
      form.validateForm()
    }

    if (this.activeStep === 0 && Object.keys(errors).length === 0) {
      this.activeStep++
    }
  }

  handleBack = async () => {
    this.checkIndustry = false
    this.activeStep--
  }

  handleCompanyAutocomplete = (input) => {
    if (!input) return Promise.resolve([])
    return getCompanySuggestions(input)
      .then((companies) => {
        return companies
      })
      .catch((err) => {
        console.error(err)
      })
  }

  handlePositionAutocomplete = (input) => {
    if (!input) return Promise.resolve([])
    const limit = 8
    return getPositionSuggestions(input, limit)
      .then((positions) => {
        return positions
      })
      .catch((err) => {
        console.error(err)
      })
  }

  renderStepContent = (step, form, fields) => {
    switch (step) {
      case 0:
        return <EssentialsForm form={form} />
      case 1:
        return <ProjectDetailsForm form={form} fields={fields} checkIndustry={this.checkIndustry} initialScope={true} />
      case 2:
        return <ScopesForm form={form} fields={fields} />
      case 3:
        return <ScreeningQuestionsForm form={form} fields={fields} />
      default:
        return <div>Not Found</div>
    }
  }

  render() {
    const { languages, countries } = this.props.staticDataStore.staticData
    const { projectStore } = this.props
    const { project, segments, activeSegmentIndex } = projectStore
    const currentValidationSchema = validationSchema[this.activeStep]
    const { modalOpen } = this
    const initialValues = { ...project, ...segments[activeSegmentIndex] }
    initialValues.languages = toJS(initialValues.languages)
    initialValues.geography = toJS(initialValues.geography)
    initialValues.companies = toJS(initialValues.companies)
    initialValues.competitors = toJS(initialValues.competitors)
    initialValues.roles = toJS(initialValues.roles)
    initialValues.seniority = toJS(initialValues.seniority)
    initialValues.industries = toJS(initialValues.industries)
    initialValues.screeningQuestions = toJS(initialValues.screeningQuestions)

    const fields = projectDetailsFields.map((field) => {
      if (field.name === 'languages') {
        field.options = languages
      } else if (field.name === 'geography') {
        field.options = countries
      } else if (field.name === 'companies') {
        field.options = []
        field.loadOptions = this.handleCompanyAutocomplete
      } else if (field.name === 'competitors') {
        field.options = []
        field.loadOptions = this.handleCompanyAutocomplete
      } else if (field.name === 'roles') {
        field.options = []
        field.loadOptions = this.handlePositionAutocomplete
      } else if (field.name === 'seniority') {
        field.options = []
      }
      return field
    })

    if (this.isSubmitting) {
      return (
        <div className="feedback">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="container">
        <div className="stepper-wrapper create-project">
          <Grid container>
            <h2>New Project</h2>
            <Grid container item xs={12}>
              <Stepper nonLinear activeStep={this.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepButton onClick={() => this.handleStep(index)}>{label}</StepButton>
                    </Step>
                  )
                })}
              </Stepper>

              {this.activeStep === steps.length ? (
                <div />
              ) : (
                <React.Fragment>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    isInitialValid={false}
                    validationSchema={currentValidationSchema}
                    validateOnChange={false}
                    onSubmit={this.submitForm}
                    render={(form) => (
                      <div className="project-form" style={{ width: '100%' }}>
                        <Form id={formId} onSubmit={form.handleSubmit}>
                          {this.renderStepContent(this.activeStep, form, fields)}
                        </Form>
                        <div className="form-footer">
                          {!!this.activeStep ? (
                            <Button
                              className="stepper-button"
                              size="large"
                              color="primary"
                              disabled={this.isSubmitting}
                              onClick={this.handleBack}
                            >
                              Back
                            </Button>
                          ) : (
                            <div />
                          )}

                          <div>
                            {this.activeStep !== 0 && (
                              <Button
                                className="stepper-button cancel"
                                size="large"
                                color="primary"
                                disabled={this.isSubmitting}
                                onClick={this.openCancelModal}
                              >
                                Cancel
                              </Button>
                            )}
                            <Button
                              className="stepper-button"
                              size="large"
                              variant="contained"
                              color="primary"
                              disabled={this.isSubmitting}
                              onClick={() => this.handleNext(form)}
                            >
                              {this.activeStep === 3 ? 'Create' : 'Next'}
                            </Button>
                          </div>
                        </div>
                        {modalOpen && (
                          <CancelModal form={form} saveProject={this.saveProject} closeModal={this.closeCancelModal} />
                        )}
                      </div>
                    )}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
function CancelModal(props) {
  const { saveProject, closeModal, form } = props

  return (
    <div className="cancel-modal">
      <div className="cancel-wraper">
        <div className="cancel-title">What do you want to do with your project in progress?</div>
        <div className="link" onClick={() => saveProject('draft')}>
          Exit and save my project as draft
        </div>
        <div className="link" onClick={() => saveProject('pending', form)}>
          Create it for me
        </div>
        <div className="helper-text">
          <p>Let us contact you and create your project for you</p>
          <p>with the available information.</p>
        </div>
        <div className="link">
          <NavLink to="/" activeClassName="active">
            Exit and don't save
          </NavLink>
        </div>
        <Button variant="outlined" color="primary" onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default withRouter(
  inject(
    'staticDataStore',
    'userStore',
    'growlStore',
    'activeProjectsStore',
    'projectStore',
  )(observer(CreateProjectForm)),
)
