import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './reducers/chatSlice'
import { apiSlice } from './reducers/apiSlice'

export default configureStore({
  reducer: {
    chat: chatReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat(apiSlice.middleware),
})
