"use strict";
// subindustry/industry options for the formik project form
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubIndustryOptions = void 0;
function getSubIndustryOptions(staticData) {
    return staticData.industries.reduce(function (industryOpts, industry) {
        var subindustries = staticData.subIndustries.filter(function (si) { return si.industryId === industry.id; });
        var industryObject = {
            id: "".concat(industry.id, "-industry"),
            type: 'industry',
            disabled: true,
            name: industry.name,
            className: 'select-parent',
            children: subindustries.map(function (si) { return si.name; }).join(' '),
        };
        var subindustryOptions = subindustries.map(function (si) {
            return {
                id: si.id,
                type: 'subIndustry',
                name: si.name,
                industryId: si.industryId,
                disabled: !si.selectable,
                className: 'select-child',
            };
        });
        return industryOpts.concat(industryObject).concat(subindustryOptions);
    }, []);
}
exports.getSubIndustryOptions = getSubIndustryOptions;
