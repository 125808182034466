import React from 'react'
import PropTypes from 'prop-types'

const Error = ({ error }) => {
  const errorTitle = error.statusCode === 403 ? error.error : 'Unknown error occured!'
  return (
    <div className="no-content">
      <div className="no-content__title">{errorTitle}</div>
      <div className="no-content__text">{error.message}</div>
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.object,
}

export default Error
