import React, { Component } from 'react'
import propTypes from 'prop-types'
import { observer } from 'mobx-react'
import InfoIcon from '../Icons/Info'
import classNames from 'classnames'

class InfoBox extends Component {
  renderIcon = ({ icon }) => {
    if (!icon) return null

    return <div className="info-box-wrapper__icon">{this.props.icon}</div>
  }
  render() {
    const className = this.props.className
    const classes = classNames({
      'info-box-wrapper': true,
      ['info-box-wrapper--' + this.props.theme]: true,
      [className]: className,
    })

    return (
      <div className={classes}>
        {this.renderIcon(this.props)}
        <div className="info-box-wrapper__rows">{this.props.children}</div>
      </div>
    )
  }
}

InfoBox.propTypes = {
  theme: propTypes.oneOf(['blue', 'red', 'yellow']),
  icon: propTypes.element,
  className: propTypes.string,
  children: propTypes.oneOfType([propTypes.node, propTypes.element, propTypes.string]),
}

InfoBox.defaultProps = {
  theme: 'blue',
  icon: <InfoIcon />,
}

export default observer(InfoBox)
