import { Results, Helpers } from './Base'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import Downshift from 'downshift'
import ArrowDown from '../Icons/ArrowDown'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { propTypes as mobxPropTypes } from 'mobx-react'

const DEBOUNCE_DELAY_IN_MS = 100

class Autocomplete extends Component {
  static propTypes = {
    // standard input values
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any, // value for the component, can be object, string, ...

    // autocomplete related options
    options: PropTypes.oneOfType([PropTypes.array, mobxPropTypes.observableArray]), // set if component isn't async
    loadOptions: PropTypes.func, // set if component is async, do not use with options prop
    onChange: PropTypes.func, // called when an item is selected
    itemToString: PropTypes.func, // how to represent the item as a string
    renderItem: PropTypes.func,
    creatable: PropTypes.bool, // can a new item be created
    itemFromCreatable: PropTypes.func, // how to create an item from an input value
    filterItemsOnly: PropTypes.bool, // if set to true it'll only filter out non-matching options, otherwise it'll sort them too
    valueIsId: PropTypes.bool, // when selected item contains only id
    showResultsOnEmptyInput: PropTypes.bool, // show result items even when input value is empty
    inputContainsLink: PropTypes.bool, // A link will be to the right of the input
  }

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      loading: false,
    }

    this.setupData()
  }

  setupData = debounce((inputValue) => {
    const { options, loadOptions, itemToString, filterItemsOnly } = this.props
    if (options) {
      const orderedItems = Helpers.reorderItems({
        items: options,
        itemToString,
        inputValue,
        filterOnly: filterItemsOnly,
      })

      this.setState({ items: orderedItems })
      return
    }

    this.setState({ loading: true })

    return loadOptions(inputValue)
      .then((items) => {
        this.setState({
          items,
          loading: false,
        })
      })
      .catch((error) => {
        console.error(error) // ignore errors here
        this.setState({
          items: [],
          loading: false,
        })
      })
  }, DEBOUNCE_DELAY_IN_MS)

  onStateChange = (changes, stateAndHelpers) => {
    // make sure to clear the input if the model changed directly
    if (changes.hasOwnProperty('inputValue') && changes.inputValue === null) {
      stateAndHelpers.clearSelection()
    }
  }

  createInputHandler = ({ clearSelection }) => {
    return (event) => {
      const inputValue = event.target.value

      const shouldClearInput = !inputValue && !this.props.showResultsOnEmptyInput
      if (shouldClearInput) {
        this.setState({
          items: [],
        })
        clearSelection()
        return
      }
      this.setupData(inputValue)
    }
  }

  getSelectedOption = () => {
    const { value, options } = this.props
    if (value === undefined) return null
    return options.find((o) => o.id === value)
  }

  render() {
    const {
      creatable,
      disabled,
      itemFromCreatable,
      itemToString,
      renderItem,
      onBlur,
      className,
      placeholder,
      value,
      valueIsId,
      showResultsOnEmptyInput,
      inputContainsLink,
    } = this.props

    // workaround when selected item is contains only id
    const selectedItem = valueIsId ? this.getSelectedOption() : value

    return (
      <Downshift
        defaultHighlightedIndex={0}
        selectedItem={selectedItem}
        onSelect={this.props.onChange}
        onStateChange={this.onStateChange}
        itemToString={itemToString}
        render={({
          selectedItem,
          getInputProps,
          getItemProps,
          highlightedIndex,
          getButtonProps,
          isOpen,
          openMenu,
          inputValue,
          clearSelection,
        }) => {
          const arrowIconClassName = classNames({
            'autocomplete__arrow-icon': true,
            'icon-animate': true,
            'icon-rotate': isOpen,
          })

          const autocompleteContainerClasses = classNames({
            autocomplete__container: true,
            'autocomplete__container--disabled': disabled,
            [className]: className,
          })

          const autocompleteInputClasses = classNames({
            autocomplete__input: true,
            'autocomplete__input--has-link': inputContainsLink,
            'autocomplete__input--disabled': disabled,
          })

          return (
            <div className={autocompleteContainerClasses}>
              <input
                {...getInputProps({
                  disabled,
                  onBlur,
                  placeholder,
                  onFocus: openMenu,
                  className: autocompleteInputClasses,
                  value: inputValue !== null ? inputValue : '',
                  onChange: this.createInputHandler({ clearSelection }),
                })}
                data-cy={this.props['data-cy']}
              />
              {!!showResultsOnEmptyInput && (
                <ArrowDown noClick={disabled} className={arrowIconClassName} {...getButtonProps({ disabled })} />
              )}

              <Results
                showResultsOnEmptyInput={showResultsOnEmptyInput}
                inputValue={inputValue}
                creatable={creatable}
                itemFromCreatable={itemFromCreatable}
                itemToString={itemToString}
                renderItem={renderItem}
                loading={this.state.loading}
                isOpen={isOpen}
                items={this.state.items}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default Autocomplete
