import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import ProjectDetailsForm from './ProjectDetailsForm'

class ScopesForm extends Component {
  static propTypes = {
    projectStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      modalOpen: false,
      isAddingScope: false,
      isFirstScope: false,
      checkIndustry: false,
      isSubmitting: false,
      isNameTaken: false,
      initialProject: {},
      initialSegment: {
        industries: [],
        companies: [],
        competitors: [],
        geography: [],
        roles: [],
        screeningQuestions: [],
        seniority: [],
        languages: [],
        requestedNumberOfCalls: null,
      },
    })
  }

  openModal = () => {
    const { projectStore } = this.props
    const { project, segments, activeSegmentIndex } = projectStore
    const initialSegment = {}
    for (const [key, value] of Object.entries(segments[activeSegmentIndex])) {
      initialSegment[key] = value
    }
    this.initialSegment = initialSegment

    const initialProject = {}
    for (const [key, value] of Object.entries(project)) {
      initialProject[key] = value
    }
    this.initialProject = initialProject
    this.modalOpen = true
  }

  handleFormValues = (segment) => {
    const { form } = this.props

    for (const [key, value] of Object.entries(segment)) {
      if (key === 'requestedNumberOfCalls') {
        form.setFieldValue(key, value)
      } else {
        form.setFieldValue(key, value ? value.slice() : [])
      }
    }
  }

  addScope = () => {
    const { projectStore } = this.props
    const { createSegment } = projectStore

    const segment = createSegment()
    this.handleFormValues(segment)

    this.isAddingScope = true
    this.isFirstScope = false
    this.openModal()
  }

  editScope = (index) => {
    this.isAddingScope = false
    this.isFirstScope = !index
    const { projectStore } = this.props
    const { setActiveSegment, segments } = projectStore
    setActiveSegment(index)
    this.handleFormValues(segments[index])
    this.openModal()
  }

  handleCancelScope = () => {
    const { projectStore } = this.props
    const { cancelAddSegment, cancelSegmentChanges, cancelProjectChanges, segments } = projectStore

    if (this.isAddingScope) {
      cancelAddSegment()
      this.isAddingScope = false
    } else {
      cancelSegmentChanges(this.initialSegment)
      cancelProjectChanges(this.initialProject)
    }

    this.handleFormValues(segments[0])

    this.checkIndustry = false
    this.modalOpen = false
  }

  handleCreateScope = () => {
    const { projectStore, form } = this.props
    const { setActiveSegment, project, segments, activeSegmentIndex } = projectStore

    form.validateForm()
    this.checkIndustry = true
    this.isNameTaken =
      segments.filter((segment) => segment.segmentName === segments[activeSegmentIndex].segmentName).length > 1

    if (!this.isNameTaken && form.values.industries.length && (project.industryId || project.industryName)) {
      setActiveSegment()
      this.handleFormValues(segments[0])

      this.modalOpen = false
    }
  }

  render() {
    const { form, fields, projectStore } = this.props
    const { segments, deleteSegment } = projectStore

    return (
      <React.Fragment>
        <Typography variant="h6" component={'div'}>
          <div className="form-title">The Scopes</div>
          <div className="form-subtitle">Your project is ready. Additionaly you can create scopes.</div>
          <Grid container item xs={12} spacing={4} className="scopes">
            {segments.map((segment, index) => (
              <Grid item className="scope" key={index}>
                <div className="field-list">
                  <div className="field-name scope-name">
                    <div className="name">{segment.segmentName}</div>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.editScope(index)}>
                      <EditIcon />
                    </IconButton>
                    {index > 0 && (
                      <IconButton aria-label="expand row" size="small" onClick={() => deleteSegment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                  {fields
                    .filter((field) => field.name !== 'requestedNumberOfCalls' && segment[field.name].length)
                    .map((field, index) => (
                      <ScopeRow key={index} value={segment[field.name]} field={field} />
                    ))}
                </div>
                <div className="scope-footer">
                  <div className="field-name">
                    <div>Target Size</div>
                    <div className="expert-count">
                      {segment.requestedNumberOfCalls || '0'} Expert{segment.requestedNumberOfCalls !== 1 && 's'}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
            <Grid item className="scope add-scope">
              <Button size="large" variant="outlined" color="primary" onClick={this.addScope}>
                <AddCircleOutlineIcon />
                &nbsp;Create Scope
              </Button>
            </Grid>
          </Grid>
        </Typography>
        {this.modalOpen && (
          <div className="scope-modal">
            <ProjectDetailsForm
              className="add-scope"
              form={form}
              fields={fields}
              handleCancel={this.handleCancelScope}
              handleCreate={this.handleCreateScope}
              checkIndustry={this.checkIndustry}
              initialScope={this.isFirstScope}
              isNameTaken={this.isNameTaken}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

function ScopeRow(props) {
  const { value, field } = props
  const [open, setOpen] = React.useState(field.name === 'industries')

  return (
    <div className="scope-field">
      <div className="field-name">
        <div>{field.label}</div>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit className="field-value">
        {field.name !== 'requestedNumberOfCalls' && value ? value.join(', ') : ''}
      </Collapse>
    </div>
  )
}

export default withRouter(inject('projectStore')(observer(ScopesForm)))
