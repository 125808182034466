"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountTypeahead = exports.getAccountsByParentAccount = exports.getFeedbacks = exports.getProjectCount = exports.getContracts = exports.getInvoicingEntities = exports.deleteAccount = exports.getAccount = exports.update = exports.create = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/account';
function create(accountFormData) {
    var accountData = accountFormData;
    return api_1.default.post(base, accountData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(oldAccountData, accountFormData) {
    var accountData = accountFormData;
    return api_1.default.put("".concat(base, "/").concat(oldAccountData.id), accountData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
function getAccount(id) {
    return api_1.default.get("".concat(base, "/").concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAccount = getAccount;
function deleteAccount(id) {
    return api_1.default.delete("".concat(base, "/").concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteAccount = deleteAccount;
function getInvoicingEntities(id) {
    return api_1.default
        .get('/api/invoicing-entity/account/' + id)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getInvoicingEntities = getInvoicingEntities;
function getContracts(id) {
    return api_1.default
        .get('/api/contract/account/' + id)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getContracts = getContracts;
function getProjectCount(accountId) {
    return api_1.default.get("".concat(base, "/").concat(accountId, "/project-count")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectCount = getProjectCount;
function getFeedbacks(accountId) {
    return api_1.default.get("".concat(base, "/feedbacks/").concat(accountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeedbacks = getFeedbacks;
function getAccountsByParentAccount(parentAccountId) {
    return api_1.default.get("".concat(base, "/parent-account/").concat(parentAccountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAccountsByParentAccount = getAccountsByParentAccount;
function accountTypeahead(query) {
    var q = query ? "q=".concat(encodeURIComponent(query)) : '';
    return api_1.default.get("".concat(base, "/typeahead?").concat(q)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.accountTypeahead = accountTypeahead;
var api = {
    create: create,
    update: update,
    getAccount: getAccount,
    getInvoicingEntities: getInvoicingEntities,
    getContracts: getContracts,
    getProjectCount: getProjectCount,
    getFeedbacks: getFeedbacks,
    deleteAccount: deleteAccount,
    getAccountsByParentAccount: getAccountsByParentAccount,
};
exports.default = api;
