"use strict";
// courtesy of W3Schools, https://www.w3schools.com/js/js_cookies.asp
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearCookies = exports.getCookie = exports.setCookie = void 0;
var tslib_1 = require("tslib");
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
exports.setCookie = setCookie;
function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
exports.getCookie = getCookie;
function clearCookies() {
    var cookies = document.cookie.split(';').map(function (cookie) { return cookie.trim().split('=')[0]; });
    window.location.hostname
        .split('.')
        .reverse()
        .reduce(function (acc, val, i) { return (i ? tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(acc), false), ["".concat(val, ".").concat(acc[i - 1])], false) : [val]); }, [])
        .flatMap(function (d) { return [d, ".".concat(d)]; })
        .forEach(function (domain) {
        cookies.forEach(function (name) {
            document.cookie = "".concat(name, "=;max-age=0;path=/;domain=").concat(domain);
        });
    });
}
exports.clearCookies = clearCookies;
