"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExtensionInfo = exports.getStandardConditions = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getStandardConditions(officeFormData) {
    return api_1.default.get('/api/standard-conditions').then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getStandardConditions = getStandardConditions;
function getExtensionInfo() {
    return api_1.default.get('/api/linkedin-extension/info').then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getExtensionInfo = getExtensionInfo;
var api = {
    getStandardConditions: getStandardConditions,
    getExtensionInfo: getExtensionInfo,
};
exports.default = api;
