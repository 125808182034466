import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { StatusLabel } from '@components/StatusLabel'
import Spinner from '@components/Spinner'
import { Error } from '@components/NoContent'
import InfoWrapperRow from '@components/InfoWrapper'
import { formatDate, formatNumber } from '@lib/helperFunctions'
import ProjectListing from './ProjectListing'
import ConsultationListing from './ConsultationListing'
import Arrow from '@components/Icons/Arrow'
import Download from '@components/Icons/Download'
import tokenStorage from '@lib/tokenStorage'

let devApiUrl = ''
if (process.env.NODE_ENV === 'development') {
  devApiUrl = 'http://localhost:3000'
}

class SingleContract extends Component {
  static propTypes = {
    singleContractStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.loadSingleContractData()
  }

  loadSingleContractData() {
    const { singleContractStore, match } = this.props
    const contractId = match.params.id
    singleContractStore.activeTab = 'Projects'
    singleContractStore.loadSingleContract(contractId)
  }

  onExportClick = () => {
    const { activeTab, contractId } = this.props.singleContractStore
    const token = tokenStorage.get()
    const url =
      activeTab === 'Projects'
        ? `${devApiUrl}/api/contract/${contractId}/projects?export=true&token=${token}`
        : `${devApiUrl}/api/fee/contract/${contractId}?excel=true&token=${token}`
    window.open(url)
  }

  renderCoverageItems = (contract) => {
    const { GLOBAL, SPECIFIC_PROJECTS } = this.props.staticDataStore.staticData.CONST.CONTRACT_COVERAGES
    if (contract.contractCoverageId === GLOBAL) return null
    else {
      return contract.contractCoverageId === SPECIFIC_PROJECTS
        ? contract.projects.map((project) => {
            return <InfoWrapperRow key={project.projectName} text={project.projectName} />
          })
        : contract.accounts.map((account) => {
            return <InfoWrapperRow key={account.companyName} text={account.companyName} />
          })
    }
  }

  renderDetails = (singleContract) => {
    const { staticData } = this.props.staticDataStore
    const { contractTypes, contractCoverages, currencies } = staticData.names
    return (
      <div className="sections-one sections-one--standalone m-b-lg">
        <h2 className="sections-one__title">
          <div className="sections-one__section__title">
            <span className="m-r-md">{singleContract.contractReference}</span>
            <StatusLabel statusId={singleContract.contractStatusId} statusesType="contractStatuses" />
          </div>
        </h2>
        <div className="sections-one__section">
          <InfoWrapperRow
            label="Client key contact"
            text={singleContract.clientContact && singleContract.clientContact.fullName}
          />
          <InfoWrapperRow label="Contract type" text={contractTypes[singleContract.contractTypeId]} />
          <InfoWrapperRow label="Contract coverage" text={contractCoverages[singleContract.contractCoverageId]} />
          {this.renderCoverageItems(singleContract)}
          <div className="m-t-lg">
            <InfoWrapperRow
              label="Start - end date"
              text={`${formatDate(singleContract.startDate)} - ${formatDate(singleContract.endDate)}`}
            />
            <InfoWrapperRow label="Credits" text={`${formatNumber(singleContract.creditAmount)} credits`} />
            <InfoWrapperRow
              label="Credit value"
              text={`${formatNumber(singleContract.creditValue)} ${currencies[singleContract.currencyId]}`}
            />
            <InfoWrapperRow label="Total value" text={formatTotalValue(singleContract, currencies)} />
            <InfoWrapperRow label="Remaining credits" text={formatBalance(singleContract, staticData)} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { singleContractStore } = this.props
    const { activeTab, singleContract, loading, error } = singleContractStore
    const { staticData } = this.props.staticDataStore
    const { subIndustries } = staticData.names

    if (loading) return <Spinner />
    if (error) return <Error error={error} />

    const tabs = ['Projects', 'Consultations']
    return (
      <div>
        <div className="container">
          <NavLink to="/contracts" className="sections-one__link">
            <Arrow rotate180 /> Back to contracts
          </NavLink>
        </div>
        <div className="container">{this.renderDetails(singleContract)}</div>
        <div className="container m-t-xl">
          <div className="sections-one sections-one--standalone">
            <div className="tabs__container m-b-md">
              <nav className="tabs__scrollable-container">
                <div className="tabs m-l-md">
                  {tabs.map((tab) => {
                    const classes = classNames({
                      tab: true,
                      'tab--active': activeTab === tab,
                    })
                    return (
                      <div
                        key={tab}
                        className={classes}
                        onClick={() => {
                          singleContractStore.activeTab = tab
                        }}
                      >
                        <li className="tab__link">{tab}</li>
                      </div>
                    )
                  })}
                </div>
                <div className="sections-one__link contract" onClick={this.onExportClick}>
                  <Download className="sections-one__action download--icon" />
                </div>
              </nav>
            </div>
            {activeTab === 'Projects' ? <ProjectListing subIndustries={subIndustries} /> : <ConsultationListing />}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(inject('singleContractStore', 'staticDataStore')(observer(SingleContract)))

function formatTotalValue(contract, currencies) {
  const currency = currencies[contract.currencyId]
  if (contract.unlimited) return 'unlimited'

  const totalValue = contract.value || contract.calculatedValue
  return `${formatNumber(totalValue)} ${currency}`
}

function formatBalance(contract, staticData) {
  const { currencies } = staticData.names
  const { CONTRACT_TYPES } = staticData.CONST
  const currency = contract.contractTypeId === CONTRACT_TYPES.PAYG ? currencies[contract.currencyId] : 'credits'
  if (contract.unlimited) return 'N/A'
  return `${formatNumber(contract.contractBalance)} ${currency}`
}
