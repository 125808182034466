export default function convertStaticDataToOptions(staticDataItem) {
  return staticDataItem.map((item) => {
    if (typeof item === 'object') {
      return {
        name: item.name || item.nameForInternal,
        id: item.id,
      }
    }

    return {
      name: item,
      id: item,
    }
  })
}
