import staticDataStore from './staticData.store'
import UserStore from './user.store'
import Modal from './modal.store'
import ActiveProjectsStore from './ActiveProjects.store'
import ActiveContractsStore from './ActiveContracts.store'
import SingleContractStore from './SingleContract.store'
import AtheneumContactStore from './AtheneumContact.store'
import ProjectStore from './Project.store'

import ConsultationListingStore from './ConsultationListing.store'
import ComplianceAuditStore from './ComplianceAudit.store'
import GrowlStore from './Growl.store'
import NotificationStore from './Notification.store'
import socketIOClient from 'socket.io-client'

export const socket = socketIOClient.connect({
  transports: ['websocket'],
})

const growlStore = new GrowlStore()
const notificationStore = new NotificationStore(growlStore)
const userStore = new UserStore(socket, notificationStore)
const modalStore = new Modal()
const activeContractsStore = new ActiveContractsStore(staticDataStore)
const singleContractStore = new SingleContractStore()
const atheneumContactStore = new AtheneumContactStore()
const projectStore = new ProjectStore(staticDataStore)

const consultationListingStore = new ConsultationListingStore()
const complianceAuditStore = new ComplianceAuditStore(staticDataStore)
const activeProjectsStore = new ActiveProjectsStore(growlStore)

const stores = {
  staticDataStore,
  userStore,
  modalStore,
  activeProjectsStore,
  activeContractsStore,
  singleContractStore,
  consultationListingStore,
  complianceAuditStore,
  growlStore,
  notificationStore,
  atheneumContactStore,
  projectStore,
}

window.stores = stores

export { stores }
