import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import { InputLabel } from '@material-ui/core'

const Dropdown = ({ field, form, label, options, className, helperText, variant, onChange }) => {
  const error = get(form.errors, field.name)
  const fieldValue = get(form.values, field.name)

  return (
    <div className={className}>
      <FormControl fullWidth variant={variant} error={error}>
        {label && <InputLabel className="label">{label}</InputLabel>}
        <Select label={label} value={fieldValue} onChange={onChange}>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  )
}

Dropdown.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  renderItem: PropTypes.object,
  options: PropTypes.array,
  className: PropTypes.string,
  variant: PropTypes.string,
}

export default Dropdown
