import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

class Input extends Component {
  handleChange = (e) => {
    const { form, field } = this.props
    form.setFieldValue(field.name, e.target.value)
  }

  render() {
    const {
      form,
      field,
      label,
      type,
      multiline,
      rows,
      variant,
      className,
      placeholder,
      helperText,
      onChange,
      customError,
    } = this.props

    const error = get(form.errors, field.name) || customError
    const fieldValue = get(form.values, field.name)

    return (
      <div className={className}>
        <FormControl fullWidth variant={variant}>
          <TextField
            inputProps={{
              autoComplete: 'new-field',
              form: {
                autocomplete: 'off',
              },
            }}
            label={label}
            placeholder={placeholder}
            type={type}
            variant={variant}
            multiline={multiline}
            rows={rows}
            value={fieldValue}
            fullWidth
            error={error}
            helperText={error || helperText || ''}
            onChange={onChange || this.handleChange}
          />
        </FormControl>
      </div>
    )
  }
}

Input.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  variant: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
}

export default Input
