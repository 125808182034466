"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundCurrency = exports.formatDecimal = exports.formatNoDecimal = exports.formatNoDecimalBigInt = void 0;
function formatNoDecimalBigInt(value) {
    if (!Number.isInteger(parseInt(value)))
        return '-';
    return Math.round(value / 1e6).toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
}
exports.formatNoDecimalBigInt = formatNoDecimalBigInt;
function formatNoDecimal(value) {
    if (!Number.isInteger(parseInt(value)))
        return '-';
    return Math.round(value).toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
}
exports.formatNoDecimal = formatNoDecimal;
function formatDecimal(value) {
    if (!Number.isInteger(parseInt(value)))
        return '-';
    return Math.round(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
}
exports.formatDecimal = formatDecimal;
function roundCurrency(value) {
    if (!Number.isInteger(parseInt(value)))
        return '-';
    return (value / 1e6).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
}
exports.roundCurrency = roundCurrency;
