import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Spinner from '@components/Spinner'
import { NoContent, Error } from '@components/NoContent'
import Pagination from '@components/Pagination'

import { Dropdown } from '@components/Autocomplete'

import AtheneumContacts from '@components/AtheneumContacts'

import ComplianceAuditSingle from './ComplianceAuditSingle'

class ComplianceAuditListing extends Component {
  static propTypes = {
    complianceAuditStore: PropTypes.object,
    staticDataStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.loadComplianceAuditEpls()
  }

  loadComplianceAuditEpls() {
    this.props.complianceAuditStore.loadAuditEpls()
  }

  render() {
    const { complianceAuditStore, atheneumContactStore } = this.props

    const { atheneumContact } = atheneumContactStore
    const { loading, auditEpls, page, perPage, totalNumOfEpls, orderOptions, error } = this.props.complianceAuditStore

    if (loading) return <Spinner />
    if (error) return <Error error={error} />
    return (
      <div>
        <div className="container">
          <div className="sections-one">
            {auditEpls.length === 0 ? (
              <NoContent item="EPLs to audit at the moment." />
            ) : (
              <div>
                <div className="filter__group">
                  <h2 className="sections-one__title">Expert profiles</h2>
                  <div className="sort__group">
                    <div>sort by</div>
                    <div style={{ width: 200, marginLeft: 10 }}>
                      <Dropdown
                        selectedItem={complianceAuditStore.orderBy}
                        onChange={(option) => {
                          complianceAuditStore.orderEplsBy(option)
                        }}
                        options={orderOptions}
                      />
                    </div>
                  </div>
                </div>
                {auditEpls.map((epl) => (
                  <ComplianceAuditSingle epl={epl} />
                ))}
              </div>
            )}
            <Pagination
              total={totalNumOfEpls}
              perPage={perPage}
              page={page}
              onChange={this.props.complianceAuditStore.changePage}
            />
          </div>
          <div className="sections-two">
            <div className="sections-one__section info-wrapper__row m-b-lg">
              <NavLink exact to="/project">
                <li className="sections-two__link">Create a new project</li>
              </NavLink>
              <div>
                If you have any questions please reach us at{' '}
                <a className="atheneum-contacts__link" href="mailto: request@atheneum-partners.com">
                  request@atheneum-partners.com
                </a>
              </div>
            </div>
            {atheneumContact && atheneumContact.status ? (
              <div>
                <AtheneumContacts contact={atheneumContact} staticData={this.props.staticDataStore.staticData} />
                <hr />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  inject(
    'complianceAuditStore',
    'atheneumContactStore',
    'staticDataStore',
    'modalStore',
    'userStore',
    'growlStore',
  )(observer(ComplianceAuditListing)),
)
