import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    activeRoom: null,
    chatListOpen: false,
    eplChat: null,
    activeChatClass: null,
    markedSeen: true,
    availableRoom: undefined,
    messages: [],
    seenStatus: {},
  },
  reducers: {
    setSeenStatus: (state: any, action: any) => {
      state.seenStatus[action.payload.eplId] = action.payload.status
    },
    setActiveRoom: (state: any, action: any) => {
      if (!action.payload.room.messages || action.payload.room.messages.length === action.payload.room.seens.length) {
        state.markedSeen = true
      } else {
        state.markedSeen = false
      }
      state.activeChatClass = action.payload.className
      state.activeRoom = action.payload.room
      state.messages = action.payload.room.messages || []
    },
    closeChat: (state: any) => {
      state.markedSeen = true
      state.activeRoom = null
      state.activeChatClass = null
    },
    setMarkedSeen: (state: any, action: any) => {
      state.markedSeen = true
    },
    openEplChat: (state: any, action: any) => {
      state.activeRoom = null
      state.eplChat = action.payload.eplId
      state.chatListOpen = true
      state.availableRoom = action.payload.room
    },
    clearAvailableRoom: (state: any) => {
      state.availableRoom = undefined
    },
    closeChatList: (state: any) => {
      state.markedSeen = true
      state.activeRoom = null
      state.activeChatClass = null
      state.eplChat = null
      state.chatListOpen = false
      state.availableRoom = undefined
    },
    toggleChatListOpen: (state: any, action: any) => {
      state.chatListOpen = action.payload
    },
    updateMessages: (state: any, action: PayloadAction<Array<any>>) => {
      const messages = action.payload
      state.messages = messages

      if (state.activeRoom.id === messages[0].roomId && state.messages?.length !== messages.length) {
        state.markedSeen = false
      }
    },
    appendMessage: (
      state: any,
      action: PayloadAction<{
        message: any
        user: any
        roomName: any
        meta: any
      }>,
    ) => {
      const newMessage = {
        ...action.payload,
        user: {
          id: action.payload.user.id,
          fullName: action.payload.user.fullName,
          type: action.payload.user.userTypeId,
          avatar: action.payload.user.picture?.path,
        },
      }
      state.messages = state.messages.concat(newMessage)
      state.markedSeen = true
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setMarkedSeen,
  setActiveRoom,
  closeChat,
  openEplChat,
  closeChatList,
  updateMessages,
  appendMessage,
  toggleChatListOpen,
  clearAvailableRoom,
  setSeenStatus,
} = chatSlice.actions
export default chatSlice.reducer
