"use strict";
// DEPRICATED
// Do not use this
// Use formatNumber instead frontend/src/lib/formatNumber.js
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCurrency = void 0;
function formatCurrency(raw) {
    if (raw && typeof raw !== 'number') {
        return Number(raw.replace(/\./g, '').replace(/,/g, '.'));
    }
    return raw;
}
exports.formatCurrency = formatCurrency;
