"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendMetric = exports.create = exports.get = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var lib_1 = require("./lib");
var base = '/api/email-template-metric';
function get(payload) {
    var url = (0, lib_1.addQueryParams)(base, tslib_1.__assign({}, payload));
    return api_1.default.get("".concat(url)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.get = get;
function create(data) {
    return api_1.default.post("".concat(base), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function sendMetric(data) {
    return api_1.default.post("".concat(base, "/metric"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendMetric = sendMetric;
var api = {
    get: get,
    create: create,
    sendMetric: sendMetric,
};
exports.default = api;
