import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { InputMUI } from '@components/Form'
import { Field } from 'formik'
import { toJS } from 'mobx'

class ScreeningQuestionsForm extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    projectStore: PropTypes.object,
    staticDataStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      isSubmitting: false,
      questionCount: this.props.form.values.screeningQuestions ? this.props.form.values.screeningQuestions.length : 0,
    })
  }

  addQuestion = () => {
    const { projectStore } = this.props
    projectStore.updateSegmentScreeningQuestions(this.questionCount, '')
    this.questionCount++
  }

  deleteQuestion = (index) => {
    const { projectStore } = this.props

    projectStore.deleteQuestion(index)
    this.questionCount--
  }

  handleTabChange = (index) => {
    const { projectStore } = this.props
    const { setActiveSegment, segments } = projectStore

    this.handleFormValues(segments[index])
    setActiveSegment(index)
  }

  handleFormValues = (segment) => {
    const { form } = this.props

    form.setFieldValue('screeningQuestions', toJS(segment.screeningQuestions))
    this.questionCount = segment.screeningQuestions.length || 0
  }

  onInputChange = (val, field, i) => {
    const { form, projectStore } = this.props

    form.setFieldValue(field, val)
    projectStore.updateSegmentScreeningQuestions(i, val)
  }

  render() {
    const { projectStore, form } = this.props
    const { segments, activeSegmentIndex } = projectStore
    const formScreeningQuestions = form.values.screeningQuestions
    let screeningQuestions = []

    if (formScreeningQuestions.length) {
      for (const key in formScreeningQuestions) {
        screeningQuestions.push(
          <Grid item className="question" xs={12} key={key}>
            <div className="index">{+key + 1}.</div>
            <Field
              form={form}
              name={`screeningQuestions[${key}]`}
              variant="standard"
              component={InputMUI}
              onChange={(e) => this.onInputChange(e.target.value, `screeningQuestions[${key}]`, key)}
            />
            <IconButton aria-label="expand row" size="small" onClick={() => this.deleteQuestion(key)}>
              <DeleteIcon />
            </IconButton>
          </Grid>,
        )
      }
    }

    for (let i = form.values.screeningQuestions.length; i < this.questionCount; i++) {
      screeningQuestions.push(
        <Grid item className="question" xs={12} key={i}>
          <div className="index">{i + 1}.</div>
          <Field
            form={form}
            name={`screeningQuestions[${i}]`}
            variant="standard"
            component={InputMUI}
            onChange={(e) => this.onInputChange(e.target.value, `screeningQuestions[${i}]`, i)}
          />
        </Grid>,
      )
    }

    return (
      <React.Fragment>
        <Typography component={'div'}>
          <div className="form-title">Screening Questions</div>
          <div className="form-tabs">
            {segments.map((segment, index) => {
              return (
                <ScopeTab
                  key={index}
                  name={segment.segmentName}
                  active={segment.segmentName === segments[activeSegmentIndex].segmentName}
                  handleClick={() => this.handleTabChange(index)}
                />
              )
            })}
          </div>
          <div className="form-title">Questions</div>
          <div className="screening-questions-wrapper">
            <Grid container item xs={12} spacing={4} className="sceening-questions">
              {screeningQuestions}
            </Grid>
          </div>
          <Grid container className="add-question" onClick={this.addQuestion}>
            <AddCircleOutlinedIcon />
            &nbsp;<span>Add new question</span>
          </Grid>
        </Typography>
      </React.Fragment>
    )
  }
}

function ScopeTab(props) {
  const { name, active, handleClick } = props
  return (
    <div className={`scope-tab ${active && 'active'}`} onClick={handleClick}>
      {name}
    </div>
  )
}

export default withRouter(inject('staticDataStore', 'userStore', 'projectStore')(observer(ScreeningQuestionsForm)))
