"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeX_prefix = void 0;
// Outlook will add 'x_' prefix to all IDs and classes in message template
// In most cases when fetching Outlook Event they remove these prefixes
// But in couple of cases they haven't removed it(we are not sure why)
// Which was making faster scheduling to fail when opening due to some key IDs being changed(like clientJoinUrls/expertJoinUrl)
var removeX_prefix = function (outlookEvent) {
    var _a;
    var editedOutlookEvent = Object.assign({}, outlookEvent);
    if ((_a = editedOutlookEvent === null || editedOutlookEvent === void 0 ? void 0 : editedOutlookEvent.body) === null || _a === void 0 ? void 0 : _a.content) {
        var idRegex = new RegExp(/id="x_/gm);
        var classRegex = new RegExp(/class="x_/gm);
        editedOutlookEvent.body.content = editedOutlookEvent.body.content
            .replace(idRegex, "id=\"")
            .replace(classRegex, "class=\"");
    }
    return editedOutlookEvent;
};
exports.removeX_prefix = removeX_prefix;
