import { when } from 'mobx'
import * as Yup from 'yup'
import CreateProjectFormModel from './CreateProjectFormModel'
const {
  formField: { projectName, projectTypeId, projectDescription, applyBeforeDate, timezone, industries, industryName },
} = CreateProjectFormModel

export default [
  Yup.object().shape({
    [projectName.name]: Yup.string().required(`${projectName.requiredErrorMsg}`).nullable(),
    [projectTypeId.name]: Yup.number().required(`${projectTypeId.requiredErrorMsg}`),
    [projectDescription.name]: Yup.string().required(`${projectDescription.requiredErrorMsg}`).nullable(),
    [applyBeforeDate.name]: Yup.date().required(`${applyBeforeDate.requiredErrorMsg}`),
    [timezone.name]: Yup.string().required(`${timezone.requiredErrorMsg}`).nullable(),
    projectDirectorPhone: Yup.string()
      .matches(/^[0-9\-+/\s]*$/, "Phone number can contain only digits, '+', '-', ' ' and '/'")
      .nullable(),
    projectLeadPhone: Yup.string()
      .matches(/^[0-9\-+/\s]*$/, "Phone number can contain only digits, '+', '-', ' ' and '/'")
      .nullable(),
    projectDirectorEmail: Yup.string().email('Email format is invalid'),
    projectLeadEmail: Yup.string().email('Email format is invalid'),
  }),
  Yup.object().shape({
    [industries.name]: Yup.array()
      .required(`${industries.requiredErrorMsg}`)
      .nullable()
      .of(Yup.string())
      .min(1, `${industries.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [industries.name]: Yup.array()
      .required(`${industries.requiredErrorMsg}`)
      .nullable()
      .of(Yup.string())
      .min(1, `${industries.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    screeningQuestions: Yup.array(),
  }),
]
