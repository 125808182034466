import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import * as yup from 'yup'

import { checkEmail } from '@api/user'

function getValidationSchema({ user }) {
  return yup.object().shape({
    address: yup.object().shape({
      city: yup.string().required('City field is required'),
      phones: yup.array().of(
        yup.object().shape({
          phoneTypeId: yup.number().integer().typeError('Phone type is required'),
          phoneNum: yup
            .string()
            .matches(/^[0-9\-+/\s]*$/, "Phone number can contain only digits, '+', '-', ' ' and '/'")
            .required('Phone number is required'),
        }),
      ),
      emails: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .email('Email format is invalid')
            .required('Email is required')
            .test('unique', 'Email is already in use', (value) => {
              if (yup.string().email().isValidSync(value)) {
                if (!value) return true // fallback to required error
                const payload = { email: value, id: user.get('id') }
                return checkEmail(payload)
                  .then(({ valid }) => valid)
                  .catch((_) => false)
              }
              return true
            }),
        }),
      ),
    }),
    email: yup.string().email('Email format is invalid'),
    emailNotRepeating: yup
      .mixed()
      .test('emailNotRepeating', 'You are using the same email in multiple fields', function () {
        const primaryEmail = this.parent.email
        const restOfEmails = this.parent.address.emails.map((email) => email.email)
        const emails = [primaryEmail, ...restOfEmails]

        const uniqueEmails = uniq(emails)

        return emails.length === uniqueEmails.length
      }),
    phoneNotRepeating: yup
      .mixed()
      .test('phoneNotRepeating', 'You are using the same phone number in multiple fields', function () {
        const phones = compact(this.parent.address.phones.map((phone) => phone.phoneNum))
        const uniquePhones = compact(uniq(phones))

        return phones.length === uniquePhones.length
      }),
  })
}

export default getValidationSchema
