import React from 'react'

const InfoIcon = () => {
  return (
    <div className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <path
          fill="#3259A9"
          fillRule="evenodd"
          d="M6.75,11.25 L8.25,11.25 L8.25,6.75 L6.75,6.75 L6.75,11.25 L6.75,11.25 Z M7.5,0 C3.375,0 0,3.375 0,7.5 C0,11.625 3.375,15 7.5,15 C11.625,15 15,11.625 15,7.5 C15,3.375 11.625,0 7.5,0 L7.5,0 Z M7.5,13.5 C4.2,13.5 1.5,10.8 1.5,7.5 C1.5,4.2 4.2,1.5 7.5,1.5 C10.8,1.5 13.5,4.2 13.5,7.5 C13.5,10.8 10.8,13.5 7.5,13.5 L7.5,13.5 Z M6.75,5.25 L8.25,5.25 L8.25,3.75 L6.75,3.75 L6.75,5.25 L6.75,5.25 Z"
        />
      </svg>
    </div>
  )
}

export default InfoIcon
