import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidePanel from '../SidePanel'
import Logo from '../SidePanel/logo'

import './NotFoundPage.sass'

class NotFoundPage extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.add('temp-responsive')
    document.getElementsByTagName('html')[0].classList.add('temp-responsive')
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('temp-responsive')
    document.getElementsByTagName('html')[0].classList.remove('temp-responsive')
  }

  static propTypes = {
    title: PropTypes.string,
    continueToMessage: PropTypes.string,
    continueToLinkName: PropTypes.string,
    continueToLink: PropTypes.string,
  }

  static defaultProps = {
    title: 'Page not found',
    continueToMessage: 'Please continue to',
    continueToLinkName: 'dashboard',
    continueToLink: '/',
  }

  render() {
    const { title, continueToMessage, continueToLinkName, continueToLink } = this.props

    return (
      <div className="auth">
        <SidePanel />
        <div className="expert-form-login">
          <Logo className="auth-form-logo" />
          <h1 className="expert-form-login__title">{title}</h1>
          <div>
            {continueToMessage} <a href={continueToLink}>{continueToLinkName}</a>
          </div>
        </div>
      </div>
    )
  }
}

export default NotFoundPage
