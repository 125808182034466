import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { InputMUI, SelectMUI, DatePickerMUI, AutocompleteMUI } from '@components/Form'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import { Field } from 'formik'
import convertStaticDataToOptions from '@lib/convertStaticDataToOptions'

const firstSteps = ['General Information', 'Project Information']
class EssentialsForm extends Component {
  static propTypes = {
    projectStore: PropTypes.object,
    staticDataStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      isSubmitting: false,
      activeStep: 0,
    })
  }

  handleStep = async (index) => {
    const { form, projectStore } = this.props
    const { project, segments, activeSegmentIndex } = projectStore

    if (this.activeStep === index) return

    const errors = await form.validateForm({ ...project, ...segments[activeSegmentIndex] })

    if (Object.keys(errors).length === 0) {
      this.activeStep = index
    }
  }

  handleBack = () => {
    this.activeStep--
  }

  handleReset = () => {
    this.activeStep = 0
  }

  onInputChange = (val, field) => {
    const { form, projectStore } = this.props
    form.setFieldValue(field, val)
    projectStore.updateProject(field, val)
  }

  render() {
    const { form } = this.props
    const { staticData } = this.props.staticDataStore
    const timezones = Object.entries(staticData.names.timezones).map((t) => t[1])

    return (
      <React.Fragment>
        <Typography component={'div'} className="essentials">
          <Stepper activeStep={this.activeStep} alternativeLabel orientation="vertical" style={{ width: '100%' }}>
            <Step key={firstSteps[0]} style={{ width: '100%' }} onClick={() => this.handleStep(0)}>
              <StepLabel style={{ width: 'fit-content', flexDirection: 'row', textAlign: 'left' }}>
                {firstSteps[0]}
              </StepLabel>
              {this.activeStep === 0 && (
                <StepContent style={{ width: '100%' }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field
                        form={form}
                        name={'projectName'}
                        label="Project Name"
                        variant="standard"
                        component={InputMUI}
                        onChange={(e) => this.onInputChange(e.target.value, 'projectName')}
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12} md={6}>
                        <Field
                          form={this.props.form}
                          name={`projectTypeId`}
                          label="Project Type"
                          variant="standard"
                          itemToString={(item) => item && item.name}
                          options={convertStaticDataToOptions(staticData.projectTypes)}
                          component={SelectMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectTypeId')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          form={this.props.form}
                          name={`applyBeforeDate`}
                          label="Deadline"
                          variant="standard"
                          component={DatePickerMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'applyBeforeDate')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        form={this.props.form}
                        placeholder="Brief placeholder"
                        name={`projectDescription`}
                        label="Project Brief"
                        multiline
                        rows={4}
                        variant="standard"
                        component={InputMUI}
                        onChange={(e) => this.onInputChange(e.target.value, 'projectDescription')}
                      />
                    </Grid>
                  </Grid>
                </StepContent>
              )}
            </Step>
            <Step key={firstSteps[1]} style={{ width: '100%' }} onClick={() => this.handleStep(1)}>
              <StepLabel style={{ width: 'fit-content', flexDirection: 'row', textAlign: 'left' }}>
                {firstSteps[1]}
              </StepLabel>
              {this.activeStep === 1 && (
                <StepContent style={{ width: '100%' }}>
                  <Grid container spacing={4}>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectDirectorName`}
                          label="Project Director"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectDirectorName')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectDirectorEmail`}
                          label="Email"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectDirectorEmail')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectDirectorPhone`}
                          label="Phone"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectDirectorPhone')}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectLeadName`}
                          label="Project Lead"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectLeadName')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectLeadEmail`}
                          label="Email"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectLeadEmail')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          form={this.props.form}
                          name={`projectLeadPhone`}
                          label="Phone"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'projectLeadPhone')}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12} md={6}>
                        <Field
                          form={this.props.form}
                          name={`timezone`}
                          label="Timezone"
                          variant="standard"
                          itemToString={(item) => item}
                          options={timezones}
                          component={AutocompleteMUI}
                          onChange={(e, val) => this.onInputChange(val, 'timezone')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          form={this.props.form}
                          name={`chargeCode`}
                          label="Charge code"
                          variant="standard"
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, 'chargeCode')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StepContent>
              )}
            </Step>
          </Stepper>
        </Typography>
      </React.Fragment>
    )
  }
}
export default withRouter(inject('staticDataStore', 'projectStore')(observer(EssentialsForm)))
