import clientApi from './api'

export function login(data) {
  return clientApi.post('/api/client/login', data)
}

export function quickLogin(token) {
  return clientApi.post('/api/client/quick-login', { token })
}

export function checkEmail(payload) {
  return clientApi.post('/api/email-check', payload)
}

export function retrievePassword(email) {
  return clientApi.post('/api/client/forgot-password', email)
}

export function getPasswordStrength(password) {
  return clientApi.post('/api/password-strength', { password })
}

export function resetPassword(data) {
  return clientApi.post('/api/reset-password', data)
}
