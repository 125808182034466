import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import get from 'lodash/get'

class TagsInputMUI extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    form: PropTypes.object,
    itemToString: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    freeSolo: PropTypes.string,
    options: PropTypes.array,
    skipOptions: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    loadOptions: PropTypes.func,
    variant: PropTypes.string,
    helperText: PropTypes.string,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      options: this.props.options,
    })
  }
  handleChange = (e, val) => {
    const { form, field } = this.props
    form.setFieldValue(field.name, val)
  }

  loadOptions = async (e) => {
    const value = e.target.value
    const { loadOptions } = this.props
    if (loadOptions) {
      this.options = await loadOptions(value)
    }
  }

  render() {
    const { field, form, variant, value, label, className, freeSolo, onChange, onBlur, skipOptions } = this.props

    const error = get(form.errors, field.name)
    const fieldValue = get(form.values, field.name)

    return (
      <div className={className}>
        <Autocomplete
          multiple
          options={this.options.map((option) => option.name)}
          getOptionDisabled={(option) => skipOptions && skipOptions.includes(option)}
          value={value || fieldValue || []}
          freeSolo={freeSolo}
          onChange={onChange || this.handleChange}
          onBlur={onBlur}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              label={label}
              onInput={this.loadOptions}
              error={!!error}
              helperText={error}
            />
          )}
        />
      </div>
    )
  }
}

TagsInputMUI.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  variant: PropTypes.string,
}

export default observer(TagsInputMUI)
