import matchSorter from 'match-sorter'

export function getKey(itemToString, item) {
  if (typeof item === 'string') return item
  if (item.id) return item.id
  if (itemToString) return itemToString(item)
  return item
}

export function renderItem({ renderItem, itemToString, item }) {
  if (renderItem) return renderItem(item)
  if (itemToString) return itemToString(item)
  return item
}

export function reorderItems({ items, inputValue, itemToString }) {
  if (!inputValue) return items

  return sortAndFilterItems(items, inputValue, itemToString)
}

function sortAndFilterItems(items, inputValue, itemToString) {
  const options = {
    keys: itemToString ? [itemToString] : undefined,
  }
  return matchSorter(items, inputValue, options)
}

export function isSelected({ selectedItem, item, itemToString }) {
  if (!selectedItem || selectedItem.length === 0) return false

  if (typeof selectedItem === 'string') {
    return selectedItem === item
  }

  if (selectedItem.length) {
    const selectedItemKeys = selectedItem.map((item) => getKey(itemToString, item))
    const itemKey = getKey(itemToString, item)
    return selectedItemKeys.includes(itemKey)
  }

  return getKey(itemToString, selectedItem) === getKey(itemToString, item)
}
