"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportDialogMessage = void 0;
var tslib_1 = require("tslib");
var Dialog_1 = require("../components/Dialog");
function exportDialogMessage(_a) {
    var exportType = _a.exportType;
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, (0, Dialog_1.showDialog)({
                        title: 'Export in progress',
                        text: "\n      Your ".concat(exportType, " export is being processed. Once it's ready\n      download will start automatically. This process can take up to five\n      minutes.\n      "),
                        type: 'info',
                    }).catch(function (err) { return (0, Dialog_1.isCancellationError)(err); })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.exportDialogMessage = exportDialogMessage;
