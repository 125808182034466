"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOffice = exports.getAllOffices = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/atheneum-office';
function getAllOffices() {
    return api_1.default.get(base).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAllOffices = getAllOffices;
function updateOffice(id, formData) {
    return api_1.default.put("".concat(base, "/").concat(id), formData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateOffice = updateOffice;
var api = {
    getAllOffices: getAllOffices,
    updateOffice: updateOffice,
};
exports.default = api;
