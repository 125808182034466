import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import SidePanel from '../SidePanel'
import Logo from '../SidePanel/logo.js'

class ThankYou extends Component {
  static propTypes = {
    pageTitle: PropTypes.string,
    accept: PropTypes.func,
    buttonVisible: PropTypes.bool,
    message: PropTypes.string,
    header: PropTypes.object,
    formInnerStyle: PropTypes.object,
    content: PropTypes.object,
  }

  render() {
    const {
      pageTitle,
      accept,
      buttonVisible,
      message = 'You have accepted the agreement.',
      header,
      formInnerStyle,
      content,
    } = this.props
    return (
      <div className="expert-compliance">
        <SidePanel />
        <div className="form-expert-compliance">
          <Logo className="expert-compliance-form-logo" />
          <h1 className="form-expert-compliance__title">{pageTitle}</h1>
          <div className="form-inner" style={formInnerStyle}>
            {header || <h3 className="form-expert-compliance__title form-expert-compliance__subtitle">Thank you</h3>}
            {content || <div className="expert-compliance-text">{message}</div>}
            {buttonVisible && (
              <div className="expert-compliance-form-button-section">
                <Button red onClick={accept}>
                  OK
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ThankYou
