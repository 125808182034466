import React, { Component } from 'react'
import NoProfilePicIcon from '../Icons/NoProfilePic'
import PropTypes from 'prop-types'

class Avatar extends Component {
  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      errorLoadingImage: false,
    }
  }

  render() {
    const cantLoadImage = !this.props.src || this.state.errorLoadingImage
    if (cantLoadImage) {
      return (
        <div className="atheneum-contacts__avatar">
          <NoProfilePicIcon />
        </div>
      )
    }

    return (
      <img
        className="atheneum-contacts__avatar"
        src={`/uploads/${this.props.src}`}
        alt={this.props.alt}
        onError={() => this.setState({ errorLoadingImage: true })}
      />
    )
  }
}

export default Avatar
