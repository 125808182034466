import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Spinner from '@components/Spinner'
import Pagination from '@components/Pagination'
import { StatusLabel } from '@components/StatusLabel'
import { NoContent, Error } from '@components/NoContent'
import { formatDate } from '@lib/helperFunctions'
import AtheneumContacts from '@components/AtheneumContacts'

class ActiveContracts extends Component {
  static propTypes = {
    activeContractsStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    atheneumContactStore: PropTypes.object,
  }

  componentDidMount() {
    const { activeContractsStore } = this.props
    activeContractsStore.loadActiveContracts()
  }

  renderContractCoverage = (contract) => {
    const { staticData } = this.props.staticDataStore
    const { contractCoverages } = staticData.names
    const { GLOBAL } = staticData.CONST.CONTRACT_COVERAGES
    const contractCoverageId = contract.contractCoverageId
    const coverage = contractCoverages[contractCoverageId]
    if (contractCoverageId === GLOBAL) {
      return `${coverage} coverage`
    } else {
      return coverage
    }
  }

  render() {
    const { numOfContracts, page, perPage, items, initialized, error } = this.props.activeContractsStore

    if (!initialized) return <Spinner />
    if (error) return <Error error={error} />

    const { staticData } = this.props.staticDataStore
    const { atheneumContact } = this.props.atheneumContactStore
    const { contractTypes } = staticData.names

    return (
      <div>
        <div className="container">
          <div className="sections-one">
            {items.length === 0 ? (
              <NoContent item="active contracts" />
            ) : (
              items.map((contract) => (
                <div key={contract.id} className="sections-one__section">
                  <div className="sections-one__link-container">
                    <div className="sections-one__section__title">
                      <NavLink className="sections-one__link" to={`/contracts/${contract.id}`}>
                        {contract.contractReference}
                      </NavLink>
                      <StatusLabel statusId={contract.contractStatusId} statusesType="contractStatuses" />
                    </div>
                    <div className="sub-header__account">{contractTypes[contract.contractTypeId]}</div>
                  </div>
                  <div className="info-wrapper__row">
                    {`${formatDate(contract.startDate)} - ${formatDate(contract.endDate)}`}
                  </div>
                  <div className="info-wrapper__row">{this.renderContractCoverage(contract)}</div>
                </div>
              ))
            )}
            <Pagination
              total={numOfContracts}
              perPage={perPage}
              page={page}
              onChange={this.props.activeContractsStore.changePage}
            />
          </div>
          <div className="sections-two">
            <div className="sections-one__section info-wrapper__row m-b-lg">
              <NavLink exact to="/project">
                <li className="sections-two__link">Create a new project</li>
              </NavLink>
              <div>
                If you have any questions please reach us at{' '}
                <a className="atheneum-contacts__link" href="mailto: request@atheneum-partners.com">
                  request@atheneum-partners.com
                </a>
              </div>
            </div>
            {atheneumContact && atheneumContact.status ? (
              <div>
                <AtheneumContacts contact={atheneumContact} staticData={staticData} />
                <hr />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default inject('activeContractsStore', 'staticDataStore', 'atheneumContactStore')(observer(ActiveContracts))
