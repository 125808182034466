import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import SidePanel from './SidePanel'
import Logo from './SidePanel/logo.js'
import { Input } from '@components/Form'
import InfoBox from '@components/InfoBox'
import WarningIcon from '@components/Icons/Warning'
import Button from '@components/Button'

class ForgotPassword extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    history: PropTypes.object,
  }

  constructor() {
    super()
    extendObservable(this, {
      error: null,
    })
  }

  submitForm = (values, actions) => {
    const { userStore, history } = this.props
    userStore
      .forgotPassword(values)
      .then(() => {
        history.push('/auth/forgot-password-success')
      })
      .catch(({ message }) => {
        this.error = message
        actions.setSubmitting(false)
      })
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={getValidationSchema()}
          onSubmit={this.submitForm}
          render={(form) => (
            <div className="auth">
              <SidePanel />
              <div className="form-login">
                <Logo className="auth-form-logo" />
                <h1 className="form-login__title">Forgot your password?</h1>
                {this.error ? (
                  <InfoBox theme="red" icon={<WarningIcon />}>
                    {this.error}
                  </InfoBox>
                ) : null}
                <Form className="form-login__form" onSubmit={form.handleSubmit}>
                  <Field
                    isLogin
                    className="m-t-md"
                    name="email"
                    label="Email address"
                    autoComplete="email"
                    component={Input}
                  />
                  <div className="m-t-lg">
                    <Button submit primary onClick={this.onSubmit} disabled={form.isSubmitting}>
                      SEND TOKEN
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

export default inject('userStore')(observer(ForgotPassword))

function getValidationSchema() {
  return yup.object().shape({
    email: yup.string().email('Email format is invalid').required('Email is required'),
  })
}
