"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestEpdConfirmation = exports.updateCpd = exports.getCpd = exports.batchUpdatePaymentStatus = exports.searchExpertPayments = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function searchExpertPayments(query) {
    return api_1.default.post('/api/report/expert-payment', query).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.searchExpertPayments = searchExpertPayments;
function batchUpdatePaymentStatus(ids, status) {
    return api_1.default
        .put('/api/consultation-payment-detail/batch-status', { ids: ids, status: status })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.batchUpdatePaymentStatus = batchUpdatePaymentStatus;
function getCpd(cpdId) {
    return api_1.default.get("/api/consultation-payment-detail/".concat(cpdId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getCpd = getCpd;
function updateCpd(cpdId, data) {
    return api_1.default.put("/api/consultation-payment-detail/".concat(cpdId), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateCpd = updateCpd;
function requestEpdConfirmation(epd, feeId) {
    return api_1.default
        .put("/api/expert-payment-detail/".concat(epd.expertId, "/request-details"), tslib_1.__assign(tslib_1.__assign({}, epd), { feeId: feeId }))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.requestEpdConfirmation = requestEpdConfirmation;
var api = {
    searchExpertPayments: searchExpertPayments,
    batchUpdatePaymentStatus: batchUpdatePaymentStatus,
    getCpd: getCpd,
    updateCpd: updateCpd,
    requestEpdConfirmation: requestEpdConfirmation,
};
exports.default = api;
