"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeamUnits = exports.getFeeCurrencyRates = exports.getFeeInvoicesAndCreditNotes = exports.getGeneralFees = exports.getContractFeesCount = exports.getContractFees = exports.getUnitValues = exports.deleteFee = exports.saveBackdatingFee = exports.updateFee = exports.createFee = exports.getFee = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/fee';
function getFee(feeId) {
    return api_1.default.get("".concat(base, "/").concat(feeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFee = getFee;
function createFee(feeFormData) {
    var feeData = feeFormData;
    return api_1.default.post(base, feeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createFee = createFee;
function updateFee(feeId, feeFormData) {
    var feeData = feeFormData;
    return api_1.default.put("".concat(base, "/").concat(feeId), feeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateFee = updateFee;
function saveBackdatingFee(feeId, feeData) {
    return api_1.default.put("".concat(base, "/").concat(feeId, "/backdating"), feeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.saveBackdatingFee = saveBackdatingFee;
function deleteFee(feeId) {
    return api_1.default.delete("".concat(base, "/").concat(feeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteFee = deleteFee;
function getUnitValues(feeData) {
    return api_1.default.post('/api/unit-values', feeData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getUnitValues = getUnitValues;
function getContractFees(contractId, page, limit) {
    if (page === void 0) { page = 1; }
    if (limit === void 0) { limit = 10; }
    return api_1.default.get("".concat(base, "/contract/").concat(contractId, "?page=").concat(page, "&limit=").concat(limit)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getContractFees = getContractFees;
function getContractFeesCount(contractId) {
    return api_1.default.get("".concat(base, "/contract/").concat(contractId, "?count")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getContractFeesCount = getContractFeesCount;
function getGeneralFees(data) {
    return api_1.default.post("/api/report/general-fees", data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getGeneralFees = getGeneralFees;
function getFeeInvoicesAndCreditNotes(feeId) {
    return api_1.default.get("".concat(base, "/").concat(feeId, "/invoices-and-credit-notes")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeeInvoicesAndCreditNotes = getFeeInvoicesAndCreditNotes;
function getFeeCurrencyRates(feeIds) {
    return api_1.default.post("".concat(base, "/currency-rates"), feeIds).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeeCurrencyRates = getFeeCurrencyRates;
function getTeamUnits(eteId) {
    return api_1.default.get("".concat(base, "/team-units/").concat(eteId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getTeamUnits = getTeamUnits;
var api = {
    getFee: getFee,
    createFee: createFee,
    updateFee: updateFee,
    deleteFee: deleteFee,
    getContractFees: getContractFees,
    getContractFeesCount: getContractFeesCount,
    getUnitValues: getUnitValues,
    getTeamUnits: getTeamUnits,
    getInvoicesAndCreditNotes: getFeeInvoicesAndCreditNotes,
    saveBackdatingFee: saveBackdatingFee,
};
exports.default = api;
