import React from 'react'
import { Chat, ChatList } from './'
import { Provider as ReduxProvider, useDispatch } from 'react-redux'
import reduxStore from './redux/store'
import { useGetChatRoomsQuery } from './redux/reducers/apiSlice'
import { toggleChatListOpen } from './redux/reducers/chatSlice'
import Grid from '@material-ui/core/Grid'
import pick from 'lodash/pick'

export const ChatListPage = ({
  projectId,
  user,
  clientOfficeId,
  expertId,
  staticData,
  clientAuth,
}: {
  projectId: number | null
  user: any
  clientOfficeId: number | null
  expertId: number | null
  className?: string
  staticData: any | null
  clientAuth: boolean | null
}) => {
  return (
    <ReduxProvider store={reduxStore}>
      <ChatListPageContainer
        user={pick(user, [
          'id',
          'fullName',
          'picture',
          'userTypeId',
          'permissions',
          'expert',
          'client',
          'employee',
          'currentPosition',
        ])}
        projectId={projectId}
        clientOfficeId={clientOfficeId}
        expertId={expertId}
        staticData={staticData}
        clientAuth={clientAuth}
      />
    </ReduxProvider>
  )
}
const ChatListPageContainer = ({
  projectId,
  user,
  clientOfficeId,
  expertId,
  staticData,
  clientAuth,
}: {
  projectId: number | null
  user: any
  clientOfficeId: number | null
  expertId: number | null
  staticData: any | null
  clientAuth: boolean | null
}) => {
  const { data }: any = useGetChatRoomsQuery({
    userId: user.id,
    meta: { projectId, clientOfficeId, expertId },
  })

  const dispatch = useDispatch()
  dispatch(toggleChatListOpen(false))

  const { unseenExpert, unseenClient } = getUnseenCount(data)

  return (
    <div>
      <div className="chat-list-page">
        {clientAuth && (
          <div className="chat-tabs">
            <Grid container>
              <Grid item className="chat-tab">
                Client
              </Grid>
              <Grid item>{!!unseenClient && <div className="chat-unseen">{unseenClient}</div>}</Grid>
            </Grid>
            <ChatList
              user={user}
              projectId={projectId}
              clientOfficeId={clientOfficeId}
              expertId={expertId}
              className="static"
              context="client"
            />
          </div>
        )}

        <div className="chat-tabs">
          <Grid container>
            <Grid item className="chat-tab">
              Expert
            </Grid>
            <Grid item>{!!unseenExpert && <div className="chat-unseen">{unseenExpert}</div>}</Grid>
          </Grid>
          <ChatList
            user={user}
            projectId={projectId}
            clientOfficeId={clientOfficeId}
            expertId={expertId}
            className="static"
            context="expert"
          />
        </div>

        <div className="chat-container">
          <Chat user={user} staticData={staticData} className="static" />
        </div>
      </div>
    </div>
  )
}

const getUnseenCount = (data: any) => {
  if (!data?.length) return {}
  let unseenExpert = 0
  let unseenClient = 0

  const clientMessageCount = data.reduce((a: number, message: any) => {
    if (message.required[0].value === 'client') {
      return a + message.messages.length
    }
    return a
  }, 0)
  const clientSeenMessageCount = data.reduce((a: number, message: any) => {
    if (message.required[0].value === 'client') {
      return a + message.seens.length
    }
    return a
  }, 0)
  let expertMessageCount = data.reduce((a: number, message: any) => {
    if (message.required[0].value === 'expert') {
      return a + message.messages.length
    }
    return a
  }, 0)
  let expertSeenMessageCount = data.reduce((a: number, message: any) => {
    if (message.required[0].value === 'expert') {
      return a + message.seens.length
    }
    return a
  }, 0)

  unseenClient = clientMessageCount - clientSeenMessageCount
  unseenExpert = expertMessageCount - expertSeenMessageCount
  return { unseenClient, unseenExpert }
}
