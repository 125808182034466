import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Button = (props) => {
  const { disabled, onClick, primary, secondary, submit, small, className, children } = props
  const type = submit ? 'submit' : 'button'
  const classes = classNames({
    [className]: className,
    button: true,
    'button--primary': primary,
    'button--secondary': secondary,
    'button--small': small,
  })

  return (
    <button type={type} disabled={disabled} className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  submit: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Button
