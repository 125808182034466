import { extendObservable } from 'mobx'
import { getSingleContract, getSingleContractProjects } from '@api/client'

class SingleContractStore {
  constructor() {
    extendObservable(this, {
      activeTab: 'Projects',
      singleContract: {},
      contractId: null,
      projects: [],
      page: 1,
      perPage: 5,
      totalNumOfProjects: null,
      loading: false,
      loadingMore: false,
      error: null,
    })
  }

  loadSingleContract(contractId) {
    this.loading = true
    this.contractId = contractId
    Promise.all([getSingleContract(contractId), getSingleContractProjects(contractId, this.page, this.perPage)])
      .then(([contract, projects]) => {
        this.singleContract = contract
        this.projects.replace(projects.rows)
        this.totalNumOfProjects = projects.count
        this.loading = false
      })
      .catch((err) => {
        this.error = err
        this.loading = false
      })
  }

  changePage = (page) => {
    this.loadingMore = true
    getSingleContractProjects(this.contractId, page, this.perPage).then((projects) => {
      this.projects.replace(projects.rows)
      this.page = page
      this.loadingMore = false
    })
  }
}

export default SingleContractStore
