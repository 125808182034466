import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable, toJS } from 'mobx'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { InputMUI, TagsInputMUI, SelectOrInputMUI } from '@components/Form'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { Field } from 'formik'
import getSubIndustryOptions from '@lib/getSubIndustryOptions'
import find from 'lodash/find'

class ProjectDetailsForm extends Component {
  static propTypes = {
    staticDataStore: PropTypes.object,
    projectStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      projectDetails: {
        industries: true,
        seniority: this.props.form.values.seniority?.length || false,
        languages: this.props.form.values.languages?.length || false,
        geography: this.props.form.values.geography?.length || false,
        roles: this.props.form.values.roles?.length || false,
        companies: this.props.form.values.companies?.length || false,
        competitors: this.props.form.values.competitors?.length || false,
        requestedNumberOfCalls: this.props.form.values.requestedNumberOfCalls || false,
      },
      isSubmitting: false,
    })
  }

  handleCheck = (checked, name, form) => {
    const { projectStore } = this.props
    this.projectDetails[name] = checked

    if (!checked) {
      form.setFieldValue(name, null, false)
      if (name === 'requestedNumberOfCalls') {
        projectStore.updateSegment(name, null)
      } else {
        projectStore.updateSegment(name, [])
      }
    }
  }

  handleSubIndustryChange = (value, form) => {
    const { projectStore, initialScope } = this.props
    const { staticData } = this.props.staticDataStore

    if (value.length) {
      form.setFieldValue('industries', value, false)
      projectStore.updateSegment('industries', value)
      const subIndustry = find(staticData.subIndustries, (subIndustry) => value.includes(subIndustry.name))

      if (initialScope && subIndustry && !form.values.subIndustryId) {
        form.setFieldValue('industryId', subIndustry.industryId, false)
        form.setFieldValue('subIndustryId', subIndustry.id, false)
        form.setFieldValue('industryName', '', false)
        projectStore.updateProject('subIndustryId', subIndustry.id)
        projectStore.updateProject('industryId', subIndustry.industryId)
        projectStore.updateProject('industryName', '')
      }

      if (initialScope && !subIndustry) {
        form.setFieldValue('industryId', null, false)
        form.setFieldValue('subIndustryId', null, false)
        projectStore.updateProject('industryId', null)
        projectStore.updateProject('subIndustryId', null)
      }
    } else {
      form.setFieldValue('industries', [], false)
      projectStore.updateSegment('industries', [])
      if (initialScope) {
        form.setFieldValue('industryId', null, false)
        form.setFieldValue('subIndustryId', null, false)
        form.setFieldValue('industryName', '', false)
        projectStore.updateProject('subIndustryId', null)
        projectStore.updateProject('industryId', null)
        projectStore.updateProject('industryName', '')
      }
    }
  }

  onInputChange = (val, field) => {
    const { form, projectStore } = this.props
    const { staticData } = this.props.staticDataStore

    form.setFieldValue(field, val)
    if (field === 'industryName') {
      const industry = find(staticData.industries, (industry) => val === industry.name)
      if (industry) {
        projectStore.updateProject('industryId', industry.id)
      } else {
        projectStore.updateProject('industryId', null, false)
      }

      projectStore.updateProject(field, val)
    } else {
      projectStore.updateSegment(field, val)
    }
  }

  onTagsInputChange = (val, field) => {
    const { form, projectStore } = this.props
    form.setFieldValue(field, val)
    projectStore.updateSegment(field, val)
  }

  handleStringValues = (val, field) => {
    const { form, projectStore } = this.props
    const { updateSegment, segments, activeSegmentIndex } = projectStore

    if (val) {
      const newValues = segments[activeSegmentIndex][field].slice()

      newValues.push(val)

      form.setFieldValue(field, newValues)
      updateSegment(field, newValues)

      if (field === 'industries') {
        this.handleSubIndustryChange(newValues, form)
      }
    }
  }

  render() {
    const { form, fields, projectStore, className, initialScope, isNameTaken } = this.props
    const { project, segments, activeSegmentIndex } = projectStore
    const { staticData } = this.props.staticDataStore
    const isNewScope = className === 'add-scope'
    const title = isNewScope ? 'Create Scope' : 'The Project Details'
    const subtitle = isNewScope
      ? 'Please choose variables for the scope.'
      : 'Please choose details to create the project.'
    const industries = staticData.industries.filter((i) => i.name !== 'Other').map((i) => i.name)

    return (
      <React.Fragment>
        <Typography className={className} component={'div'}>
          <div className="form-title">{title}</div>
          <div className="form-subtitle">{subtitle}</div>
          {className === 'add-scope' && (
            <Grid container item xs={12} spacing={4} className="scope-name">
              <Grid item xs={12} md={6}>
                <Field
                  form={form}
                  name={'segmentName'}
                  label={'Scope name'}
                  variant="standard"
                  component={InputMUI}
                  onChange={(e) => this.onInputChange(e.target.value, 'segmentName')}
                  customError={isNameTaken && 'You are using the same segment title for multiple segments'}
                />
              </Grid>
            </Grid>
          )}
          <div className="project-details">
            {fields.map((field) => (
              <Tooltip title={field.tooltip} key={field.name}>
                <FormControlLabel
                  label={field.label}
                  control={
                    <Checkbox
                      checked={!!this.projectDetails[field.name]}
                      color="primary"
                      disabled={field.disabled}
                      onChange={(e) => this.handleCheck(e.target.checked, field.name, form)}
                    />
                  }
                />
              </Tooltip>
            ))}
          </div>
          <div className="details">
            <Grid container item xs={12} spacing={4}>
              {fields
                .filter((field) => this.projectDetails[field.name])
                .map((field) => {
                  if (field.name === 'requestedNumberOfCalls') {
                    return (
                      <Grid item xs={12} md={6} key={field.name}>
                        <Field
                          form={form}
                          name={field.name}
                          label={field.label}
                          variant="standard"
                          type={field.type}
                          helperText={field.helperText}
                          component={InputMUI}
                          onChange={(e) => this.onInputChange(e.target.value, field.name)}
                        />
                      </Grid>
                    )
                  } else if (field.name === 'industries') {
                    return (
                      <Grid item xs={12} md={6} className="industries" key={field.name}>
                        <Field
                          showAllItemsOnEmpty
                          form={form}
                          name={field.name}
                          label={field.label}
                          variant="standard"
                          skipOptions={staticData.industries.map((i) => i.name)}
                          options={getSubIndustryOptions(staticData)}
                          onChange={(e, value) => this.handleSubIndustryChange(value, form)}
                          onBlur={(e) => this.handleStringValues(e.target.value, field.name)}
                          freeSolo={true}
                          component={TagsInputMUI}
                          disabled
                        />
                        {initialScope &&
                          toJS(segments[activeSegmentIndex].industries).length > 0 &&
                          (project.industryName || (this.props.checkIndustry && !project.industryId)) && (
                            <Field
                              form={form}
                              name="industryName"
                              label="Industry"
                              variant="standard"
                              options={industries}
                              component={SelectOrInputMUI}
                              helperText={'You entered a new subindustry, enter industry name so we can create it.'}
                              onChange={(e) => this.onInputChange(e.target.value, 'industryName')}
                            />
                          )}
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item xs={12} md={6} key={field.name}>
                        <Field
                          form={form}
                          showAllItemsOnEmpty
                          name={field.name}
                          label={field.label}
                          variant="standard"
                          helperText={field.helperText}
                          options={field.options}
                          loadOptions={field.loadOptions}
                          freeSolo={true}
                          component={TagsInputMUI}
                          onChange={(e, val) => this.onTagsInputChange(val, field.name)}
                          onBlur={(e) => this.handleStringValues(e.target.value, field.name)}
                        />
                      </Grid>
                    )
                  }
                })}
            </Grid>
          </div>
          {className === 'add-scope' && (
            <div className="form-footer">
              <div />
              <div>
                <Button
                  className="stepper-button cancel"
                  size="large"
                  color="primary"
                  disabled={this.isSubmitting}
                  onClick={this.props.handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="stepper-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={this.isSubmitting}
                  onClick={this.props.handleCreate}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Typography>
      </React.Fragment>
    )
  }
}

export default withRouter(inject('projectStore', 'staticDataStore')(observer(ProjectDetailsForm)))
