"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParticipationStats = exports.exportParticipantData = exports.updateParticipant = exports.getParticipants = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/survey';
function getParticipants(id, filter) {
    var filterQuery = '';
    if (filter) {
        filterQuery = '?' + new URLSearchParams(filter).toString();
    }
    return api_1.default.get("".concat(base, "/").concat(id, "/participants").concat(filterQuery)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getParticipants = getParticipants;
function updateParticipant(data) {
    return api_1.default.post("".concat(base, "/participant"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateParticipant = updateParticipant;
function exportParticipantData(id, query) {
    return api_1.default.post("".concat(base, "/").concat(id, "/participants/export"), query).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportParticipantData = exportParticipantData;
function getParticipationStats(id) {
    return api_1.default.get("".concat(base, "/").concat(id, "/participation-stats")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getParticipationStats = getParticipationStats;
