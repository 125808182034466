"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRelevanceStatement = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/ai';
function generateRelevanceStatement(_a) {
    var prompt = _a.prompt, message = _a.message;
    return api_1.default
        .post("".concat(base, "/relevance-statement"), {
        prompt: prompt,
        message: message,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.generateRelevanceStatement = generateRelevanceStatement;
