"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSwalError = void 0;
var sweetAlertPopupClosingActions = ['timer', 'cancel', 'close', 'overlay', 'esc'];
/**
 * Returns true if passed error is SweetAlert popup cancelation or closing.
 *
 * @param {string|Object} error can be a Sweetalert error or error object
 * @returns {boolean} is the passed error from Sweetalert or not
 */
function isSwalError(error) {
    return sweetAlertPopupClosingActions.indexOf(error) !== -1;
}
exports.isSwalError = isSwalError;
