"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDepartmentResponsibleUsers = exports.updateContractualDetails = exports.update = exports.getByKey = exports.getAll = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/configuration';
function getAll() {
    return api_1.default.get(base).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAll = getAll;
function getByKey(key) {
    return getAll()
        .then(function (data) { return data.find(function (entry) { return entry.key === key; }); })
        .catch(api_1.errorHandler);
}
exports.getByKey = getByKey;
function update(id, data) {
    return api_1.default.put("".concat(base, "/").concat(id), data).catch(api_1.errorHandler);
}
exports.update = update;
function updateContractualDetails(data) {
    return api_1.default.put("".concat(base, "/contractual-details"), data).catch(api_1.errorHandler);
}
exports.updateContractualDetails = updateContractualDetails;
function updateDepartmentResponsibleUsers(data) {
    return api_1.default.put("".concat(base, "/department-responsible-users"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateDepartmentResponsibleUsers = updateDepartmentResponsibleUsers;
var api = {
    getAll: getAll,
    getByKey: getByKey,
    update: update,
    updateDepartmentResponsibleUsers: updateDepartmentResponsibleUsers,
};
exports.default = api;
