"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportProjectRevenuesReport = exports.exportGeneralFeesReport = exports.exportExpertPayment = exports.exportFinancialRevenue = exports.exportPerformance = exports.exportTeamPerformance = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function exportTeamPerformance(month) {
    return api_1.default.get("/api/report/team-performance-export?month=".concat(month)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportTeamPerformance = exportTeamPerformance;
function exportPerformance(month) {
    return api_1.default.get("/api/report/performance-export?month=".concat(month)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportPerformance = exportPerformance;
function exportFinancialRevenue(query) {
    return api_1.default.post("/api/report/financial-revenue-export", query).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportFinancialRevenue = exportFinancialRevenue;
function exportExpertPayment(query) {
    return api_1.default.get("/api/report/expert-payment/export?".concat(query)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportExpertPayment = exportExpertPayment;
function exportGeneralFeesReport(query) {
    return api_1.default.get("/api/report/export-general-fees?".concat(query)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportGeneralFeesReport = exportGeneralFeesReport;
function exportProjectRevenuesReport(query) {
    return api_1.default.get("/api/project/revenues-report?".concat(query)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportProjectRevenuesReport = exportProjectRevenuesReport;
