import React from 'react'

const ChevronIcon = ({ onClick }) => {
  const classes = 'svg-icon chevron-icon'

  return (
    <div className={classes} onClick={onClick}>
      <svg width="12px" height="8px" viewBox="0 0 12 8">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-331.000000, -1280.000000)" fill="#7E8DA2">
            <g transform="translate(337.000000, 1284.000000) rotate(0.000000) translate(-337.000000, -1284.000000) translate(333.000000, 1278.000000)">
              <polygon id="Shape" points="2 0 0.6 1.4 5.2 6 0.6 10.6 2 12 8 6" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default ChevronIcon
