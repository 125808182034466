"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplianceWarningMessage = exports.displayCIDStatus = exports.CID_STATUS_MAP = exports.CID_STATUS = exports.isCID = exports.isCIDProject = void 0;
var isCIDProject = function (project, _a) {
    var COMPLIANCE_TYPES = _a.COMPLIANCE_TYPES;
    return project.complianceTypeId === COMPLIANCE_TYPES.CID;
};
exports.isCIDProject = isCIDProject;
var isCID = function (epl, CONST) {
    return (0, exports.isCIDProject)(epl.project, CONST);
};
exports.isCID = isCID;
exports.CID_STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending',
    REJECTED: 'rejected',
    MISSING: 'missing',
};
exports.CID_STATUS_MAP = (_a = {},
    _a[exports.CID_STATUS.PENDING] = 'CID Pending',
    _a[exports.CID_STATUS.APPROVED] = 'CID Cleared',
    _a[exports.CID_STATUS.REJECTED] = 'CID Failed',
    _a[exports.CID_STATUS.MISSING] = 'CID Missing',
    _a);
var displayCIDStatus = function (status) {
    if (!status)
        return exports.CID_STATUS_MAP['missing'];
    return exports.CID_STATUS_MAP[status] || '';
};
exports.displayCIDStatus = displayCIDStatus;
exports.ComplianceWarningMessage = 'Are you sure you want to do this? This should normally done by the client and this facility is only to be used in exceptional circumstances (i.e. they have made a mistake and asked you to correct it). All uses of this function are logged and audited.';
