import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Item = ({ children, isActive, isSelected, ...props }) => {
  const classes = classNames({
    autocomplete__item: true,
    'autocomplete__item--active': isActive,
    'autocomplete__item--selected': isSelected,
  })

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  )
}

Item.propTypes = {
  children: PropTypes.any,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
}

export default observer(Item)
