import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import get from 'lodash/get'

const Checkbox = ({ form, field, label, onChange, value, className, disabled }) => {
  let error
  if (form) {
    error = get(form.touched, field.name) && get(form.errors, field.name)
  }

  const groupClasses = classNames({
    'form__input-group': true,
    'form__input-group--error': error,
  })
  const wrapperClasses = classNames({
    'form__input-wrapper': true,
    'form__input-wrapper--error': error,
  })
  const inputClasses = classNames({
    [className]: className,
    'is-disabled': disabled,
    checkbox__wrapper: true,
  })
  let checked = value
  if (form) {
    checked = get(form.values, field.name)
  }

  return (
    <div className={groupClasses}>
      <div className={wrapperClasses}>
        <label className={inputClasses}>
          <input
            type="checkbox"
            className="checkbox"
            checked={checked}
            disabled={disabled}
            {...field}
            onChange={onChange || field.onChange}
          />
          {label}
        </label>
        <div className="form__input-error">{error}</div>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  form: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default observer(Checkbox)
