"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancel = exports.dropIndex = exports.reindex = exports.getState = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getState() {
    return api_1.default.get('/api/elastic/status').then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getState = getState;
function reindex(index) {
    return api_1.default.post('/api/elastic/reindex', { index: index }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.reindex = reindex;
function dropIndex(index) {
    return api_1.default.post('/api/elastic/drop-index', { index: index }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.dropIndex = dropIndex;
function cancel(index) {
    return api_1.default
        .delete('/api/elastic/reindex/' + index)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.cancel = cancel;
