"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDiff = exports.getLogs = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/log';
function getLogs(query) {
    return api_1.default.post("".concat(process.env.REACT_APP_SEARCH_SERVICE_URL, "/log"), query).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getLogs = getLogs;
function getDiff(revisionHash) {
    return api_1.default.get("".concat(base, "/diff/").concat(revisionHash));
}
exports.getDiff = getDiff;
var api = {
    getLogs: getLogs,
};
exports.default = api;
