import React, { Component } from 'react'
import propTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { Dropdown } from '@components/Autocomplete'

class DropdownField extends Component {
  static propTypes = {
    field: propTypes.shape({
      name: propTypes.string,
    }).isRequired,
    form: propTypes.object,
    itemToString: propTypes.func,
    label: propTypes.string,
    linkCallback: propTypes.func,
    linkLabel: propTypes.string,
    loadOptions: propTypes.func,
    onChange: propTypes.func,
  }

  state = { selectedItem: null }

  componentDidMount() {
    this.setDropdownValue()
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props.form
    if (!isEqual(values, prevProps.form.values)) {
      this.setDropdownValue()
    }
  }

  /**
   * @TODO
   *  Try relying on form state instead of copying into internal state
   *  There was probably a reason why this is done this way
   */
  setDropdownValue = () => {
    const { options, form, field } = this.props
    const value = get(form.values, field.name)

    if (value === null || value === undefined) {
      this.setState({ selectedItem: null })
      return
    }
    // if value is primitive, strict comparison
    // otherwise try accessing ID
    // maybe we could fallback to string matching but that's full of pitfalls
    const selectedOption = options.find((option) => {
      if (typeof value === 'object') {
        return value.id === option.id
      } else {
        return value === option || value === option.id
      }
    })

    this.setState({ selectedItem: selectedOption })
  }

  render() {
    const {
      field,
      short,
      form,
      label,
      linkCallback,
      linkLabel,
      options,
      loadOptions,
      onChange,
      itemToString,
      tooltip,
      noError,
      isEpl,
      groupClass,
      wrapperClass,
      labelClass,
      ...props
    } = this.props

    const error = get(form.touched, field.name) && get(form.errors, field.name)
    const value = get(form.values, field.name)

    const groupClasses =
      groupClass ||
      classNames({
        'form__input-group': true,
        'form__input-group--error': error,
      })

    const wrapperClasses =
      wrapperClass ||
      classNames({
        'form__input-wrapper': true,
        'form__input-wrapper--short': short,
        'form__input-wrapper--error': error,
      })

    const labelClasses = labelClass || 'form__label'

    const dropdownProps = {
      className: 'form__input form__input--autocomplete',
      selectedItem: this.state.selectedItem,
      options,
      itemToString,
      onBlur: () => form.setFieldTouched(field.name, true),
      onChange: (option) => {
        if (!onChange) {
          form.setFieldValue(field.name, option ? option.id : null, false)
        } else {
          onChange(option)
        }
        form.setFieldTouched(field.name, true)
        this.setState({ selectedItem: option })
      },
      ...props,
    }

    let errorDisplay

    if (typeof error === 'string') {
      errorDisplay = <div className="form__input-error">{error}</div>
    } else if (error) {
      errorDisplay = Object.values(error).map((message) => (
        <div key={message} className="form__input-error">
          {message}
        </div>
      ))
    } else {
      errorDisplay = null
    }

    const dropdownField = (
      <div className={wrapperClasses}>
        <Dropdown {...dropdownProps} />
        {!noError && errorDisplay}
      </div>
    )

    const shouldShowLink = !!(value && linkCallback && linkLabel)
    const linkButton = shouldShowLink && (
      <div className="form__link">
        <span onClick={linkCallback}>{linkLabel}</span>
      </div>
    )

    if (!label) {
      return dropdownField
    }

    return (
      <div className={groupClasses}>
        <label className={labelClasses}>{label}</label>
        {dropdownField}
        {linkButton}
      </div>
    )
  }
}

export default observer(DropdownField)
