import React, { useEffect } from 'react'
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Spinner from './Spinner'
import { useGetChatRoomsQuery } from './redux/reducers/apiSlice'
import { setActiveRoom, closeChat } from './redux/reducers/chatSlice'
import reduxStore from './redux/store'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { AtheneumIcon } from './'
import pick from 'lodash/pick'

export const ChatList = ({
  projectId,
  user,
  clientOfficeId,
  expertId,
  eplId,
  className,
  context,
}: {
  projectId?: number | null
  user: any
  clientOfficeId?: number | null
  expertId?: number | null
  eplId?: number | null
  className?: string
  context?: string
}) => {
  return (
    <ReduxProvider store={reduxStore}>
      <ChatListContainer
        user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])}
        projectId={projectId}
        clientOfficeId={clientOfficeId}
        expertId={expertId}
        eplId={eplId}
        className={className}
        context={context}
      />
    </ReduxProvider>
  )
}

const ChatListContainer = ({
  user,
  projectId,
  clientOfficeId,
  expertId,
  eplId,
  className,
  context,
}: {
  projectId?: number | null
  user: any
  clientOfficeId?: number | null
  expertId?: number | null
  eplId?: number | null
  className?: string
  context?: string
}) => {
  const meta = { projectId, clientOfficeId, expertId, eplId, context }
  const { data, isFetching, isSuccess }: any = useGetChatRoomsQuery({
    userId: user.id,
    meta: { projectId, clientOfficeId, expertId, eplId, context },
  })
  const dispatch = useDispatch()
  const activeRoom = useSelector((state: any) => state.chat.activeRoom)
  const availableRoom = useSelector((state: any) => state.chat.availableRoom)

  useEffect(() => {
    dispatch(closeChat())
  }, [context, dispatch])

  const renderRoom = (room: any) => {
    const unseen = room?.messages?.length - room?.seens?.length
    const nameClass = unseen ? 'room-unseen' : 'room-seen'
    const lastMessage = room?.messages?.[room?.messages?.length - 1]
    const lastMessageTime = lastMessage?.created_at

    let lastMessageName = lastMessage?.user?.platformUserId === user?.id ? 'You' : lastMessage?.user?.name
    lastMessageName = user?.userTypeId !== 1 && lastMessage?.user?.type === 1 ? 'Atheneum' : lastMessageName

    let avatar = user?.userTypeId !== 1 && lastMessage?.user?.type === 1 ? <AtheneumIcon /> : ('' as string | any)

    if (!avatar && lastMessage?.user?.avatar) {
      avatar = '/uploads/' + lastMessage?.user?.avatar
    }

    const timeFormat =
      moment?.(lastMessageTime)?.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'HH:mm' : 'MMM D'
    const message =
      lastMessage?.message?.length > 57 ? lastMessage?.message.substring(0, 57) + '...' : lastMessage?.message
    return (
      <div
        className={activeRoom?.id === room?.id ? 'room-row active' : 'room-row'}
        key={room?.id}
        onClick={() => dispatch(setActiveRoom({ room, className }))}
      >
        <div className="room-name">
          <div className={nameClass}>
            <div>{room?.name}</div>
            <div className="last-message">
              <Avatar src={avatar} className="picture">
                {avatar || <AccountCircleIcon className="default-profile" />}
              </Avatar>
              <div>
                {!!room?.messages?.length && (
                  <div>
                    {lastMessageName}: {message}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!!unseen && <div className="chat-unseen">{unseen}</div>}
        </div>
        {!!lastMessageTime && <div className="time">{moment?.(lastMessageTime).format(timeFormat)}</div>}
      </div>
    )
  }

  return (
    <div>
      {isFetching && !isSuccess && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
      {isSuccess && (
        <div className="chat-list-wrapper">
          {!!data.length && data.map(renderRoom)}
          {!data.length && availableRoom && renderRoom(availableRoom)}
          {!data.length && !availableRoom && (
            <div>
              <p className="no-messages">No messages</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
