"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEmployeeTargetMargin = exports.getUnassignedAccountManagers = exports.removeTeam = exports.removeEmployee = exports.assignEmployee = exports.update = exports.create = exports.getTeamMembersByTeam = exports.getTeamMembers = exports.getActiveTeams = exports.getTeams = exports.exportEmployees = exports.getAllEmployees = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getAllEmployees(year, month) {
    return api_1.default.get("/api/team/employees/".concat(year, "/").concat(month)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAllEmployees = getAllEmployees;
function exportEmployees(year, month) {
    return api_1.default.get("/api/team/employees/".concat(year, "/").concat(month, "/export")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportEmployees = exportEmployees;
function getTeams() {
    return api_1.default.get("/api/team").then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getTeams = getTeams;
function getActiveTeams() {
    return api_1.default.get("/api/team/active").then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getActiveTeams = getActiveTeams;
function getTeamMembers() {
    return api_1.default.get("/api/team/members").then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getTeamMembers = getTeamMembers;
function getTeamMembersByTeam(id) {
    return api_1.default.get("/api/team/members/".concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getTeamMembersByTeam = getTeamMembersByTeam;
function create(data) {
    return api_1.default.post('/api/team', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(id, data) {
    return api_1.default
        .post('/api/team/' + id, data)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.update = update;
function assignEmployee(data) {
    return api_1.default.post('/api/team/assign-employee', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.assignEmployee = assignEmployee;
function removeEmployee(data) {
    return api_1.default.post('/api/team/remove-employee', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.removeEmployee = removeEmployee;
function removeTeam(id, year, month) {
    return api_1.default.delete("/api/team/".concat(id, "/").concat(year, "/").concat(month)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.removeTeam = removeTeam;
function getUnassignedAccountManagers(year, month) {
    return api_1.default.get("/api/team/unassigned-account-managers/".concat(year, "/").concat(month)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getUnassignedAccountManagers = getUnassignedAccountManagers;
function updateEmployeeTargetMargin(_a) {
    var effectiveDate = _a.effectiveDate, employeeId = _a.employeeId, targetMargin = _a.targetMargin;
    return api_1.default
        .post('/api/team/target-margin', {
        effectiveDate: effectiveDate,
        employeeId: employeeId,
        targetMargin: targetMargin,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateEmployeeTargetMargin = updateEmployeeTargetMargin;
