import React from 'react'

const CloseIcon = (props) => {
  return (
    <div className="svg-icon close-icon" {...props}>
      <svg width="14px" height="14px" viewBox="0 0 14 14">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="#FFFFFF">
            <g>
              <polygon points="14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default CloseIcon
