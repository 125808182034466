import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import InfoIcon from '../../Icon/Info'
import Tooltip from '../Tooltip'

const Checkbox = (props) => {
  const { onChange, disabled, tooltip, value, label, className, onFocus, onBlur, noLeftMargin, inlineStyle } = props
  const classes = classNames({
    [className]: className,
    'checkbox__wrapper--disabled': disabled,
    checkbox__wrapper: true,
  })

  let tooltipEl = null
  if (tooltip) {
    tooltipEl = (
      <Tooltip tooltip={tooltip} placement="top">
        <div className="iconWrapper">
          <InfoIcon />
        </div>
      </Tooltip>
    )
  }

  return (
    <label className={classes}>
      <input
        style={inlineStyle ? inlineStyle : {}}
        disabled={disabled}
        type="checkbox"
        className={`checkbox ${noLeftMargin ? 'checkbox__no-left-margin' : ''}`}
        checked={value}
        onChange={(e) => onChange && onChange(e.target.checked)}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {label} {tooltipEl}
    </label>
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noLeftMargin: PropTypes.bool,
}

export { Checkbox }
export default observer(Checkbox)
