"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldHideExpertInfo = void 0;
/**
 * This is a hardcoded version of cascading settings before they are implemented
 * @returns {boolean} - false, except for special cases
 */
function shouldHideExpertInfo(project) {
    var accountId = project.accountId, officeId = project.officeId, parentAccountId = project.parentAccountId;
    if (!accountId || !officeId || !parentAccountId) {
        return null;
    }
    // Company we care about is Trinity with contractReference "TRN-008-PAY"
    // Usable identifiers:
    // parentAccountId
    //  https://platform.atheneum-app.com/parent-account/602
    // accountId
    //  US ttps://platform.atheneum-app.com/account/1371
    //  DE https://platform.atheneum-app.com/account/1856
    //  UK https://platform.atheneum-app.com/account/2601
    // officeId
    //  Boston https://platform.atheneum-app.com/office/1884
    //  New York No postcode https://platform.atheneum-app.com/office/1632
    //  New York 10017 https://platform.atheneum-app.com/office/1631
    //  San Francisco https://platform.atheneum-app.com/office/1941
    //  London https://platform.atheneum-app.com/office/3110
    //  München https://platform.atheneum-app.com/office/2215
    //  Philly https://platform.atheneum-app.com/office/2791
    //  New Jersey https://platform.atheneum-app.com/office/1895
    // TODO After cascading settings are implemented, please remove!
    var HIDDEN_OFFICES = [2816, 1884, 1632, 1631, 1941, 3110, 2215, 2791, 1895, 2816];
    var HIDDEN_ACCOUNTS = [1371, 1856, 2601];
    var HIDDEN_PARENT_ACCOUNTS = [602];
    if (HIDDEN_OFFICES.includes(officeId) ||
        HIDDEN_ACCOUNTS.includes(accountId) ||
        HIDDEN_PARENT_ACCOUNTS.includes(parentAccountId)) {
        return true;
    }
    return false;
}
exports.shouldHideExpertInfo = shouldHideExpertInfo;
