import React from 'react'
import moment from 'moment'
import currencyFormatter from 'currency-formatter'
import get from 'lodash/get'

const defaultMonthFormat = 'MMM YYYY'

export function formatDate(date) {
  const format = 'MMM DD, YYYY'
  const noDateString = 'N/A'
  if (!date) return noDateString
  return moment(date).format(format)
}

export function formatMonth(date, format = defaultMonthFormat, noMonthString = '-') {
  if (!date) return noMonthString
  return moment(date).format(format)
}

export function formatNumber(value) {
  return Number(value).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function renderExpertRate({ epl, pricings, currencies, className }) {
  const pricing = epl.pricingId ? pricings[epl.pricingId] : ''

  if (epl.credits) {
    return (
      <div className={className || 'info-wrapper__row'}>
        {epl.credits} credit(s) {pricing}
      </div>
    )
  }

  if (epl.fee) {
    const rate = renderMoney(currencies, epl.feeCurrencyId, epl.fee)
    return (
      <div className={className || 'info-wrapper__row'}>
        {rate} {pricing}
      </div>
    )
  }

  return ''
}

export function renderMoney(currencies, currencyId, amount) {
  const formatted = currencyFormatter.format(amount, {
    code: currencies[currencyId],
    locale: 'de-DE',
  })
  return `${formatted}`
}

export function renderExperience(experience) {
  if (!experience) return ''

  const startDateString = experience.startDate ? formatMonth(experience.startDate) : ''
  const endDateString = experience.endDate ? formatMonth(experience.endDate) : 'Now'
  let dates
  if (startDateString && endDateString) {
    dates = `(${startDateString} - ${endDateString})`
  } else if (endDateString) {
    dates = `(${endDateString})`
  } else {
    dates = ''
  }

  const companyName = get(experience, 'company.name', '')

  return `${companyName} ${dates}`
}
