import React from 'react'
import propTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Field, FieldArray } from 'formik'
import get from 'lodash/get'

import RepeatableAction from './RepeatableAction'
import Input from './Input'

const EmailFields = ({
  form,
  primaryEmail,
  additionalEmails,
  emailNotRepeating,
  primaryEmailIsDisabled,
  label,
  inputProps,
}) => {
  const emails = get(form.values, additionalEmails)
  const emailValue = get(form.values, primaryEmail)

  return (
    <div className="form__input-group">
      <label className="form__label">{label}</label>
      <div className="form__input-wrapper">
        <FieldArray
          name={additionalEmails}
          render={(arrayHelpers) => (
            <div className="form__repeatable-input-wrapper">
              <div className="form__repeatable-input" key={0}>
                <Field
                  name={primaryEmail}
                  placeholder=""
                  component={Input}
                  value={emailValue}
                  disabled
                  {...inputProps}
                />
                <RepeatableAction
                  allowAdd={emails.length === 0}
                  allowDelete={false}
                  arrayHelpers={arrayHelpers}
                  initialObject={{ email: '' }}
                />
              </div>
              {emails.map((emailInfo, index) => (
                <div className="form__repeatable-input" key={index}>
                  <Field
                    name={`${additionalEmails}[${index}].email`}
                    placeholder=""
                    component={Input}
                    value={emails[index].email}
                    {...inputProps}
                  />
                  <RepeatableAction
                    fieldValues={emails}
                    allowDelete={emails.length > 0}
                    arrayHelpers={arrayHelpers}
                    index={index}
                    initialObject={{ email: '' }}
                  />
                </div>
              ))}
            </div>
          )}
        />
        {emailNotRepeating ? <div className="form__input-error">{emailNotRepeating}</div> : null}
      </div>
    </div>
  )
}

EmailFields.propTypes = {
  form: propTypes.object,
  primaryEmail: propTypes.string,
  additionalEmails: propTypes.oneOfType([propTypes.string, propTypes.array]),
  emailNotRepeating: propTypes.string,
  email: propTypes.string,
  label: propTypes.string,
  primaryEmailIsDisabled: propTypes.bool,
  inputProps: propTypes.object,
  repeatableActionProps: propTypes.object,
}

export default observer(EmailFields)
