import axios from 'axios'
import axiosDefaults from 'axios/lib/defaults'
import get from 'lodash/get'
import moment from 'moment'
import tokenStorage from '@lib/tokenStorage'

const SIXTY_SECONDS = 60000

const clientApi = axios.create({
  timeout: SIXTY_SECONDS,
  responseType: 'json',
  transformRequest: [
    ...axiosDefaults.transformRequest,
    function (data, headers) {
      const token = tokenStorage.get()
      if (token) {
        headers.Authorization = token
      }
      headers.utcoffset = moment().utcOffset() / 60
      return data
    },
  ],
})

// unwrap the response data for all requests, no need to do it manually every time
clientApi.interceptors.response.use(
  function (response) {
    return extractData(response)
  },
  function (error) {
    let cleanError = (error.response && error.response.data) || error
    handleInvalidToken(cleanError)
    const errMsg = extractError(error)
    return Promise.reject(errMsg)
  },
)

export default clientApi

export function errorHandler(errorResponse) {
  const error = get(errorResponse, 'response.data', errorResponse)
  throw error
}

export function extractData(response) {
  if (response.data === undefined) return response
  return response.data
}

export function extractError(errorResponse) {
  const error = get(errorResponse, 'response.data', errorResponse)
  return error
}

// If we ever get the invalid token error handle it here by removing the token
function handleInvalidToken(error) {
  if (error && error.statusCode === 401 && error.message === 'Invalid token format') {
    tokenStorage.clear()
  }
}
