import React, { Component } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import InfoWrapperRow from '@components/InfoWrapper'
import Edit from '@components/Icons/Edit'
import AtheneumContacts from '@components/AtheneumContacts'
import ACL from '@components/ACL'

class ClientAccount extends Component {
  static propTypes = {
    staticDataStore: PropTypes.object,
    modalStore: PropTypes.object,
    userStore: PropTypes.object,
    atheneumContactStore: PropTypes.object,
  }

  renderContactInformation = (staticData, user) => {
    const { modalStore } = this.props
    const address = user.get('address')
    return (
      <div className="sections-one">
        <h2 className="sections-one__title">
          Contact information
          <Edit
            className="sections-one__action"
            onClick={(e) => {
              return modalStore.show({
                type: 'EDIT_ACCOUNT',
              })
            }}
          />
        </h2>
        <div className="sections-one__section">
          {address.phones.length > 0 &&
            address.phones.map((phone) => {
              return (
                <InfoWrapperRow
                  key={phone.id}
                  label={`Phone (${staticData.names.phoneTypes[phone.phoneTypeId]})`}
                  text={phone.phoneNum}
                />
              )
            })}
          <InfoWrapperRow label="Email address" text={user.get('email')} />
          {address.emails.length > 0 &&
            address.emails.map((email) => {
              return <InfoWrapperRow key={email.id} text={email.email} />
            })}
          <InfoWrapperRow label="Address" text={address.address1} />
          <InfoWrapperRow label="Secondary address" text={address.address2} />
          <InfoWrapperRow label="City" text={address.city} />
          <InfoWrapperRow label="Postal code" text={address.postalCode} />
          <InfoWrapperRow label="State/Province" text={address.state} />
        </div>
      </div>
    )
  }

  render() {
    const { user } = this.props.userStore
    const { staticData } = this.props.staticDataStore

    const kmSpecificAccounts = user.get('kmSpecificAccounts')
    const client = user.get('client')
    const { atheneumContact } = this.props.atheneumContactStore

    return (
      <div className="container">
        {this.renderContactInformation(staticData, user)}
        <div className="sections-two">
          <div className="sections-one__section info-wrapper__row m-b-lg">
            <NavLink exact to="/project">
              <li className="sections-two__link">Create a new project</li>
            </NavLink>
            <div>
              If you have any questions please reach us at{' '}
              <a className="atheneum-contacts__link" href="mailto: request@atheneum-partners.com">
                request@atheneum-partners.com
              </a>
            </div>
          </div>
          {atheneumContact && atheneumContact.status ? (
            <div>
              <AtheneumContacts contact={atheneumContact} staticData={staticData} />
              <hr />
            </div>
          ) : (
            ''
          )}
          <ACL isAllowed={client.knowledgeManager}>
            <div className="m-b-lg">
              <h2 className="sections-one__title">In charge of</h2>
              {client.global ? (
                <div>
                  <div className="info-wrapper__row">
                    All accounts under {client.account.parentAccount.parentAccountName}
                  </div>
                </div>
              ) : (
                kmSpecificAccounts.map((account) => {
                  return (
                    <div key={account.id} className="info-wrapper__row">
                      {account.companyName}
                    </div>
                  )
                })
              )}
            </div>
          </ACL>
          <ACL isAllowed={client.complianceAuditor}>
            <div>
              <h2 className="sections-one__title">Compliance auditor for</h2>
              <div className="info-wrapper__row">All projects under {client.account.companyName}</div>
            </div>
          </ACL>
        </div>
      </div>
    )
  }
}

export default withRouter(
  inject('staticDataStore', 'modalStore', 'userStore', 'atheneumContactStore')(observer(ClientAccount)),
)
