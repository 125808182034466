import React from 'react'
import PropTypes from 'prop-types'

const Download = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg width="16px" height="16px" viewBox="0 0 21 20">
        <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-344.000000, -234.000000)" id="download" className="icon__fill" fill="white">
            <g transform="translate(344.376880, 234.000000)">
              <path
                d="M14,6 L10,6 L10,0 L4,0 L4,6 L0,6 L7,13 L14,6 L14,6 Z M0,15 L0,17 L14,17 L14,15 L0,15 L0,15 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

Download.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Download
