import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SelectOrInput = ({ field, form, variant, label, options, className, onChange }) => {
  const error = get(form.errors, field.name)
  const fieldValue = get(form.values, field.name)

  return (
    <div className={className}>
      {!!options.length && (
        <Autocomplete
          freeSolo
          value={fieldValue}
          options={options}
          onSelect={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-field',
              }}
              autoComplete="off"
              error={error}
              helperText={error}
            />
          )}
        />
      )}
    </div>
  )
}

SelectOrInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  variant: PropTypes.string,
}

export default observer(SelectOrInput)
