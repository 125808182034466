"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendLastReminderToEmployees = exports.announceDeployment = exports.updateToSeen = exports.update = exports.getNotificationTypes = exports.getCount = exports.get = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var baseUrl = '/api/notification';
function get(limit, offset) {
    var fetchUrl = baseUrl;
    if (limit) {
        fetchUrl = "".concat(baseUrl, "?limit=").concat(limit, "&offset=").concat(offset);
    }
    return api_1.default.get(fetchUrl).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.get = get;
function getCount() {
    return api_1.default.get("".concat(baseUrl, "/count")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getCount = getCount;
function getNotificationTypes() {
    return api_1.default.get("".concat(baseUrl, "/notification-types")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getNotificationTypes = getNotificationTypes;
function update(notificationId, data) {
    return api_1.default.put("".concat(baseUrl, "/").concat(notificationId), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.update = update;
function updateToSeen() {
    return api_1.default.put("".concat(baseUrl, "/seen")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateToSeen = updateToSeen;
function announceDeployment(data) {
    return api_1.default.post("".concat(baseUrl, "/announce-deployment"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.announceDeployment = announceDeployment;
function sendLastReminderToEmployees() {
    return api_1.default.post("".concat(baseUrl, "/deployment-last-reminder")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendLastReminderToEmployees = sendLastReminderToEmployees;
var api = {
    get: get,
    getCount: getCount,
    getNotificationTypes: getNotificationTypes,
    update: update,
    updateToSeen: updateToSeen,
    announceDeployment: announceDeployment,
};
exports.default = api;
