"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubIndustryTagOptions = void 0;
function getSubIndustryTagOptions(staticData) {
    return staticData.industries.reduce(function (industryOpts, industry) {
        var subindustries = staticData.subIndustries.filter(function (si) { return si.industryId === industry.id; });
        var industryObject = {
            id: industry.id,
            type: 'industry',
            disabled: true,
            value: industry.name,
            label: industry.name,
            className: 'select-parent',
            children: subindustries.map(function (si) { return si.name; }).join(' '),
        };
        var subindustryOptions = subindustries.map(function (si) {
            return {
                id: si.id,
                type: 'subIndustry',
                value: si.name,
                label: si.name,
                industryId: si.industryId,
                className: 'select-child',
            };
        });
        return industryOpts.concat(industryObject).concat(subindustryOptions);
    }, []);
}
exports.getSubIndustryTagOptions = getSubIndustryTagOptions;
