"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeeTypes = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/unit-values';
function getFeeTypes() {
    return api_1.default.get("".concat(base, "/fee-type")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeeTypes = getFeeTypes;
var api = {
    getFeeTypes: getFeeTypes,
};
exports.default = api;
