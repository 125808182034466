import React from 'react'

export function RobotIcon({ className }: { className?: string | null | undefined }) {
  return (
    <div className={className || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="70" viewBox="0 0 82 64">
        <defs>
          <clipPath id="clip-path">
            <path fill="none" d="M0 0H110.87V118.815H0z" data-name="Rectangle 1255"></path>
          </clipPath>
          <clipPath id="clip-path-2">
            <path
              fill="none"
              d="M44.8 26.062c-1.463 4.56 1.238 13.566 1.238 13.566l9.011-15.321a16.086 16.086 0 00-7.519-3.423s-1.267.619-2.73 5.178"
              data-name="Path 9447"
              transform="translate(-44.37 -20.884)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="-1.96"
            x2="-1.914"
            y1="5.736"
            y2="5.736"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#b9acf4"></stop>
            <stop offset="1" stopColor="#e1d1f5"></stop>
          </linearGradient>
          <clipPath id="clip-path-3">
            <path
              fill="none"
              d="M47.257 23.157l3.893 9.39.982-1.016c.134-7.506-3.27-10.647-3.27-10.647a6.809 6.809 0 00-1.6 2.273"
              data-name="Path 9448"
              transform="translate(-47.257 -20.884)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-2"
            x1="-5.101"
            x2="-4.993"
            y1="9.219"
            y2="9.219"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#be9dea"></stop>
            <stop offset="1" stopColor="#9e8cef"></stop>
          </linearGradient>
          <clipPath id="clip-path-4">
            <path
              fill="none"
              d="M115.893 24.307l9.007 15.321s2.7-9.006 1.238-13.566-2.73-5.178-2.73-5.178a16.089 16.089 0 00-7.519 3.423"
              data-name="Path 9449"
              transform="translate(-115.893 -20.884)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-3"
            x1="-5.349"
            x2="-5.302"
            y1="5.736"
            y2="5.736"
            xlinkHref="#linear-gradient"
          ></linearGradient>
          <clipPath id="clip-path-5">
            <path
              fill="none"
              d="M126.956 20.883a14.615 14.615 0 00-3.349 9.372l1.3.816 3.771-7.806s-1.217-2.383-1.7-2.383h-.02"
              data-name="Path 9450"
              transform="translate(-123.607 -20.881)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-4"
            x1="-13.189"
            x2="-13.082"
            y1="10.553"
            y2="10.553"
            xlinkHref="#linear-gradient-2"
          ></linearGradient>
          <clipPath id="clip-path-6">
            <path
              fill="none"
              d="M125.68 31.718l5.117 9.847a11.121 11.121 0 002.436-10.265c-1.52-6.417-5.192-8.148-5.192-8.148-3.251 3.631-2.361 8.57-2.361 8.57"
              data-name="Path 9451"
              transform="translate(-125.569 -23.148)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-5"
            x1="-6.735"
            x2="-6.68"
            y1="5.772"
            y2="5.772"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#45ffcd"></stop>
            <stop offset="1" stopColor="#22e0ff"></stop>
          </linearGradient>
          <clipPath id="clip-path-7">
            <path
              fill="none"
              d="M39.984 31.3a11.121 11.121 0 002.437 10.269l5.116-9.847s.89-4.939-2.36-8.57c0 0-3.673 1.731-5.193 8.148"
              data-name="Path 9452"
              transform="translate(-39.636 -23.148)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-6"
            x1="-2.033"
            x2="-1.979"
            y1="5.772"
            y2="5.772"
            xlinkHref="#linear-gradient-5"
          ></linearGradient>
          <clipPath id="clip-path-8">
            <path
              fill="none"
              d="M44.455 35.346c-2.9 7.731-3.23 16.132-.218 21.719 4.616 8.556 40.075 9.34 47.619-1.239 2.536-3.556 2.794-9.193 1.288-15.115C90.166 29.009 80.287 16.2 67.426 16.2c-10.926 0-19.166 9-22.972 19.149"
              data-name="Path 9453"
              transform="translate(-42.123 -16.197)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-7"
            x1="-0.36"
            x2="-0.351"
            y1="1.934"
            y2="1.934"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#cbc1f7"></stop>
            <stop offset="1" stopColor="#e8dcf7"></stop>
          </linearGradient>
          <clipPath id="clip-path-10">
            <path
              fill="none"
              d="M70.407 19.294c-15.445.927-23.022 15.451-20.255 27.254 1.689 7.2 32.855 7.989 40.51 1.46 7.492-6.39-2.909-28.742-19.184-28.745-.356 0-.71.01-1.071.032"
              data-name="Path 9455"
              transform="translate(-49.59 -19.262)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-8"
            x1="-0.485"
            x2="-0.474"
            y1="3.072"
            y2="3.072"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#0665bd"></stop>
            <stop offset="1" stopColor="#1a1f77"></stop>
          </linearGradient>
          <clipPath id="clip-path-12">
            <path fill="none" d="M0 0H51.948V28.281H0z" data-name="Rectangle 1266"></path>
          </clipPath>
          <clipPath id="clip-path-13">
            <path
              fill="none"
              d="M91.856 72.1c-7.545 10.579-43 9.794-47.619 1.239-3.012-5.588-2.682-13.988.218-21.72-.124.455-3.1 11.292-.062 16.06 3.1 4.875 15.374 4.2 28.547 3.636s17.53-2.477 19.741-6.194c1.742-2.923-.009-9.376-.834-11.987l1.292 3.855c1.51 5.911 1.252 11.552-1.283 15.111"
              data-name="Path 9456"
              transform="translate(-42.123 -51.612)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-9"
            x1="-0.409"
            x2="-0.399"
            y1="2.927"
            y2="2.927"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#9d68c2"></stop>
            <stop offset="1" stopColor="#ccedff"></stop>
          </linearGradient>
          <clipPath id="clip-path-14">
            <path
              fill="none"
              d="M58.734 91.468c-2.277.418-3.726.931-3.81 1.515-.138.958.807 1.89 2.758 2.669 2.973 1.19 8.284 2.018 15.655 2.028 6.658 0 19.091-2.308 18.4-4.7a1 1 0 00-.21-.365 5.292 5.292 0 00-2.607-1.176l-.063-.014a16.699 16.699 0 00-.334-.081 27.2 27.2 0 00-1.778-.325A83.282 83.282 0 0075.1 90.34a103.841 103.841 0 00-16.37 1.128"
              data-name="Path 9457"
              transform="translate(-54.911 -90.34)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-10"
            x1="-0.785"
            x2="-0.771"
            y1="9.529"
            y2="9.529"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#8879ea"></stop>
            <stop offset="1" stopColor="#9e8cef"></stop>
          </linearGradient>
          <clipPath id="clip-path-16">
            <path fill="none" d="M0 0H36.746V5.312H0z" data-name="Rectangle 1270"></path>
          </clipPath>
          <clipPath id="clip-path-17">
            <path
              fill="none"
              d="M91.652 92.395a.847.847 0 01-.125.223c-.722 1.021-3.649 2.335-3.649 2.335s-11.252-4.6-20.374-2.558-9.817 3.257-9.817 3.257c-1.951-.78-2.9-1.711-2.758-2.669.085-.584 1.533-1.1 3.81-1.515 6.693-1.212 20.547-1.577 28.011-.445.78.138 1.488.276 2.113.405l.062.014c1.769.383 2.816.722 2.731.954"
              data-name="Path 9458"
              transform="translate(-54.911 -90.34)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-11"
            x1="-0.8"
            x2="-0.785"
            y1="12.224"
            y2="12.224"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#8da4e5"></stop>
            <stop offset="1" stopColor="#755cbb"></stop>
          </linearGradient>
          <clipPath id="clip-path-18">
            <path
              fill="none"
              d="M61.984 91.468c1.2 1.622 6.39 2.967 14.928 2.981 4.763 0 13.119-1.435 14.862-3.1a69.813 69.813 0 00-13.4-1.007 104.1 104.1 0 00-16.385 1.128"
              data-name="Path 9459"
              transform="translate(-61.984 -90.34)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-12"
            x1="-5.71"
            x2="-5.641"
            y1="2.758"
            y2="2.758"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#5e36cc"></stop>
            <stop offset="1" stopColor="#6c4a96"></stop>
          </linearGradient>
          <clipPath id="clip-path-20">
            <path fill="none" d="M0 0H12.292V7.453H0z" data-name="Rectangle 1277"></path>
          </clipPath>
          <clipPath id="clip-path-21">
            <path
              fill="none"
              d="M44.514 85.465a14.439 14.439 0 014.435-3.154C51.887 80.9 54.587 79.8 55 78.671s-8.058-.464-8.058-.464l-4.219 1.831a22.845 22.845 0 001.786 5.427"
              data-name="Path 9463"
              transform="translate(-42.728 -78.012)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-13"
            x1="-1.82"
            x2="-1.777"
            y1="9.729"
            y2="9.729"
            xlinkHref="#linear-gradient-11"
          ></linearGradient>
          <clipPath id="clip-path-22">
            <path
              fill="none"
              d="M103.841 35.125c-3.94.45-4.092 6.074-3.546 7.035 1.126 1.984 8.586.221 9.006-.957.539-1.508-1.346-6.107-4.96-6.108a4.4 4.4 0 00-.5.029"
              data-name="Path 9465"
              transform="translate(-100.064 -35.096)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-14"
            x1="-5.329"
            x2="-5.276"
            y1="11.034"
            y2="11.034"
            xlinkHref="#linear-gradient-5"
          ></linearGradient>
          <clipPath id="clip-path-23">
            <path
              fill="none"
              d="M62.189 41.2c.421 1.177 7.881 2.941 9.006.957.546-.962.394-6.586-3.546-7.035a4.4 4.4 0 00-.5-.029c-3.615 0-5.5 4.6-4.961 6.108"
              data-name="Path 9466"
              transform="translate(-62.098 -35.096)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-15"
            x1="-3.282"
            x2="-3.229"
            y1="11.034"
            y2="11.034"
            xlinkHref="#linear-gradient-5"
          ></linearGradient>
          <clipPath id="clip-path-25">
            <path
              fill="none"
              d="M23.2 53.212c-3.489 6.023-2.082 9.364-2.082 9.364 4.137 2.819 16.127.474 16.127.474l-.507-1.689s-14.114 2.45-14.607-2.609c-.591-6.062 9.624-11.626 9.624-11.626 1.429-3.26-.755-4.667-.755-4.667a70.143 70.143 0 00-7.8 10.753"
              data-name="Path 9468"
              transform="translate(-20.833 -42.459)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-16"
            x1="-0.258"
            x2="-0.236"
            y1="4.668"
            y2="4.668"
            xlinkHref="#linear-gradient-2"
          ></linearGradient>
          <clipPath id="clip-path-26">
            <path
              fill="none"
              d="M46.662 74.666c-1.286 1.455 1.443 3.046 3.687 3.29 2.518.274 6.99-.722 6.72-3.258-.146-1.367-2.281-2.26-4.69-2.26a7.658 7.658 0 00-5.716 2.228"
              data-name="Path 9469"
              transform="translate(-46.332 -72.438)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-17"
            x1="-3.089"
            x2="-3.024"
            y1="6.42"
            y2="6.42"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#dd1a8f"></stop>
            <stop offset="1" stopColor="#fe117e"></stop>
          </linearGradient>
          <clipPath id="clip-path-27">
            <path fill="none" d="M0 0H33.964V13.881H0z" data-name="Rectangle 1286"></path>
          </clipPath>
          <clipPath id="clip-path-28">
            <path
              fill="none"
              d="M60.071 29.069c-.389 2.334 4.8 2.233 11.877 2 8.5-.282 17.04 7.036 22.009 1.784.819-.865-7.318-12.229-17.141-11.974S60.408 27.044 60.07 29.07"
              data-name="Path 9470"
              transform="translate(-60.05 -20.873)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-18"
            x1="-0.914"
            x2="-0.899"
            y1="6.165"
            y2="6.165"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0"></stop>
            <stop offset="1" stopColor="#6a9bff"></stop>
          </linearGradient>
          <clipPath id="clip-path-29">
            <path fill="none" d="M0 0H24.136V4.503H0z" data-name="Rectangle 1288"></path>
          </clipPath>
          <clipPath id="clip-path-30">
            <path
              fill="none"
              d="M68.97 72.38c-.449 1.691 3.09 3.383 11.792 2.957 10.52-.515 12.754-2.719 12.237-3.33-.311-.367-3.462 1.618-12.1-.276-5.706-1.251-11.455-1.131-11.927.648"
              data-name="Path 9471"
              transform="translate(-68.932 -70.901)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-19"
            x1="-1.474"
            x2="-1.453"
            y1="15.317"
            y2="15.317"
            xlinkHref="#linear-gradient-18"
          ></linearGradient>
          <clipPath id="clip-path-31">
            <path fill="none" d="M0 0H0.254V0.767H0z" data-name="Rectangle 1290"></path>
          </clipPath>
          <clipPath id="clip-path-32">
            <path
              fill="none"
              d="M133.768 53.352l-.254-.767c.049.147.142.41.254.767"
              data-name="Path 9472"
              transform="translate(-133.514 -52.585)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-20"
            x1="-284.682"
            x2="-282.55"
            y1="117.924"
            y2="117.924"
            xlinkHref="#linear-gradient-9"
          ></linearGradient>
          <clipPath id="clip-path-34">
            <path
              fill="none"
              d="M47.258 75.064c-.076.414 8.911.514 9.123-1.075.1-.75-1.8-1.267-3.887-1.267-2.339 0-4.924.648-5.236 2.342"
              data-name="Path 9489"
              transform="translate(-47.257 -72.722)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-21"
            x1="-2.739"
            x2="-2.681"
            y1="27.839"
            y2="27.839"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#d93de3"></stop>
            <stop offset="1" stopColor="#ff6dba"></stop>
          </linearGradient>
          <clipPath id="clip-path-36">
            <path fill="none" d="M0 0H4.792V5.703H0z" data-name="Rectangle 1319"></path>
          </clipPath>
          <clipPath id="clip-path-37">
            <path
              fill="none"
              d="M131.759 27.546a3.043 3.043 0 00-2.814-1.084c-1.623.392-1.731 5.507-.76 5.633s1.383-2.828 2.127-3.293a3.718 3.718 0 012.045-.284z"
              data-name="Path 9496"
              transform="translate(-127.565 -26.396)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-22"
            x1="-13.341"
            x2="-13.236"
            y1="17.05"
            y2="17.05"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0"></stop>
            <stop offset="1" stopColor="#d4e2ff"></stop>
          </linearGradient>
          <clipPath id="clip-path-38">
            <path fill="none" d="M0 0H5.281V6.365H0z" data-name="Rectangle 1321"></path>
          </clipPath>
          <clipPath id="clip-path-39">
            <path
              fill="none"
              d="M42.531 28.412a2.224 2.224 0 012.034-.479c1.408.23 2.218 3.118 2.939 2.828s.192-4.969-1.711-6.344a14.233 14.233 0 00-3.261 4"
              data-name="Path 9497"
              transform="translate(-42.531 -24.416)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-23"
            x1="-4.039"
            x2="-3.944"
            y1="15.918"
            y2="15.918"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#d4e2ff"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <clipPath id="clip-path-40">
            <path
              fill="none"
              d="M30.338 50.4c.3.4 4.973-5.412 4.674-5.99 0 0-4.978 5.591-4.674 5.99"
              data-name="Path 9498"
              transform="translate(-30.324 -44.412)"
            ></path>
          </clipPath>
          <linearGradient
            id="linear-gradient-24"
            x1="-2.394"
            x2="-2.311"
            y1="17.525"
            y2="17.525"
            xlinkHref="#linear-gradient-7"
          ></linearGradient>
          <clipPath id="clip-Chat_bot_icon">
            <path d="M0 0H82V64H0z"></path>
          </clipPath>
        </defs>
        <g clipPath="url(#clip-Chat_bot_icon)">
          <path fill="transparent" d="M0 0H82V64H0z"></path>
          <g data-name="Group 5137" transform="translate(-7 -7)">
            <g clipPath="url(#clip-path)" data-name="Group 3182" transform="translate(2 7)">
              <path
                fill="#fff"
                d="M48.2 167.032c-.748 0 .389 3.308.778 3.23s-.156-3.23-.778-3.23"
                data-name="Path 9446"
                transform="translate(-22.032 -76.741)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-2)" data-name="Group 3184" transform="translate(25.985 18.289)">
              <path
                fill="url(#linear-gradient)"
                d="M0 0H11.712V18.744H0z"
                data-name="Rectangle 1256"
                transform="translate(-1.029)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-3)" data-name="Group 3186" transform="translate(27.545 18.289)">
              <path fill="url(#linear-gradient-2)" d="M0 0H5.008V11.663H0z" data-name="Rectangle 1257"></path>
            </g>
            <g clipPath="url(#clip-path-4)" data-name="Group 3188" transform="translate(64.648 18.289)">
              <path fill="url(#linear-gradient-3)" d="M0 0H11.713V18.744H0z" data-name="Rectangle 1258"></path>
            </g>
            <g clipPath="url(#clip-path-5)" data-name="Group 3190" transform="translate(68.818 18.288)">
              <path fill="url(#linear-gradient-4)" d="M0 0H5.066V10.19H0z" data-name="Rectangle 1259"></path>
            </g>
            <g clipPath="url(#clip-path-6)" data-name="Group 3192" transform="translate(69.878 19.513)">
              <path
                fill="url(#linear-gradient-5)"
                d="M0 0H9.963V18.417H0z"
                data-name="Rectangle 1260"
                transform="translate(-.779)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-7)" data-name="Group 3194" transform="translate(23.426 19.513)">
              <path
                fill="url(#linear-gradient-6)"
                d="M0 0H9.963V18.417H0z"
                data-name="Rectangle 1261"
                transform="translate(-1.172)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-8)" data-name="Group 3196" transform="translate(24.77 15.755)">
              <path
                fill="url(#linear-gradient-7)"
                d="M0 0H56.474V59.265H0z"
                data-name="Rectangle 1262"
                transform="rotate(-82.841 24.459 28.742)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3198" transform="translate(2 7)">
              <path
                fill="#dfe3fb"
                d="M50.45 48.166c1.689 7.205 32.855 7.99 40.51 1.46S87.592 19.9 70.706 20.913c-15.445.927-23.022 15.451-20.255 27.253"
                data-name="Path 9454"
                transform="translate(-22.921 -9.593)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-10)" data-name="Group 3200" transform="translate(28.807 17.412)">
              <path
                fill="url(#linear-gradient-8)"
                d="M0 0H50.768V35.275H0z"
                data-name="Rectangle 1264"
                transform="translate(-2.205)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3207" transform="translate(2 7)">
              <g
                data-name="Group 3206"
                opacity="0.3"
                transform="translate(22.77 27.9)"
                style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
              >
                <g data-name="Group 3205">
                  <g clipPath="url(#clip-path-12)" data-name="Group 3204">
                    <g data-name="Group 3203">
                      <g clipPath="url(#clip-path-13)" data-name="Group 3202">
                        <path
                          fill="url(#linear-gradient-9)"
                          d="M0 0H53.425V31.064H0z"
                          data-name="Rectangle 1265"
                          transform="translate(-.899)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g clipPath="url(#clip-path-14)" data-name="Group 3209" transform="translate(31.683 55.834)">
              <path
                fill="url(#linear-gradient-10)"
                d="M0 0H37.646V7.344H0z"
                data-name="Rectangle 1268"
                transform="translate(-.125)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3216" transform="translate(2 7)">
              <g
                data-name="Group 3215"
                opacity="0.5"
                transform="translate(29.683 48.835)"
                style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
              >
                <g data-name="Group 3214">
                  <g clipPath="url(#clip-path-16)" data-name="Group 3213">
                    <g data-name="Group 3212">
                      <g clipPath="url(#clip-path-17)" data-name="Group 3211">
                        <path
                          fill="url(#linear-gradient-11)"
                          d="M0 0H36.951V5.762H0z"
                          data-name="Rectangle 1269"
                          transform="translate(-.125 -.45)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g clipPath="url(#clip-path-18)" data-name="Group 3218" transform="translate(35.506 55.834)">
              <path
                fill="url(#linear-gradient-12)"
                d="M0 0H7.794V30.07H0z"
                data-name="Rectangle 1272"
                transform="rotate(-82.841 2.04 2.313)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3231" transform="translate(2 7)">
              <g
                data-name="Group 3230"
                opacity="0.5"
                transform="translate(23.097 42.17)"
                style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
              >
                <g data-name="Group 3229">
                  <g clipPath="url(#clip-path-20)" data-name="Group 3228">
                    <g data-name="Group 3227">
                      <g clipPath="url(#clip-path-21)" data-name="Group 3226">
                        <path
                          fill="url(#linear-gradient-13)"
                          d="M0 0H12.694V7.925H0z"
                          data-name="Rectangle 1276"
                          transform="translate(0 -.471)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g clipPath="url(#clip-path-22)" data-name="Group 3235" transform="translate(56.091 25.972)">
              <path
                fill="url(#linear-gradient-14)"
                d="M0 0H10.091V9.049H0z"
                data-name="Rectangle 1280"
                transform="translate(-.315)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-23)" data-name="Group 3237" transform="translate(35.568 25.972)">
              <path
                fill="url(#linear-gradient-15)"
                d="M0 0H10.091V9.049H0z"
                data-name="Rectangle 1281"
                transform="translate(-.448)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3239" transform="translate(2 7)">
              <path
                fill="#2f95cd"
                d="M75.083 54.647s2.127 2.2 8.156 2.2 9.124-2.533 9.124-2.533-2.657 3.6-9.124 3.6-8.156-3.264-8.156-3.264"
                data-name="Path 9467"
                transform="translate(-34.496 -24.952)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-25)" data-name="Group 3241" transform="translate(13.262 29.952)">
              <path
                fill="url(#linear-gradient-16)"
                d="M0 0H24.942V20.255H0z"
                data-name="Rectangle 1283"
                transform="rotate(-82.841 10.815 13.536)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-26)" data-name="Group 3243" transform="translate(27.045 46.157)">
              <path
                fill="url(#linear-gradient-17)"
                d="M0 0H8.38V12.972H0z"
                data-name="Rectangle 1284"
                transform="rotate(-76.75 2.341 4.163)"
              ></path>
            </g>
            <g data-name="Group 5136" transform="translate(2 7)">
              <g
                data-name="Group 3249"
                opacity="0.5"
                transform="translate(32.461 11.283)"
                style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
              >
                <g data-name="Group 3248">
                  <g clipPath="url(#clip-path-27)" data-name="Group 3247">
                    <g data-name="Group 3246">
                      <g clipPath="url(#clip-path-28)" data-name="Group 3245">
                        <path
                          fill="url(#linear-gradient-18)"
                          d="M0 0H35.094V17.48H0z"
                          data-name="Rectangle 1285"
                          transform="translate(-.368 -.25)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                data-name="Group 3254"
                opacity="0.5"
                transform="translate(37.263 38.327)"
                style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
              >
                <g data-name="Group 3253">
                  <g clipPath="url(#clip-path-29)" data-name="Group 3252">
                    <g data-name="Group 3251">
                      <g clipPath="url(#clip-path-30)" data-name="Group 3250">
                        <path
                          fill="url(#linear-gradient-19)"
                          d="M0 0H24.995V5.282H0z"
                          data-name="Rectangle 1287"
                          transform="translate(-.411 -.42)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                data-name="Group 3259"
                opacity="0.3"
                transform="translate(72.173 28.426)"
                style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
              >
                <g data-name="Group 3258">
                  <g clipPath="url(#clip-path-31)" data-name="Group 3257">
                    <g data-name="Group 3256">
                      <g clipPath="url(#clip-path-32)" data-name="Group 3255">
                        <path fill="url(#linear-gradient-20)" d="M0 0H0.254V0.767H0z" data-name="Rectangle 1289"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3303" transform="translate(2 7)">
              <path
                fill="#fff"
                d="M143.171 59.516c-.34-.04-.225 2.953.2 2.827s.282-2.771-.2-2.827"
                data-name="Path 9488"
                transform="translate(-65.686 -27.343)"
              ></path>
            </g>
            <g clipPath="url(#clip-path-34)" data-name="Group 3305" transform="translate(27.546 46.311)">
              <path
                fill="url(#linear-gradient-21)"
                d="M0 0H9.298V2.856H0z"
                data-name="Rectangle 1311"
                transform="translate(-.076)"
              ></path>
            </g>
            <g clipPath="url(#clip-path)" data-name="Group 3331" transform="translate(2 7)">
              <path
                fill="#fff"
                d="M163.175 11.732q-.057 1.117-.1 2.214t-.056 2.2c.094.007.189.011.285.011h.285c.121 0 .243 0 .37-.005s.252-.009.381-.017q.011-.249.011-.488v-.494q0-.858-.017-1.709t-.056-1.715h1.388q-.1 1.36-.155 2.7t-.054 2.7q0 .666.017 1.325t.045 1.331h-1.287q.028-.671.051-1.337t.045-1.331q-.322-.017-.64-.031t-.635-.015h-.045v1.941q0 .384.011.772h-1.291q.028-.812.036-1.6t.009-1.594q0-2.425-.1-4.856z"
                data-name="Path 9493"
                transform="translate(-74.279 -5.39)"
              ></path>
              <path
                fill="#fff"
                d="M172.364 19.724a16.828 16.828 0 00-.902-.023q-.372 0-.745.014c-.248.009-.5.024-.744.042l.011-1.212c.086.011.173.019.259.025s.172.012.259.02q.034-.875.065-1.74t.031-1.735q0-.581-.017-1.148t-.034-1.142c-.1 0-.2 0-.3.005l-.31.017.011-1.178a28.069 28.069 0 001.743.056h.316c.105 0 .212 0 .322-.011l-.034 1.122c-.087 0-.172 0-.257-.005s-.17-.005-.257-.005q-.079 1.015-.112 2.016t-.034 2.016q0 .885.034 1.765.18 0 .369-.005t.381-.017z"
                data-name="Path 9494"
                transform="translate(-78.076 -5.362)"
              ></path>
              <path
                fill="#fff"
                d="M176.694 19.291a.611.611 0 01-.054.254.7.7 0 01-.141.206.644.644 0 01-.209.138.651.651 0 01-.256.051.616.616 0 01-.251-.051.682.682 0 01-.205-.138.629.629 0 01-.142-.206.644.644 0 01-.05-.254.623.623 0 01.05-.251.671.671 0 01.142-.206.663.663 0 01.205-.141.625.625 0 01.251-.051.661.661 0 01.256.051.627.627 0 01.209.141.752.752 0 01.141.206.59.59 0 01.054.251m.1-7.7q-.158 1.562-.248 3.153t-.107 3.13l-.75.023q-.04-1.551-.1-3.148t-.15-3.147z"
                data-name="Path 9495"
                transform="translate(-80.578 -5.323)"
              ></path>
              <g
                data-name="Group 3325"
                opacity="0.72"
                transform="translate(68.957 14.269)"
                style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
              >
                <g data-name="Group 3324">
                  <g clipPath="url(#clip-path-36)" data-name="Group 3323">
                    <g data-name="Group 3322">
                      <g clipPath="url(#clip-path-37)" data-name="Group 3321">
                        <path
                          fill="url(#linear-gradient-22)"
                          d="M0 0H5.142V6.152H0z"
                          data-name="Rectangle 1318"
                          transform="translate(-.351 -.326)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                data-name="Group 3330"
                opacity="0.72"
                transform="translate(22.991 13.198)"
                style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
              >
                <g data-name="Group 3329">
                  <g clipPath="url(#clip-path-38)" data-name="Group 3328">
                    <g data-name="Group 3327">
                      <g clipPath="url(#clip-path-39)" data-name="Group 3326">
                        <path fill="url(#linear-gradient-23)" d="M0 0H5.693V6.634H0z" data-name="Rectangle 1320"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g clipPath="url(#clip-path-40)" data-name="Group 3333" transform="translate(18.392 31.007)">
              <path
                fill="url(#linear-gradient-24)"
                d="M0 0H6.476V5.381H0z"
                data-name="Rectangle 1323"
                transform="rotate(-89.055 3.05 3.395)"
              ></path>
            </g>
            <path fill="none" d="M0 0H110.87V118.815H0z" data-name="Rectangle 1324" transform="translate(2 7)"></path>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default RobotIcon
