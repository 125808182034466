import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import get from 'lodash/get'

class AutocompleteMUI extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    form: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    helperText: PropTypes.string,
  }

  handleChange = (e, val) => {
    const { form, field } = this.props

    form.setFieldValue(field.name, val)
  }

  render() {
    const { name, options, variant, label, form, field, helperText, onChange } = this.props
    const error = get(form.errors, field.name)
    const fieldValue = get(form.values, field.name)

    return (
      <FormControl fullWidth variant={variant} error={!!error}>
        <Autocomplete
          value={fieldValue}
          options={options}
          name={name}
          onChange={onChange || this.handleChange}
          renderInput={(params) => <TextField {...params} label={label} variant={variant} error={!!error} />}
        />
        {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )
  }
}
export default AutocompleteMUI
