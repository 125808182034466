import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import SidePanel from './SidePanel'
import Logo from './SidePanel/logo.js'
import { Input, Checkbox } from '@components/Form'
import InfoBox from '@components/InfoBox'
import WarningIcon from '@components/Icons/Warning'
import Button from '@components/Button'

class Login extends Component {
  static propTypes = {
    userStore: PropTypes.object,
  }

  constructor() {
    super()
    extendObservable(this, {
      error: null,
    })
  }

  submitForm = (values, actions) => {
    const { userStore } = this.props
    userStore.loginClient(values).catch(({ message }) => {
      this.error = message
      actions.setSubmitting(false)
    })
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            login: '',
            password: '',
            remember: false,
          }}
          validationSchema={getValidationSchema()}
          onSubmit={this.submitForm}
          render={(form) => (
            <div className="auth">
              <SidePanel />
              <div className="form-login">
                <Logo className="auth-form-logo" />
                <h1 className="form-login__title">Log in</h1>
                {this.error ? (
                  <InfoBox theme="red" icon={<WarningIcon />}>
                    {this.error}
                  </InfoBox>
                ) : null}
                <Form className="form-login__form" onSubmit={form.handleSubmit}>
                  <Field isLogin className="m-t-lg" name="login" label="Email" autoComplete="email" component={Input} />
                  <Field
                    isLogin
                    className="m-t-lg"
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    component={Input}
                  />
                  <div className="form__input-wrapper--two_one_children">
                    <Field isLogin name="remember" label="Remember me" component={Checkbox} />
                    <Link className="form-login__link" to="/auth/forgot-password">
                      Forgot password
                    </Link>
                  </div>
                  <div className="m-t-lg">
                    <Button submit primary onClick={this.onSubmit} disabled={form.isSubmitting}>
                      LOG IN
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

export default inject('userStore')(observer(Login))

function getValidationSchema() {
  return yup.object().shape({
    login: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
    remember: yup.boolean(),
  })
}
