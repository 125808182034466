import React from 'react'
import ReactDOM from 'react-dom'

const reactComponentToHtml = async (Cmp: React.JSX.Element): Promise<string> => {
  const div = document.createElement('div')
  return new Promise((resolve) => {
    ReactDOM.render(Cmp, div, () => {
      const htmlString = div.innerHTML
      resolve(htmlString)
    })
  })
}

export default reactComponentToHtml
