import { extendObservable } from 'mobx'
import { getComplianceAuditEpls, getSingleAuditEpl, updateEpl } from '@api/client'
import { sendMetric } from '@atheneum/api/dist/lib/index'

class ComplianceAuditStore {
  constructor(staticDataStore) {
    extendObservable(this, {
      auditEpls: [],
      epl: null,
      page: 1,
      perPage: 6,
      totalNumOfEpls: null,
      groupByProject: false,
      orderBy: 'Recency',
      singleLoading: false,
      loading: false,
      error: null,
      KPMG_PARENT_ACCOUNT_ID: 97,
      staticDataStore,
    })
    this.orderOptions = ['Recency', 'Projects']
  }

  loadAuditEpls() {
    this.loading = true
    getComplianceAuditEpls(this.page, this.perPage, this.orderBy)
      .then((epls) => {
        this.auditEpls.replace(epls.rows)
        this.totalNumOfEpls = epls.count
        this.loading = false
      })
      .catch((err) => {
        this.error = err
        this.loading = false
      })
  }

  loadSingleEpl(eplId) {
    this.singleLoading = true
    getSingleAuditEpl(eplId)
      .then((epl) => {
        this.epl = epl
        this.singleLoading = false
      })
      .catch((err) => {
        this.error = err
        this.singleLoading = false
      })
  }

  updateEplStatus = (epl, { eplStatusId, engagementManager, engagementCode, clientComments }, userName) => {
    let payload = { eplStatusId, engagementManager, engagementCode, clientComments }

    const additionalComment = clientComments ? `: ${clientComments}` : ''
    const { COMPLIANCE_TYPES, EPL_STATUSES } = this.staticDataStore.staticData.CONST

    if (epl.project.complianceRequired && epl.project.complianceTypeId === COMPLIANCE_TYPES.CID) {
      payload = {
        ...payload,
        externalComplianceStatus: eplStatusId === EPL_STATUSES.REJECTED ? 'rejected' : 'approved',
      }
      sendMetric('CIDCompliance.statusChange', 'CID-compliance-client', {
        status: payload.externalComplianceStatus,
        [payload.externalComplianceStatus]: 1,
        interface: 'client-km-interface',
      })
    }

    if (epl.project.complianceRequired && epl.project.complianceTypeId === COMPLIANCE_TYPES.EY) {
      payload = {
        ...payload,
        externalComplianceStatus: eplStatusId === EPL_STATUSES.REJECTED ? 'REJECTED' : 'APPROVED',
      }
      sendMetric('EYCompliance.statusChange', 'EY-compliance-platform', {
        status: payload.externalComplianceStatus,
        interface: 'client-km-interface',
      })
    }

    sendMetric('epl.complianceChange', 'client-km-interface', {
      status: eplStatusId,
      complianceType: epl.project.complianceTypeId,
      externalComplianceStatus: payload.externalComplianceStatus,
      interface: 'client-km-interface',
    })

    if (userName) {
      const reason =
        eplStatusId === EPL_STATUSES.REJECTED
          ? {
              eplRejectionReason: [
                {
                  additionalComment: `Expert has been rejected by Compliance auditor ${userName}${additionalComment}`,
                },
              ],
            }
          : {
              eplAcceptanceReason: [
                {
                  additionalComment: `Expert has been accepted by Compliance auditor ${userName}${additionalComment}`,
                },
              ],
            }
      payload = {
        ...payload,
        ...reason,
      }
    }

    return updateEpl(epl.id, payload)
  }

  groupEplsByProject = () => {
    this.groupByProject = !this.groupByProject
    this.loadAuditEpls()
  }

  orderEplsBy = (option) => {
    this.orderBy = option
    this.loadAuditEpls()
  }

  changePage = (page) => {
    this.page = page
    this.loadAuditEpls()
  }
}

export default ComplianceAuditStore
