import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import SidePanel from './SidePanel'
import Logo from './SidePanel/logo.js'
import { Input } from '@components/Form'
import InfoBox from '@components/InfoBox'
import WarningIcon from '@components/Icons/Warning'
import PasswordStrength from '@components/PasswordStrength'
import Button from '@components/Button'

class ResetPassword extends Component {
  static propTypes = {
    userStore: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    setPassword: PropTypes.bool,
  }

  constructor() {
    super()
    extendObservable(this, {
      error: null,
    })
  }

  submitForm = ({ password }, actions) => {
    const { userStore, match, history } = this.props
    const token = match.params.token
    const formData = { token, password }

    return userStore
      .resetClientPassword(formData)
      .then(() => {
        history.push('/auth/reset-password-success', {
          setPassword: this.props.setPassword,
        })
      })
      .catch(({ message }) => {
        this.error = message
        actions.setSubmitting(false)
      })
  }

  render() {
    const { setPassword } = this.props
    return (
      <div>
        <Formik
          initialValues={{
            password: '',
            passwordRepeat: '',
          }}
          validationSchema={getValidationSchema()}
          onSubmit={this.submitForm}
          render={(form) => (
            <div className="auth">
              <SidePanel />
              <div className="form-login">
                <Logo className="auth-form-logo" />
                <h1 className="form-login__title">{setPassword ? 'Set password' : 'Reset password'}</h1>
                {this.error ? (
                  <InfoBox theme="red" icon={<WarningIcon />}>
                    {this.error}
                  </InfoBox>
                ) : null}
                <Form className="form-login__form" onSubmit={form.handleSubmit}>
                  <Field
                    isLogin
                    className="m-t-md"
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="password"
                    component={Input}
                  />
                  <PasswordStrength password={form.values.password} />
                  <Field
                    isLogin
                    className="m-t-md"
                    name="passwordRepeat"
                    label="Repeat password"
                    type="password"
                    autoComplete="passwordRepeat"
                    component={Input}
                  />
                  <div className="m-t-lg">
                    <Button submit primary onClick={this.onSubmit} disabled={form.isSubmitting}>
                      {setPassword ? 'SET PASSWORD' : 'RESET PASSWORD'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

export default withRouter(inject('userStore')(observer(ResetPassword)))

function getValidationSchema() {
  return yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/(?=.*[0-9].*)(?=.*[a-z].*)/, 'Password must contain at least one letter and number'),
    passwordRepeat: yup
      .string()
      .required('Password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
}
