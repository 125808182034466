"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletePicture = exports.deleteFile = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var base = '/api/file';
function deleteFile(fileId) {
    return api_1.default.delete("".concat(base, "/").concat(fileId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteFile = deleteFile;
function deletePicture(pictureId) {
    return api_1.default.delete("".concat(base, "/").concat(pictureId, "/picture"));
}
exports.deletePicture = deletePicture;
var api = {
    deleteFile: deleteFile,
    deletePicture: deletePicture,
};
exports.default = api;
