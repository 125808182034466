import { extendObservable } from 'mobx'
import { getActiveContracts } from '@api/client'

class ActiveContractsStore {
  constructor(staticDataStore) {
    extendObservable(this, {
      page: 1,
      perPage: 6,
      activeContracts: [],
      numOfContracts: null,
      numOfActiveContracts: null,
      items: [],
      staticDataStore,
      initialized: false,
      error: null,
    })
  }

  loadActiveContracts() {
    if (!this.initialized) {
      return getActiveContracts()
        .then((contracts) => {
          this.numOfContracts = contracts.length
          const { staticData } = this.staticDataStore
          const { ACTIVE, EXPIRING_SOON } = staticData.CONST.CONTRACT_STATUSES
          this.numOfActiveContracts = contracts.filter(
            (contract) => contract.contractStatusId === ACTIVE || contract.contractStatusId === EXPIRING_SOON,
          ).length
          const reorderedItems = contracts.sort(({ contractStatusId }) =>
            [ACTIVE, EXPIRING_SOON].includes(contractStatusId) ? -1 : 0,
          )
          this.activeContracts.replace(reorderedItems)
          this.items.replace(reorderedItems.slice(0, this.perPage))
          this.initialized = true
        })
        .catch((err) => {
          this.initialized = true
          this.error = err
        })
    }
  }

  changePage = (page) => {
    const from = (page - 1) * this.perPage
    const to = page * this.perPage
    this.page = page
    this.items.replace(this.activeContracts.slice(from, to))
  }
}

export default ActiveContractsStore
