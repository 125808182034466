import React from 'react'

import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import TextField from '@material-ui/core/TextField'

const DatePickerMUI = ({ form, field, label, disabled, className, variant, onChange }) => {
  const error = get(form.errors, field.name)
  const fieldValue = get(form.values, field.name)

  return (
    <div className={className}>
      <TextField
        label={label}
        variant={variant}
        disabled={disabled}
        value={fieldValue}
        onChange={onChange}
        name={field.name}
        type="date"
        fullWidth
        error={error}
      />
    </div>
  )
}

DatePickerMUI.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
}

export default observer(DatePickerMUI)
