"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNewTargetedNumberOfExperts = exports.sendGenericLinks = exports.sendIndividualLink = exports.updateCapiProject = exports.getUnresolvedEpls = exports.getZoomMeetings = exports.exportProjectPipeline = exports.exportProjectDeliverables = exports.searchProjects = exports.getLoggedEvents = exports.emailExperts = exports.saveInterviewTarget = exports.getInterviewedExperts = exports.deleteProjectAttachments = exports.getProjectAttachments = exports.getApplicableContracts = exports.getSimilarProjects = exports.typeahead = exports.getFeesByProjectId = exports.typeaheadSearch = exports.searchTags = exports.createSegment = exports.getProjectClients = exports.getClientTimezone = exports.getClientAvailabilities = exports.deleteComment = exports.submitComment = exports.getComments = exports.getExpertFeedbacks = exports.getClientFeedbacks = exports.getMetric = exports.getSinglePipelineEpl = exports.getPipelineEpls = exports.getPipelineFilters = exports.saveSegmentOrder = exports.regenerateProjectCode = exports.sendAdHocFeedback = exports.updateProjectFieldsHiddenToClient = exports.updateProjectStatus = exports.getSegmentEpls = exports.getExternLinks = exports.getExternLink = exports.remove = exports.updateCategory = exports.update = exports.create = exports.getOne = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getOne(projectId) {
    return api_1.default.get("/api/project/".concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getOne = getOne;
function create(projectFormData) {
    var projectData = projectFormData;
    return api_1.default.post('/api/project', projectData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.create = create;
function update(oldProjectData, projectFormData) {
    var projectData = projectFormData;
    return api_1.default
        .put('/api/project/' + oldProjectData.id, projectData)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.update = update;
function updateCategory(projectId, projectCategoryId) {
    return api_1.default
        .put('/api/project/' + projectId + '/category', { projectCategoryId: projectCategoryId })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateCategory = updateCategory;
function remove(projectId, deletePulse) {
    return api_1.default.delete("/api/project/".concat(projectId, "?deletePulse=").concat(deletePulse)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.remove = remove;
function getExternLink(projectId) {
    return api_1.default.post("/api/project/".concat(projectId, "/extern-link")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getExternLink = getExternLink;
function getExternLinks(projectId) {
    return api_1.default.post("/api/project/".concat(projectId, "/extern-links")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getExternLinks = getExternLinks;
function getSegmentEpls(segmentId) {
    return api_1.default.get("/api/project/segment/".concat(segmentId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getSegmentEpls = getSegmentEpls;
function updateProjectStatus(projectId, payload) {
    return api_1.default.post("/api/project/".concat(projectId, "/change-status"), payload).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.updateProjectStatus = updateProjectStatus;
function updateProjectFieldsHiddenToClient(projectId, payload) {
    return api_1.default
        .put("/api/project/".concat(projectId, "/update-fields-hidden-to-client"), payload)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateProjectFieldsHiddenToClient = updateProjectFieldsHiddenToClient;
function sendAdHocFeedback(projectId) {
    return api_1.default.post("/api/project/".concat(projectId, "/send-feedback")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendAdHocFeedback = sendAdHocFeedback;
function regenerateProjectCode(projectId) {
    return api_1.default.put("/api/project/".concat(projectId, "/regenerate-code"), { projectId: projectId }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.regenerateProjectCode = regenerateProjectCode;
/**
 * Will save ordering of the EPLs as specified.
 *
 * @param {Number} segmentId     IDs of the segment the EPLs belong to
 * @param {Array<Number>} eplIds New order of EPLs on the segment
 */
function saveSegmentOrder(segmentId, eplIds) {
    return api_1.default.put("/api/project/segment/".concat(segmentId, "/order"), { eplIds: eplIds }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.saveSegmentOrder = saveSegmentOrder;
function getPipelineFilters(projectId) {
    return api_1.default
        .get('/api/project/' + projectId + '/pipeline/filters')
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getPipelineFilters = getPipelineFilters;
/**
 * Fetches EPLs to be displayed on the project EPL.
 * TODO: move the custom query to this function
 *
 * @param {Number} projectId project ID to fetch the EPLs for
 * @param { object } params
 * @param { any } params.filters overriding query (uses filters)
 * @returns {Promise} EPLs related to the specified project
 */
function getPipelineEpls(projectId, params) {
    return api_1.default.post("/api/project/".concat(projectId, "/pipeline"), params).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getPipelineEpls = getPipelineEpls;
function getSinglePipelineEpl(projectId, eplId) {
    var url = "/api/project/".concat(projectId, "/pipeline/").concat(eplId);
    return api_1.default.get(url).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getSinglePipelineEpl = getSinglePipelineEpl;
function getMetric(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/metric")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getMetric = getMetric;
function getClientFeedbacks(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/client-feedback")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getClientFeedbacks = getClientFeedbacks;
function getExpertFeedbacks(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/expert-feedback")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getExpertFeedbacks = getExpertFeedbacks;
function getComments(projectId) {
    return api_1.default.get("/api/note/project/".concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getComments = getComments;
function submitComment(projectId, comment) {
    return api_1.default
        .post("/api/note/project/".concat(projectId), {
        comment: comment,
        visibility: false,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.submitComment = submitComment;
function deleteComment(comment) {
    return api_1.default.delete("/api/note/project/".concat(comment.id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteComment = deleteComment;
function getClientAvailabilities(projectId) {
    return api_1.default.get("/api/client-to-project-availability/fetch/".concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getClientAvailabilities = getClientAvailabilities;
function getClientTimezone(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/client-timezone")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getClientTimezone = getClientTimezone;
function getProjectClients(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/clients")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectClients = getProjectClients;
function createSegment(projectFormSegmentData) {
    var segmentData = projectFormSegmentData;
    return api_1.default.post('/api/segment', segmentData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createSegment = createSegment;
function searchTags(query) {
    if (!query)
        return Promise.resolve([]);
    var encodedQuery = encodeURIComponent(query);
    return api_1.default
        .get('/api/tag?q=' + encodedQuery)
        .then(function (res) {
        var options = (0, api_1.extractData)(res);
        return options;
    })
        .catch(api_1.errorHandler);
}
exports.searchTags = searchTags;
function typeaheadSearch(query, options) {
    if (options === void 0) { options = {}; }
    var params = new window.URLSearchParams();
    params.append('q', query);
    Object.keys(options).forEach(function (objectProp) {
        params.append(objectProp, options[objectProp]);
    });
    return api_1.default.get("/api/project/typeahead", { params: params }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.typeaheadSearch = typeaheadSearch;
function getFeesByProjectId(projectId) {
    return api_1.default.get("/api/fee/project/".concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeesByProjectId = getFeesByProjectId;
function typeahead(query) {
    var encodedQuery = encodeURIComponent(query);
    return api_1.default
        .get('/api/project/typeahead?q=' + encodedQuery)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.typeahead = typeahead;
function getSimilarProjects(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/similar")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getSimilarProjects = getSimilarProjects;
function getApplicableContracts(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/contracts")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getApplicableContracts = getApplicableContracts;
function getProjectAttachments(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/attachments")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectAttachments = getProjectAttachments;
function deleteProjectAttachments(projectId) {
    return api_1.default.delete("/api/project/attachments/".concat(projectId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteProjectAttachments = deleteProjectAttachments;
function getInterviewedExperts(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/interviewed-expected")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getInterviewedExperts = getInterviewedExperts;
function saveInterviewTarget(projectId, data) {
    return api_1.default.post("/api/project/".concat(projectId, "/interview-target"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.saveInterviewTarget = saveInterviewTarget;
function emailExperts(data) {
    return api_1.default.post('/api/project/email-experts', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.emailExperts = emailExperts;
function getLoggedEvents(data) {
    return api_1.default.post('/api/logged-event/query', data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getLoggedEvents = getLoggedEvents;
function searchProjects(data) {
    return api_1.default
        .post(process.env.REACT_APP_SEARCH_SERVICE_URL + '/project', data)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.searchProjects = searchProjects;
function exportProjectDeliverables(projectId) {
    return api_1.default.post('/api/project/project-deliverables-export', { projectId: projectId }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportProjectDeliverables = exportProjectDeliverables;
function exportProjectPipeline(projectId, selectedEplsIds, format) {
    if (format === void 0) { format = 'standard'; }
    var payload = { projectId: projectId };
    if (selectedEplsIds) {
        payload = tslib_1.__assign(tslib_1.__assign({}, payload), { selectedEplsIds: selectedEplsIds });
    }
    return api_1.default
        .post('/api/project/project-pipeline-export', tslib_1.__assign(tslib_1.__assign({}, payload), { format: format }))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.exportProjectPipeline = exportProjectPipeline;
function getZoomMeetings(projectId, scheduleStartDate) {
    if (!projectId) {
        console.error('All good. No projectId. Skipping the request');
        return [];
    }
    return api_1.default
        .post("/api/project/".concat(projectId, "/zoom-meetings"), {
        startTime: scheduleStartDate,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getZoomMeetings = getZoomMeetings;
function getUnresolvedEpls(projectId) {
    return api_1.default.get("/api/project/".concat(projectId, "/unresolved")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getUnresolvedEpls = getUnresolvedEpls;
function updateCapiProject(projectId, platformProjectId) {
    return api_1.default
        .post("".concat(process.env.REACT_APP_CAPI_SERVICE_URL, "/update-project-platform-id"), { projectId: projectId, platformProjectId: platformProjectId })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateCapiProject = updateCapiProject;
function sendIndividualLink(projectId, userId) {
    if (userId === void 0) { userId = null; }
    return api_1.default.post("/api/project/".concat(projectId, "/individual-link"), { userId: userId }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendIndividualLink = sendIndividualLink;
function sendGenericLinks(projectId) {
    return api_1.default.post("/api/project/".concat(projectId, "/generic-link")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendGenericLinks = sendGenericLinks;
function createNewTargetedNumberOfExperts(projectId, numberOfExperts, updateReason) {
    return api_1.default
        .post("/api/project/".concat(projectId, "/targeted-number-of-experts-update"), { numberOfExperts: numberOfExperts, updateReason: updateReason })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.createNewTargetedNumberOfExperts = createNewTargetedNumberOfExperts;
var api = {
    getOne: getOne,
    create: create,
    update: update,
    remove: remove,
    getExternLink: getExternLink,
    getExternLinks: getExternLinks,
    getSegmentEpls: getSegmentEpls,
    updateProjectStatus: updateProjectStatus,
    sendAdHocFeedback: sendAdHocFeedback,
    regenerateProjectCode: regenerateProjectCode,
    saveSegmentOrder: saveSegmentOrder,
    getPipelineFilters: getPipelineFilters,
    getPipelineEpls: getPipelineEpls,
    getSinglePipelineEpl: getSinglePipelineEpl,
    getMetric: getMetric,
    getClientFeedbacks: getClientFeedbacks,
    getExpertFeedbacks: getExpertFeedbacks,
    getComments: getComments,
    submitComment: submitComment,
    deleteComment: deleteComment,
    getClientAvailabilities: getClientAvailabilities,
    getClientTimezone: getClientTimezone,
    getProjectClients: getProjectClients,
    createSegment: createSegment,
    searchTags: searchTags,
    typeaheadSearch: typeaheadSearch,
    getFeesByProjectId: getFeesByProjectId,
    getSimilarProjects: getSimilarProjects,
    getApplicableContracts: getApplicableContracts,
    getProjectAttachments: getProjectAttachments,
    deleteProjectAttachments: deleteProjectAttachments,
    getInterviewedExperts: getInterviewedExperts,
    saveInterviewTarget: saveInterviewTarget,
    emailExperts: emailExperts,
    getLoggedEvents: getLoggedEvents,
    getZoomMeetings: getZoomMeetings,
    searchProjects: searchProjects,
    exportProjectDeliverables: exportProjectDeliverables,
    exportProjectPipeline: exportProjectPipeline,
    sendIndividualLink: sendIndividualLink,
    sendGenericLinks: sendGenericLinks,
    createNewTargetedNumberOfExperts: createNewTargetedNumberOfExperts,
};
exports.default = api;
