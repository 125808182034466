import { debounce } from 'lodash'
import { ChatStore, apiSlice } from '@atheneum/messaging'

class NotificationStore {
  constructor(growlStore) {
    this.growlStore = growlStore
  }

  notificationListener(socket) {
    socket.on('clientNotification', (data) => {
      this.handleNotification(data)
    })
  }

  handleNotification = debounce((data) => {
    if (data.type === 'NEW_MESSAGE') {
      ChatStore.dispatch(apiSlice.util.invalidateTags([{ type: 'Chat' }, { type: 'Room' }]))

      const notification = {
        title: 'New message',
        message: data.message,
        type: 'info',
        autoClose: true,
      }

      this.growlStore.addNotification(notification)
    }
  }, 300)
}

export default NotificationStore
