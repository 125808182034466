import React from 'react'
import InfoIcon from '../../Icon/Info'
import RcTooltip from 'rc-tooltip'
import PropTypes from 'prop-types'

export default class Tooltip extends React.Component {
  static propTypes = {
    placement: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]),
    method: PropTypes.string,
    delay: PropTypes.number,
    children: PropTypes.element,
    disabled: PropTypes.bool,
    wide: PropTypes.bool,
    sherlock: PropTypes.bool,
    light: PropTypes.bool,
  }

  static defaultProps = {
    placement: 'top',
    delay: 0,
  }

  render() {
    const { placement, tooltip, delay, children, disabled, method = 'hover', wide, sherlock, light } = this.props

    if (disabled) return children

    let content = children || (
      <div className="iconWrapper">
        <InfoIcon />
      </div>
    )

    let overlayClassName = wide ? 'wideTooltipWrapper' : 'tooltipWrapper'

    if (sherlock) {
      overlayClassName = `${overlayClassName} sherlockTooltipWrapper`
    }

    if (light) {
      overlayClassName = `${overlayClassName} lightTooltipWrapper`
    }

    return (
      <RcTooltip
        placement={placement || 'top'}
        trigger={[method]}
        overlayClassName={overlayClassName}
        overlay={tooltip}
        mouseEnterDelay={delay || 0}
        mouseLeaveDelay={0}
      >
        {content}
      </RcTooltip>
    )
  }
}
