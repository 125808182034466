import React from 'react'
import Icon from './Icon'

class Cancel extends React.Component {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="21px" height="20px" viewBox="0 0 21 20">
          <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1202.000000, -234.000000)" id="cancel">
              <g transform="translate(1200.625269, 232.000000)">
                <polygon id="Bounds" points="0 0 24 0 24 24 0 24" />
                <path
                  d="M14.59,8 L12,10.59 L9.41,8 L8,9.41 L10.59,12 L8,14.59 L9.41,16 L12,13.41 L14.59,16 L16,14.59 L13.41,12 L16,9.41 L14.59,8 L14.59,8 Z M12,2 C6.47,2 2,6.47 2,12 C2,17.53 6.47,22 12,22 C17.53,22 22,17.53 22,12 C22,6.47 17.53,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z"
                  id="Icon"
                  className="icon__fill"
                  fill="#7E8DA2"
                />
              </g>
            </g>
          </g>
        </svg>
      </Icon>
    )
  }
}

export default Cancel
