import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { computed, extendObservable } from 'mobx'

import Arrow from '@components/Icons/Arrow'
import PropTypes from 'prop-types'

class Pagination extends Component {
  static propTypes = {
    total: PropTypes.number,
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    maxPages: PropTypes.number,
    pagesToShow: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    pagesToShow: 5,
  }

  constructor(props) {
    super(props)

    extendObservable(this, {
      lastPage: computed(() => {
        let lastPage = Math.ceil(this.props.total / this.props.perPage)
        if (this.props.maxPages < lastPage) {
          lastPage = this.props.maxPages
        }
        return lastPage
      }),
      pages: computed(() => this.getPages()),
    })
  }

  getPages() {
    const pages = [this.props.page]
    if (this.props.page > this.lastPage) {
      return [1]
    }

    for (let i = 1; i < this.props.pagesToShow; i++) {
      const pageAfter = this.props.page + i
      if (pageAfter < this.lastPage && pages.length < this.props.pagesToShow) {
        pages.push(pageAfter)
      }

      const pageBefore = this.props.page - i
      if (pageBefore > 1 && pages.length < this.props.pagesToShow) {
        pages.unshift(pageBefore)
      }

      if (pages.length === this.props.pagesToShow) i = this.props.pagesToShow
    }

    // Add first page and dots if needed
    if (pages[0] !== 2 && this.props.page !== 1) pages.unshift(null)
    if (this.props.page !== 1) pages.unshift(1)

    // Add last page and dots if needed
    if (pages[pages.length - 1] !== this.lastPage - 1 && this.props.page !== this.lastPage) {
      pages.push(null)
    }
    if (this.props.page !== this.lastPage) pages.push(this.lastPage)

    return pages
  }

  renderPage = (page) => {
    return page ? (
      <div key={page} className={page === this.props.page ? 'active' : ''} onClick={() => this.changePage(page)}>
        {page}
      </div>
    ) : (
      <div key={'sep' + Math.random()} className="disabled">
        ...
      </div>
    )
  }

  changePage = (page) => {
    this.props.onChange(page)
  }

  render() {
    const { total } = this.props
    if (!total) return null
    return (
      <div className={'Pagination'}>
        {this.props.page !== 1 ? (
          <div className="Arrow" onClick={() => this.changePage(this.props.page - 1)}>
            <Arrow rotate180 />
          </div>
        ) : (
          ''
        )}
        {this.pages.map((p) => this.renderPage(p))}
        {this.props.page !== this.lastPage ? (
          <div className="Arrow" onClick={() => this.changePage(this.props.page + 1)}>
            <Arrow />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default observer(Pagination)
