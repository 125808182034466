// subindustry/industry options for the formik project form

export default function getSubIndustryOptions(staticData) {
  return staticData.industries.reduce((industryOpts, industry) => {
    const subindustries = staticData.subIndustries.filter((si) => si.industryId === industry.id)

    const industryObject = {
      id: `${industry.id}-industry`,
      type: 'industry',
      disabled: true,
      name: industry.name,
      className: 'select-parent',
      children: subindustries.map((si) => si.name).join(' '),
    }

    const subindustryOptions = subindustries.map((si) => {
      return {
        id: si.id,
        type: 'subIndustry',
        name: si.name,
        industryId: si.industryId,
        disabled: !si.selectable,
        className: 'select-child',
      }
    })

    return industryOpts.concat(industryObject).concat(subindustryOptions)
  }, [])
}
