import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import tokenStorage from '@atheneum/api/dist/lib/tokenStorage'

import { updateMessages, appendMessage, clearAvailableRoom } from './chatSlice'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MESSAGING_SERVICE_URL,
    prepareHeaders: (headers: any) => {
      const token = tokenStorage.getChatToken()

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Chat', 'Room'],
  endpoints: (builder: any) => ({
    getChatRooms: builder.query({
      query: (query: any) => ({
        url: `/get-room-list`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Room'],
    }),
    getUserUnseenCount: builder.query({
      query: (query: any) => ({
        url: `/get-user-unseen-count`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Room'],
    }),
    getRoomData: builder.query({
      query: (query: any) => ({
        url: `/get-room-data`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Room'],
    }),
    getRoomChatMessages: builder.query({
      query: (query: any) => ({
        url: `/get-room-messages`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Chat'],
      async onQueryStarted(_id: any, { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateMessages(data))
        } catch (err) {}
      },
    }),
    createMessage: builder.mutation({
      query: (query: any) => ({
        url: '/create-message',
        method: 'POST',
        body: query,
      }),
      invalidatesTags: ['Chat', 'Room'],
      async onQueryStarted(query: any, { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }) {
        try {
          dispatch(appendMessage(query))
          dispatch(clearAvailableRoom())
        } catch (err) {}
      },
    }),
    markMessagesRead: builder.mutation({
      query: (query: any) => ({
        url: '/mark-messages-read',
        method: 'POST',
        body: query,
      }),
      invalidatesTags: ['Room'],
    }),
  }),
})

export const {
  useGetRoomChatMessagesQuery,
  useGetChatRoomsQuery,
  useGetUserUnseenCountQuery,
  useGetRoomDataQuery,
  useCreateMessageMutation,
  useMarkMessagesReadMutation,
} = apiSlice
