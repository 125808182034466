import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'

import { observer } from 'mobx-react'

const Input = ({
  field,
  form,
  label,
  short,
  isLogin,
  extraShort,
  numberInput,
  linkCallback,
  linkLabel,
  className,
  inputClassName,
  renderError,
  tooltip,
  ...props
}) => {
  const error = get(form.touched, field.name) && get(form.errors, field.name)

  const groupClasses = classNames({
    'form__input-group': !isLogin,
    'form__input-group--error': error,
    [className]: className,
  })

  const wrapperClasses = classNames({
    'form__input-wrapper': !isLogin,
    'form__input-wrapper--short': short,
    'form__input-wrapper--error': error,
    [inputClassName]: inputClassName,
  })

  const inputClasses = classNames({
    form__input: true,
    'form__input--number': numberInput,
  })

  let fieldValue = get(form.values, field.name)

  // check for empty values to avoid the input value is null React error
  if (fieldValue === undefined || fieldValue === null) fieldValue = ''

  const inputField = (
    <div className={wrapperClasses}>
      <input className={inputClasses} {...field} {...props} value={fieldValue} />
      {renderError ? renderError(error) : <div className="form__input-error">{error}</div>}
    </div>
  )

  const linkButton =
    linkCallback && linkLabel ? (
      <div className="form__link">
        <span onClick={linkCallback}>{linkLabel}</span>
      </div>
    ) : null

  if (!label) {
    return inputField
  }

  return (
    <div className={groupClasses}>
      <label className={isLogin ? '' : 'form__label'}>{label}</label>
      {inputField}
      {linkButton}
    </div>
  )
}

Input.propTypes = {
  field: propTypes.shape({
    name: propTypes.string,
  }).isRequired,
  form: propTypes.object,
  short: propTypes.bool,
  extraShort: propTypes.bool,
  numberInput: propTypes.bool,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]),
  noLabel: propTypes.bool,
  linkCallback: propTypes.func,
  linkLabel: propTypes.string,
  className: propTypes.string,
  inputClassName: propTypes.string,
  renderError: propTypes.func,
}

export default observer(Input)
