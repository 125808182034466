import React from 'react'
import ReactModal from 'react-modal'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import ModalButtons from './ModalButtons'
import CloseIcon from '../Icons/Close'
import ContactInformationForm from '@root/ClientAccount/Components'
import get from 'lodash/get'

const ModalTypes = {
  EDIT_ACCOUNT: ContactInformationForm,
}

const modalContentClassNames = {
  base: 'modal__content',
  afterOpen: 'modal__content--after-open',
  beforeClose: 'modal__content--before-close',
}

const modalOverlayClassNames = {
  base: 'modal__overlay',
  afterOpen: 'modal__overlay--after-open',
  beforeClose: 'modal__overlay--before-close',
}

const ModalWrapper = ({ modalStore }) => {
  let modalContent
  let Modal
  let defaultProps = {}

  if (modalStore.type) {
    Modal = ModalTypes[modalStore.type]
    defaultProps = get(Modal, 'wrappedComponent.defaultProps') || Modal.defaultProps || {}
    modalContent = <Modal {...modalStore.props} />
  }

  const isVisible = modalStore.config.isOpen
  const shouldCloseOnOverlayClick =
    defaultProps.shouldCloseOnOverlayClick !== undefined
      ? defaultProps.shouldCloseOnOverlayClick
      : modalStore.props.shouldCloseOnOverlayClick

  const shouldShowCloseIcon =
    defaultProps.showCloseIcon !== undefined ? defaultProps.showCloseIcon : modalStore.props.showCloseIcon
  const closeIcon = shouldShowCloseIcon ? <CloseIcon onClick={modalStore.close} /> : null

  const classes = defaultProps.class
    ? Object.assign({}, modalContentClassNames, {
        base: `${modalContentClassNames.base} ${defaultProps.class}`,
      })
    : modalContentClassNames

  return (
    <ReactModal
      isOpen={isVisible}
      contentLabel="Modal"
      className={classes}
      overlayClassName={modalOverlayClassNames}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={modalStore.close}
      closeTimeoutMS={modalStore.closeTimeoutInMs}
      ariaHideApp={false}
    >
      {closeIcon}
      {modalContent}
      <ModalButtons {...modalStore.props} {...defaultProps} close={modalStore.close} />
    </ReactModal>
  )
}

ModalWrapper.propTypes = {
  modalStore: PropTypes.shape({
    type: PropTypes.string,
    props: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.node,
      okText: PropTypes.string,
      cancelText: PropTypes.string,
      hideCancelButton: PropTypes.bool,
      hideButtons: PropTypes.bool,
      onOk: PropTypes.func,
      onCancel: PropTypes.func,
      showCloseIcon: PropTypes.bool,
    }),
  }),
}

export default inject('modalStore')(observer(ModalWrapper))
