import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import ACL from '@components/ACL'

const SubHeader = ({ userStore, activeProjectsStore, activeContractsStore }) => {
  const { user } = userStore
  const { numOfActiveProjects, requestedExperts } = activeProjectsStore
  const { numOfActiveContracts } = activeContractsStore
  const fullName = user.get('fullName')
  const client = user.get('client')
  const parentAccount = client.account.parentAccount
  return (
    <div className="sub-header">
      <div className="container">
        <div className="sub-header__section">
          <div className="sub-header__title">Hi {fullName}</div>
          <div className="sub-header__account">{parentAccount.parentAccountName}</div>
        </div>
        <ACL isAllowed={client.knowledgeManager}>
          <div className="sub-header__section">
            <div className="sub-header__statistic">
              <div className="sub-header__statistic-item">
                <span className="sub-header__statistic-number">{numOfActiveProjects}</span>
                Active
                <br />
                projects
              </div>
              <div className="sub-header__statistic-item">
                <span className="sub-header__statistic-number">{requestedExperts}</span>
                Requested
                <br />
                experts
              </div>
              <div className="sub-header__statistic-item">
                <span className="sub-header__statistic-number">{numOfActiveContracts}</span>
                Active
                <br />
                contracts
              </div>
            </div>
          </div>
        </ACL>
      </div>
    </div>
  )
}

SubHeader.propTypes = {
  userStore: PropTypes.object,
  activeProjectsStore: PropTypes.object,
  activeContractsStore: PropTypes.object,
}

export default inject('userStore', 'activeProjectsStore', 'activeContractsStore')(observer(SubHeader))
