"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvoicingRegions = exports.getContracts = exports.getContractsCount = exports.getProjects = exports.getClientsData = exports.getOffices = exports.getAccounts = exports.autocomplete = exports.getInvoicingEntities = exports.getFeedbacks = exports.getProjectCount = exports.getParentAccount = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getParentAccount(parentAccountId) {
    return api_1.default.get("/api/parent-account/".concat(parentAccountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getParentAccount = getParentAccount;
function getProjectCount(parentAccountId) {
    return api_1.default.get("/api/parent-account/".concat(parentAccountId, "/projects-count")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectCount = getProjectCount;
function getFeedbacks(parentAccountId) {
    return api_1.default.get("/api/parent-account/feedbacks/".concat(parentAccountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getFeedbacks = getFeedbacks;
function getInvoicingEntities(parentAccountId) {
    return api_1.default.get("/api/invoicing-entity?parentAccountId=".concat(parentAccountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getInvoicingEntities = getInvoicingEntities;
function autocomplete(keyword) {
    return api_1.default.get("/api/parent-account/autocomplete?q=".concat(keyword)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.autocomplete = autocomplete;
function getAccounts(parentAccountId, options) {
    if (options === void 0) { options = { expanded: true }; }
    var params = new window.URLSearchParams();
    Object.keys(options).forEach(function (objectProp) {
        params.append(objectProp, options[objectProp]);
    });
    var url = "/api/account/parent-account/".concat(parentAccountId);
    return api_1.default.get(url, { params: params }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAccounts = getAccounts;
function getOffices(parentAccountId) {
    return api_1.default.get("/api/office/parent-account/".concat(parentAccountId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getOffices = getOffices;
function getClientsData(hash) {
    return api_1.default.get("/api/project/".concat(hash, "/client-data/extern")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getClientsData = getClientsData;
function getProjects(payload) {
    return api_1.default
        .post(process.env.REACT_APP_SEARCH_SERVICE_URL + '/project', payload)
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getProjects = getProjects;
function getContractsCount(parentAccountId) {
    return api_1.default.get("/api/parent-account/".concat(parentAccountId, "?bare=true")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getContractsCount = getContractsCount;
function getContracts(parentAccountId) {
    return api_1.default.get("/api/parent-account/".concat(parentAccountId, "/contracts")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getContracts = getContracts;
function getInvoicingRegions() {
    return api_1.default.get('/api/invoicing-region').then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getInvoicingRegions = getInvoicingRegions;
var api = {
    getParentAccount: getParentAccount,
    getProjectCount: getProjectCount,
    getFeedbacks: getFeedbacks,
    getInvoicingEntities: getInvoicingEntities,
    autocomplete: autocomplete,
    getAccounts: getAccounts,
    getOffices: getOffices,
    getProjects: getProjects,
    getContractsCount: getContractsCount,
    getContracts: getContracts,
    getInvoicingRegions: getInvoicingRegions,
    getClientsData: getClientsData,
};
exports.default = api;
