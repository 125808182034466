import React from 'react'
import PropTypes from 'prop-types'

const Edit = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg width="15px" height="15px" viewBox="0 0 22 22">
        <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-413.000000, -356.000000)" id="edit" className="icon__fill" fill="#FFFFFF">
            <g transform="translate(413.000000, 355.000000)">
              <path
                d="M0,15.25 L0,19 L3.75,19 L14.81,7.94 L11.06,4.19 L0,15.25 L0,15.25 Z M17.71,5.04 C18.1,4.65 18.1,4.02 17.71,3.63 L15.37,1.29 C14.98,0.9 14.35,0.9 13.96,1.29 L12.13,3.12 L15.88,6.87 L17.71,5.04 L17.71,5.04 Z"
                id="Icon"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

Edit.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Edit
