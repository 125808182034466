import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Spinner from '@components/Spinner'
import { Error } from '@components/NoContent'
import Button from '@components/Button'
import ChevronIcon from '@components/Icons/Chevron'
import Arrow from '@components/Icons/Arrow'
import { showDialog, isCancellationError } from '@components/Dialog'
import Input from '@components/Form/Components/Input'

import { formatDate, renderExperience, renderExpertRate } from '@lib/helperFunctions'

import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

class SingleEpl extends Component {
  static propTypes = {
    singleEplStore: PropTypes.object,
    staticDataStore: PropTypes.object,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.loadSingleEplData()
    this.state = {
      error: null,
    }
  }

  get engagementRequired() {
    const { complianceAuditStore } = this.props
    const { epl, singleLoading } = complianceAuditStore
    if (singleLoading || !epl) return true

    const engagementDisabledTypes = [
      this.props.staticDataStore.staticData.CONST.COMPLIANCE_TYPES.CID,
      this.props.staticDataStore.staticData.CONST.COMPLIANCE_TYPES.EY,
    ]

    return epl.project.complianceRequired && !engagementDisabledTypes.includes(epl.project.complianceTypeId)
  }

  getValidationSchema() {
    const accountId = this.props.complianceAuditStore.epl.project.parentAccountId
    const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'
    const engagementRequired = (s, msg) => (this.engagementRequired ? s.required(msg) : s.notRequired().default(''))
    return yup.object().shape({
      engagementManager: engagementRequired(yup.string().nullable(), `Engagement ${em} is required`),
      engagementCode: engagementRequired(yup.string().nullable(), 'Engagement Code is required'),
      clientComments: yup.string(),
    })
  }

  loadSingleEplData() {
    const { complianceAuditStore, match } = this.props
    const eplId = match.params.id
    complianceAuditStore.loadSingleEpl(eplId)
  }

  submitForm =
    (epl, eplStatusId, fullName = '') =>
    (form) => {
      if (form.isValid) {
        this.setState({ error: null })
        this.handleEplUpdate(epl, { eplStatusId, ...form.values }, fullName)
      } else {
        const accountId = this.props.complianceAuditStore.epl.project.parentAccountId
        const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'
        this.setState({ error: `Engagement ${em} and Engagement Code are required to approve this profile` })
      }
    }

  handleEplUpdate = (
    epl,
    { eplStatusId, engagementManager = null, engagementCode = null, clientComments = null },
    fullName = '',
  ) => {
    const { EPL_STATUSES } = this.props.staticDataStore.staticData.CONST
    showDialog({
      title: `Are you sure you want to ${eplStatusId === EPL_STATUSES.REJECTED ? 'reject' : 'approve'} this profile?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    })
      .then(async () => {
        const { complianceAuditStore, growlStore } = this.props
        await complianceAuditStore.updateEplStatus(
          epl,
          {
            eplStatusId,
            engagementManager,
            engagementCode,
            clientComments,
          },
          fullName,
        )
        growlStore.showSuccess()
        await complianceAuditStore.loadAuditEpls()
      })
      .catch((err) => {
        if (isCancellationError(err)) return
        this.props.growlStore.showError(err)
      })
  }

  initializeForm(epl) {
    const initialValues = {
      engagementManager: epl.project.engagementManager || null,
      engagementCode: epl.project.engagementCode || null,
    }
    return initialValues
  }

  render() {
    const { staticDataStore, userStore, complianceAuditStore } = this.props

    const { epl, singleLoading, error } = complianceAuditStore
    if (singleLoading || !epl) return <Spinner />
    const engagementManager = epl.project.engagementManager
    const engagementCode = epl.project.engagementCode

    const requestor = epl.project.clientToProjects[0].client.fullName

    const accountId = this.props.complianceAuditStore.epl.project.parentAccountId
    const em = this.props.complianceAuditStore.KPMG_PARENT_ACCOUNT_ID === accountId ? 'Partner' : 'Manager'

    if (error) return <Error error={error} />

    const { staticData } = staticDataStore
    const { countries } = staticData.names
    const countryName = countries[epl.expert.address.countryId]
    const { SUBMITTED, REJECTED } = staticData.CONST.EPL_STATUSES
    const fullName = userStore.user.get('fullName')
    return (
      <div>
        <div className="container">
          <Link to="/audit" className="sections-one__link">
            <Arrow rotate180 /> Back to audit
          </Link>
        </div>
        <div className="container">
          <div className="sections-one" style={{ width: '100%' }}>
            <div className="form__contact-information">
              <h3 className="form__contact-information__title">
                {epl.expert.fullName ? `${epl.expert.fullName}, ${countryName}` : `Expert from ${countryName}`}
              </h3>
              {renderEplExperience(epl.relevantExperience.experience)}
              <hr className="separator--thick" />

              {renderScreening(epl.screening)}
              <div>
                <hr />
                <h2 className="sections-one__title">Availability</h2>
                <div
                  className="expert-profile__text"
                  dangerouslySetInnerHTML={{
                    __html: epl.expertAvailability || staticData.expertAvailabilityDefaultText,
                  }}
                />
              </div>
              {renderPositions(epl)}
              {renderIndustryExperience(epl.industryExpertise)}
              {renderLanguagesAndRate(staticData, epl)}

              <div className="single-form-section">
                <div className="epl_engagement_form">
                  <div className="requestor-section">
                    {requestor && <div>Requestor: {requestor}</div>}
                    <div>
                      Project Name: <b>{epl.project.projectName}</b> ({epl.segment.name})
                    </div>
                  </div>
                  <Formik
                    isInitialValid={(engagementManager && engagementCode) || !this.engagementRequired}
                    enableReinitialize
                    initialValues={this.initializeForm(epl)}
                    validationSchema={this.getValidationSchema()}
                    render={(form) => (
                      <Form>
                        <Field name={'engagementManager'} label={`Engagement ${em}`} component={Input} />
                        <Field name={'engagementCode'} label="Engagement Code" component={Input} />
                        <Field name={'clientComments'} label="Comments" component={Input} />
                        {this.state.error && <div className="error-message">{this.state.error}</div>}
                        <div className="epl__actions">
                          <div className="epl__action">
                            <Button
                              className="button--green"
                              onClick={(event) => this.submitForm(epl, SUBMITTED, fullName)(form)}
                            >
                              Approve profile
                            </Button>
                          </div>
                          <div className="epl__action">
                            <Button
                              className="button--red"
                              onClick={() => this.submitForm(epl, REJECTED, fullName)(form)}
                            >
                              Reject profile
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
              <div className="close-section epl__actions">
                <div className="epl__action">
                  <Link to="/audit">
                    <Button secondary>Back</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="sections-two" />
        </div>
      </div>
    )
  }
}

export default withRouter(
  inject('userStore', 'complianceAuditStore', 'growlStore', 'staticDataStore')(observer(SingleEpl)),
)

const renderEplExperience = (experience) => {
  return (
    <div className="m-t-lg">
      <div className="info-wrapper__row">
        <b>{experience.position.name}</b>
      </div>
      <div className="info-wrapper__row">
        <b>
          {experience.company.name} ({formatDate(experience.startDate)} -{' '}
          {experience.current ? 'Now' : formatDate(experience.endDate)})
        </b>
      </div>
    </div>
  )
}

const renderScreening = (screening) => {
  if (!screening) return null
  return (
    <>
      <h2 className="sections-one__title">Screening</h2>
      <div className="expert-profile__text" dangerouslySetInnerHTML={{ __html: screening }} />
    </>
  )
}

const renderPositions = (epl) => {
  const experiences = epl.expert.experiences.slice().sort((exp1, exp2) => {
    const isFirstExpRelevant = exp1.id === epl.relevantExperience.experience.id
    if (isFirstExpRelevant) return -1
    const isSecondExpRelevant = exp2.id === epl.relevantExperience.experience.id
    if (isSecondExpRelevant) return 1

    if (exp1.current && exp2.current) {
      return new Date(exp2.startDate) - new Date(exp1.startDate)
    }

    if (!exp1.current && !exp2.current) {
      return new Date(exp2.endDate) - new Date(exp1.endDate)
    }

    if (exp1.current) return -1
    if (exp2.current) return 1
    return 0
  })

  const positions = experiences.map((experience) => {
    const position = get(experience, 'position.name', '')
    const experienceString = renderExperience(experience)
    const isCurrentlyUsedExperience = experience.id === epl.relevantExperience.experience.id

    return (
      <li key={experience.id} className="flex-items info-wrapper__row">
        <ChevronIcon />
        {isCurrentlyUsedExperience ? (
          <b className="m-l-md">
            {position}, {experienceString}
          </b>
        ) : (
          <span className="m-l-md">
            {position}, {experienceString}
          </span>
        )}
      </li>
    )
  })

  if (!positions.length) return null

  return (
    <div>
      <hr />
      <h2 className="sections-one__title">Positions</h2>
      <ul>{positions}</ul>
    </div>
  )
}

const renderIndustryExperience = (industryExpertise) => {
  if (!industryExpertise) return null

  return (
    <div>
      <hr />
      <h2 className="sections-one__title">Relevance statement</h2>
      <div className="expert-profile__text" dangerouslySetInnerHTML={{ __html: industryExpertise }} />
    </div>
  )
}

const renderLanguagesAndRate = (staticData, epl) => {
  let languages = []
  if (typeof epl.expert.languages === 'object') {
    languages = epl.expert.languages.map((language) => {
      const proficiencyId = language.languageToUser.languageProficiencyId
      const proficiency = staticData.names.languageProficiencies[proficiencyId]
      return (
        <li className="language" key={language.id}>
          <span className="language__name">{language.name}</span>
          <span className="language__proficiency">{proficiency}</span>
        </li>
      )
    })
  }

  const languageEl = languages.length ? (
    <div className="expert-profile__language">
      <h2 className="sections-one__title">Languages</h2>
      <ul className="language-list">{languages}</ul>
    </div>
  ) : null

  const rateEl = renderRate(staticData, epl)

  if (languageEl || rateEl) {
    return (
      <div className="epl__actions">
        {languageEl && <div className="epl__action">{languageEl}</div>}
        {rateEl && <div className="epl__action">{rateEl}</div>}
      </div>
    )
  }
}

const renderRate = (staticData, epl) => {
  if (!epl.fee && !epl.credits) return null
  const { pricings, currencies } = staticData.names
  const pricingId = epl.pricingId || staticData.CONST.PRICINGS.PER_HOUR
  const expertPayment = pricings[pricingId]

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="flex-items">
        <h2 className="sections-one__title">
          Fee
          {renderExpertRate({
            epl,
            pricings,
            currencies,
            className: 'sections-one__title--black',
          })}
        </h2>
      </div>
      <div>{expertPayment}</div>
    </div>
  )
}
