"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateInvoiceUrl = exports.calculateTax = exports.calculateGrossAmount = exports.calculateTotalGrossAmount = exports.calculateTotalNetAmount = void 0;
function calculateTotalNetAmount(invoice) {
    if (!invoice)
        return 0;
    var totalNetAmount = invoice.invoiceItems
        .map(function (item) {
        return item.netAmount;
    })
        .reduce(function (totalAmount, amount) { return totalAmount + amount; }, 0);
    return totalNetAmount;
}
exports.calculateTotalNetAmount = calculateTotalNetAmount;
function calculateTotalGrossAmount(invoice) {
    if (!invoice)
        return 0;
    var totalGrossAmount = invoice.invoiceItems
        .map(function (item) { return calculateGrossAmount(invoice.vat, item); })
        .reduce(function (totalGrossAmount, grossAmount) { return totalGrossAmount + grossAmount; }, 0);
    return totalGrossAmount;
}
exports.calculateTotalGrossAmount = calculateTotalGrossAmount;
function calculateGrossAmount(vat, invoiceItem) {
    var taxAmount = calculateTax(vat, invoiceItem.netAmount);
    var grossAmount = invoiceItem.netAmount + taxAmount;
    return grossAmount || 0;
}
exports.calculateGrossAmount = calculateGrossAmount;
/**
 * Calculate the tax amount
 *
 * @param {number} amount  net amount
 * @param {number} vat invoice VAT - tax percentage
 * @returns {number} calculated tax amount
 */
function calculateTax(vat, amount) {
    var taxAmount = (amount * vat) / 100;
    return taxAmount || 0;
}
exports.calculateTax = calculateTax;
/**
 * Function will generate a proper invoice URL based
 * on the passed project (PAYG) or contract ID (NON-PAYG).
 *
 * @param {object} param
 * @param {number} param.invoiceId invoice ID
 * @param {number?} param.projectId related project ID (if invoice is PAYG)
 * @param {number?} param.contractId related contract ID (if invoice is NON-PAYG)
 * @returns {string}
 */
function generateInvoiceUrl(_a) {
    var invoiceId = _a.invoiceId, projectId = _a.projectId, contractId = _a.contractId;
    if (contractId) {
        return "/contract/".concat(contractId, "/invoice/").concat(invoiceId);
    }
    else if (projectId) {
        return "/project/".concat(projectId, "/invoice/").concat(invoiceId);
    }
    else {
        return '/';
    }
}
exports.generateInvoiceUrl = generateInvoiceUrl;
