import './chat.sass'

import React from 'react'
import { Provider as ReduxProvider, useSelector, useDispatch } from 'react-redux'
import { closeChatList } from './redux/reducers/chatSlice'
import reduxStore from './redux/store'
import CloseIcon from '@material-ui/icons/Close'
import ChatIcon from '@material-ui/icons/Chat'
import pick from 'lodash/pick'

import { ChatList } from './'

export const EPLChat = ({ user, context }: { user: any; context: string | undefined }) => {
  return (
    <ReduxProvider store={reduxStore}>
      <EPLChatContainer user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])} context={context} />
    </ReduxProvider>
  )
}
const EPLChatContainer = ({ user, context }: { user: any; context: string | undefined }) => {
  const eplChat = useSelector((state: any) => state.chat.eplChat)
  const chatListOpen = useSelector((state: any) => state.chat.chatListOpen)
  const dispatch = useDispatch()

  if (!eplChat) return <div></div>

  return (
    <div>
      {chatListOpen && eplChat && (
        <div className="chat-wrapper fixed list">
          <div className="chat-header">
            <div className="left">
              <ChatIcon style={{ fontSize: 32, color: '#fff' }} />
              <span>Messages</span>
            </div>
            <CloseIcon style={{ color: '#fff' }} onClick={() => dispatch(closeChatList())} />
          </div>
          <ChatList user={user} eplId={eplChat} context={context} />
        </div>
      )}
    </div>
  )
}
