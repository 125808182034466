import React, { Component } from 'react'
import propTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Autocomplete } from '@components/Autocomplete'
import get from 'lodash/get'

class AutocompleteField extends Component {
  static propTypes = {
    field: propTypes.shape({
      name: propTypes.string,
    }).isRequired,
    form: propTypes.object,
    valueIsId: propTypes.bool,
    itemToString: propTypes.func,
    label: propTypes.string,
    creatable: propTypes.bool,
    linkCallback: propTypes.func,
    linkLabel: propTypes.string,
    loadOptions: propTypes.func,
    onChange: propTypes.func,
  }

  render() {
    const {
      field,
      form,
      label,
      creatable,
      linkCallback,
      valueIsId,
      linkLabel,
      loadOptions,
      onChange,
      itemToString,
      ...props
    } = this.props

    const error = get(form.touched, field.name) && get(form.errors, field.name)
    const value = get(form.values, field.name)

    const groupClasses = classNames({
      'form__input-group': true,
      'form__input-group--relative': true,
      'form__input-group--error': error,
    })

    const wrapperClasses = classNames({
      'form__input-wrapper': true,
      'form__input-wrapper--error': error,
    })

    const hasValue = itemToString ? !!itemToString(value) : !!value
    const shouldShowLink = hasValue && linkCallback && linkLabel

    const autocompleteProps = {
      className: 'form__input form__input--autocomplete',
      value,
      valueIsId,
      loadOptions: this.props.loadOptions,
      itemToString,
      creatable,
      inputContainsLink: shouldShowLink,
      onBlur: () => {
        form.setFieldTouched(field.name, true)
      },
      onChange: (option) => {
        if (!onChange) {
          form.setFieldValue(field.name, option, false)
        } else {
          onChange(option)
        }
        form.setFieldTouched(field.name, true)
      },
      ...props,
    }

    let errorDisplay

    if (typeof error === 'string') {
      errorDisplay = <div className="form__input-error">{error}</div>
    } else if (error) {
      errorDisplay = Object.values(error).map((message) => (
        <div key={message} className="form__input-error">
          {message}
        </div>
      ))
    } else {
      errorDisplay = null
    }

    const inputField = (
      <div className={wrapperClasses}>
        <Autocomplete {...autocompleteProps} />
        {errorDisplay}
      </div>
    )

    const linkButton = shouldShowLink && (
      <div className="form__link">
        <span onClick={linkCallback}>{linkLabel}</span>
      </div>
    )

    if (!label) {
      return inputField
    }

    return (
      <div className={groupClasses}>
        <label className="form__label">{label}</label>
        {inputField}
        {linkButton}
      </div>
    )
  }
}

export default observer(AutocompleteField)
