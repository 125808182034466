import React from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const StatusLabel = ({ statusId, statusesType, dataCy, staticDataStore }) => {
  const { staticData } = staticDataStore
  const status = staticData.names[statusesType][statusId]
  const { CREATED } = staticData.CONST.INVOICE_STATUSES
  const classes = classNames({
    'status-label': true,
    [`status-label-${status.toLowerCase()}`]: !(statusId < CREATED && statusesType === 'invoiceStatuses'),
  })
  return (
    <div className={classes} data-cy={dataCy}>
      {status}
    </div>
  )
}

StatusLabel.propTypes = {
  staticDataStore: PropTypes.object,
  statusesType: PropTypes.string,
  statusId: PropTypes.number,
  dataCy: PropTypes.string,
}

export default inject('staticDataStore')(observer(StatusLabel))
