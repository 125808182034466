import { action, extendObservable } from 'mobx'

export default class Modal {
  constructor() {
    extendObservable(this, {
      error: null,
      content: null,
      closeTimeoutInMs: 300,
      config: {},
      props: {},
    })
  }

  show = action(({ type, props }) => {
    this.type = type
    this.props = props || {}
    this.config = { isOpen: true }
  })

  showLoader = action(() => {
    this.show({ type: 'LOADING' })
  })

  showInfo = action(({ title, text, icon = null, onOk }) => {
    this.show({
      type: 'DIALOG',
      props: {
        title,
        text,
        icon,
        onOk,
        okText: 'OK',
        hideCancelButton: true,
      },
    })
  })

  close = action(() => {
    this.config = { isOpen: false }

    setTimeout(() => {
      this.config = {}
      this.type = null
      this.props = {}
    }, this.closeTimeoutInMs)
  })
}
