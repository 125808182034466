import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

/*
  NOTE: emptyMsg="hidden" is reserved keyword.

        When used empty rows won't be display
        at all

        Other values will be displayed as string

  EXAMPLE: <InfoWrapperRow label={label} value={value} emptyMsg="hidden" />
*/

class InfoWrapperRow extends Component {
  static propTypes = {
    text: PropTypes.any,
    emptyMsg: PropTypes.string,
    mailTo: PropTypes.bool,
    children: PropTypes.object,
    label: PropTypes.string,
    noSpace: PropTypes.bool,
  }

  isContentEmpty = () => {
    return !this.props.text
  }

  isComponentHidden = () => {
    if (this.isContentEmpty()) {
      return this.props.emptyMsg && this.props.emptyMsg === 'hidden'
    }
  }

  renderValue = () => {
    if (this.props.children) {
      return this.props.children
    }

    if (this.props.text) {
      if (this.props.mailTo) {
        const href = 'mailto:' + this.props.text
        return (
          <span className="info-wrapper__row__value">
            <a href={href}>{this.props.text}</a>
          </span>
        )
      }
      return <span className="info-wrapper__row__value">{this.props.text}</span>
    }

    if (this.isContentEmpty()) {
      if (this.props.emptyMsg) {
        return <span className="info-wrapper__row__value">{this.props.emptyMsg}</span>
      }
      return <span className="info-wrapper__row__value">-</span>
    }
  }

  render() {
    if (this.isComponentHidden()) return null
    const { label, noSpace } = this.props
    return (
      <div className={`info-wrapper__row ${noSpace ? 'info-wrapper__row--no-space' : ''}`}>
        <span className="info-wrapper__row__name">{label ? `${label}:` : ''}</span>
        {this.renderValue()}
      </div>
    )
  }
}

export default observer(InfoWrapperRow)
