import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const PlusIcon = ({ onClick }) => {
  const innerClasses = classNames({
    icon__fill: true,
  })

  return (
    <div onClick={onClick}>
      <svg width="12px" height="12px" viewBox="0 0 12 12">
        <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-489.000000, -359.000000)" id="plus">
            <g transform="translate(495.000000, 365.000000) rotate(-315.000000) translate(-495.000000, -365.000000) translate(483.000000, 353.000000)">
              <polygon points="6.82121026e-13 5.68434189e-13 24 5.68434189e-13 24 24 6.82121026e-13 24" />
              <polygon
                className={innerClasses}
                points="16.8786797 8.53132034 15.4686797 7.12132034 12 10.59 8.53132034 7.12132034 7.12132034 8.53132034 10.59 12 7.12132034 15.4686797 8.53132034 16.8786797 12 13.41 15.4686797 16.8786797 16.8786797 15.4686797 13.41 12"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

PlusIcon.propTypes = {
  onClick: PropTypes.func,
}

export default PlusIcon
