import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import propTypes from 'prop-types'

import CancelIcon from '../Icon/Cancel'
import InfoIcon from '../Icon/Info'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '../Icon/Warning'

import './Growl.sass'

/*
Call like this:

this.props.growlStore.addNotification({
icon: <InfoIcon />,
title: 'Some title'
message: 'You have successfully changed status Lorem ipsum dolor sit amet',
type: 'success', // default white bkg with black text / icons
timeout: 5000, // default 3000
callback: this.myCallback,
autoClose: true, // default false
children: <html>
buttons: [{
    text: 'Update',
    type: 'primary',
    handler: fn()
  }]
});

- icon, message, title are default props, you can replace
them with your html (children prop)

*/

class Growl extends Component {
  renderNotifications = () => {
    const { growlStore } = this.props
    return growlStore.list.map((notification) => {
      // set type
      let className = 'notification-wrapper__single'
      if (notification.type) {
        className += ' notification-wrapper__single--' + notification.type
      }

      // set animation
      const timeout = Number(notification.timeout || growlStore.defaultOffset) / 1000 - 0.5

      const closeAnimation = notification.autoClose ? ', .5s ease ' + timeout + 's 1 normal forwards running hide' : ''

      const animation = '.5s ease 0s 1 normal forwards running show' + closeAnimation

      const style = { animation: animation }

      // check if children is prop
      if (notification.children) {
        return this.renderChildren(notification, className, style)
      }

      return (
        <div
          key={notification.id}
          className={className}
          style={style}
          onClick={() => this.handleBoxClick(notification)}
        >
          {this.renderIcon(notification)}
          <div className="notification-wrapper__single__box">
            {this.renderTitle(notification)}
            {this.renderMessage(notification)}
            {notification.buttons && (
              <div className="notification-buttons">
                {notification.buttons.map((button) => this.renderButton(button, notification.id))}
              </div>
            )}
            {this.renderCloseButton(notification.id)}
          </div>
        </div>
      )
    })
  }

  handleBoxClick = (notification) => {
    if (notification.callback) notification.callback()
    if (notification.linkTo) window.location = notification.linkTo
  }

  renderIcon = (notification) => {
    let icon = <InfoIcon />
    if (!notification.icon) {
      if (notification.type === 'success') icon = <CheckCircleOutlineIcon />
      if (notification.type === 'error') icon = <WarningIcon />
    } else {
      if (notification.icon === 'info') icon = <InfoIcon />
      if (notification.icon === 'warning') icon = <WarningIcon />
    }

    return <div className="notification-wrapper__single__icon">{icon}</div>
  }

  renderCloseButton = (id) => {
    return (
      <button
        className="notification-wrapper__single__cancel"
        onClick={() => this.props.growlStore.removeNotification(id)}
      >
        <CancelIcon />
      </button>
    )
  }

  renderTitle = (notification) => {
    if (!notification.title) return null
    return (
      <span className="notification-wrapper__single__title" dangerouslySetInnerHTML={{ __html: notification.title }} />
    )
  }

  renderMessage = (notification) => {
    if (!notification.message) return null
    return (
      <span
        className="notification-wrapper__single__message"
        dangerouslySetInnerHTML={{ __html: notification.message }}
      />
    )
  }

  renderChildren = (notification, className, style) => {
    return (
      <div className={className} style={style} onClick={() => this.handleBoxClick(notification)}>
        <div dangerouslySetInnerHTML={{ __html: notification.children }} />
      </div>
    )
  }

  renderButton = (button, id) => {
    return (
      <div className={'notification-button ' + button.type} onClick={() => button.handler(id)}>
        {button.text}
      </div>
    )
  }

  render() {
    return <div className="notification-wrapper">{this.renderNotifications()}</div>
  }
}

Growl.propTypes = {
  growlStore: propTypes.object,
  /* icon: propTypes.element,
  title: propTypes.node,
  message: propTypes.node,
  timeout: propTypes.number,
  type: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.string,
    propTypes.arrayOf(propTypes.node),
    propTypes.node
  ]) */
}

export default inject('growlStore')(observer(Growl))
