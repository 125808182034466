import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import IconDown from '../../Icon/IconDown.js'

const Select = (props) => {
  const { value, options, disabled, onChange, onBlur, onFocus, blue, placeholder, className = '' } = props
  const isDisabled = options.length === 0 || disabled
  const classes = classNames({
    select__wrapper: true,
    'select__wrapper--blue': blue,
    'select__wrapper--disabled': isDisabled,
    [className]: className,
  })

  let selectOptions = options.map((opt) => {
    const isObject = typeof opt === 'object'
    const value = isObject ? opt.value : opt
    const label = isObject ? opt.label : opt
    const key = isObject ? `${value}${label}` : opt
    return (
      <option key={key} value={value} label={label}>
        {label}
      </option>
    )
  })

  if (placeholder) {
    const defaultOption = (
      <option key="default" disabled value="">
        {placeholder}
      </option>
    )
    selectOptions = [defaultOption].concat(selectOptions)
  }

  return (
    <label className={classes}>
      <IconDown right inside noClick />
      <select
        className="select"
        onChange={onChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isDisabled}
      >
        {selectOptions}
      </select>
    </label>
  )
}

export default observer(Select)
