"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportHfCompliance = exports.sendRequest = exports.reject = exports.confirm = exports.getHfCompliance = exports.getHfCompliances = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
var urlBase = '/api/hf-compliance';
function getHfCompliances(expertId) {
    return api_1.default.get("".concat(urlBase, "/expert/").concat(expertId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getHfCompliances = getHfCompliances;
function getHfCompliance(hfComplianceId, expertId) {
    return api_1.default.get("".concat(urlBase, "/").concat(expertId, "/").concat(hfComplianceId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getHfCompliance = getHfCompliance;
function confirm(id) {
    return api_1.default.put("".concat(urlBase, "/").concat(id, "/confirm")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.confirm = confirm;
function reject(id) {
    return api_1.default.put("".concat(urlBase, "/").concat(id, "/reject")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.reject = reject;
function sendRequest(expertId) {
    return api_1.default.put("".concat(urlBase, "/request/").concat(expertId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendRequest = sendRequest;
function exportHfCompliance(_a) {
    var hfComplianceId = _a.hfComplianceId, withComments = _a.withComments, projectName = _a.projectName;
    return api_1.default
        .post("".concat(urlBase, "/export"), { hfComplianceId: hfComplianceId, withComments: withComments, projectName: projectName })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.exportHfCompliance = exportHfCompliance;
var api = {
    getHfCompliances: getHfCompliances,
    getHfCompliance: getHfCompliance,
    exportHfCompliance: exportHfCompliance,
    confirm: confirm,
    reject: reject,
    sendRequest: sendRequest,
};
exports.default = api;
